@import './colors.scss';
@import './fonts.scss';

#lumisoft-maxi #gallerypage {
    margin-top: 150px;
}

#lumisoft-maxi #gallerypage .contentwrap .section .headingblock {
    margin: auto;
}

#lumisoft-maxi #gallerypage .contentwrap .headingblock h2 {
    font-size: 30px;
    color: $colorDark;
    letter-spacing: 0 !important;
    font-weight: 600 !important;
    text-transform: none !important;
    margin-bottom: 5px;
    font-family: $roboto;
}

#lumisoft-maxi.dark #gallerypage .contentwrap .section .headingblock h2 {
    color: $colorWhite;
}

#lumisoft-maxi.light #gallerypage .contentwrap .section .headingblock h2 {
    color: $colorDark;
}

#lumisoft-maxi.dark #gallerypage .contentwrap .section .headingblock small {
    color: $colorWhite;
}

#lumisoft-maxi.light #gallerypage .contentwrap .section .headingblock small {
    color: $colorDark;
}

#lumisoft-maxi #gallerypage .contentwrap .section .headingblock .aboutTitle {
    font-family: $montserrat;
    font-weight: 300;
    font-size: 3rem;
    line-height: 1.0625;
    text-align: center;
    color: #3e3e3e;
    letter-spacing: 0;
    width: -moz-fit-content;
    width: fit-content;
    margin: auto;
}

#lumisoft-maxi.dark #gallerypage .contentwrap .section .headingblock .aboutTitle {
    color: $colorWhite;
}

#lumisoft-maxi.light #gallerypage .contentwrap .section .headingblock .aboutTitle {
    color: $colorDark;
}

// FILTER OPEN CLOSE
#lumisoft-maxi #gallerypage .frontfilterSearch {
    height: 100%;
    background: #fff;
    box-shadow: 1px 0px 7px rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 0;
    right: 0;
    width: 85%;
    max-width: 400px;
    z-index: 800;
    display: block;
    padding: 0 30px 35px;
    pointer-events: all;
    -webkit-transform: translateX(110%);
    -moz-transform: translateX(110%);
    -ms-transform: translateX(110%);
    transform: translateX(110%);
    transition: transform 0.2s ease;
    will-change: transform;
}

#lumisoft-maxi #gallerypage .frontfilterSearch.open {
    transform: translateX(0);
    overflow-y: auto;
    // height: 100%;
    top: 0%;
    bottom: 0%;
    position: fixed;
    // -webkit-overflow-scrolling: touch;
}

// FILTER WRAPPER
#lumisoft-maxi #gallerypage .filterSortWrapper {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    padding-top: 5px;
    padding-bottom: 15px;
}

#lumisoft-maxi #gallerypage .filterSortWrapper .sortContainer,
#lumisoft-maxi #gallerypage .filterSortWrapper .filterContainer {
    display: flex;
    flex-direction: row;
}

#lumisoft-maxi #gallerypage .filterSortWrapper .filterContainer .filterIcon {
    margin-right: 5px;
    display: flex;
    align-items: center;
}

#lumisoft-maxi #gallerypage .filterSortWrapper .filterContainer .filterText {
    cursor: pointer;
    display: flex;
    align-items: center;
    font-size: 15px;
    //    font-family: $Lato;
    font-weight: 700;
}

#lumisoft-maxi.light #gallerypage .filterSortWrapper .filterContainer:hover {
    color: $colorBlueLogo
}

#lumisoft-maxi.dark #gallerypage .filterSortWrapper .filterContainer:hover {
    color: $colorGreen
}

#lumisoft-maxi #gallerypage .filterSortWrapper .sortContainer .sortIcon {
    margin-left: 5px;
    display: flex;
    align-items: center;
}

#lumisoft-maxi #gallerypage .filterSortWrapper .sortContainer .sortText {
    cursor: pointer;
    display: flex;
    align-items: center;
    font-size: 15px;
    // font-family: $Lato;
    font-weight: 700;
}

// FILTER INSIDE WRAPPER
#lumisoft-maxi .frontfilterSearch .rightSideClose {
    font-size: 16px;
    font-weight: 600;
    position: -webkit-sticky;
    position: sticky;
    z-index: 2;
    top: 0;
    overflow: hidden;
    min-height: 69px;
    margin: 0 -5px 10px;
    padding: 35px 5px;
    transition: .2s;
    transform: translateZ(0);
    text-align: right;
    color: #282828;
    background: #fff;
    background: -webkit-gradient(left top, left bottom, color-stop(80%, #fff), color-stop(100%, rgba(255, 255, 255, 0)));
    background: linear-gradient(to bottom, #fff 80%, rgba(255, 255, 255, 0) 100%);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    cursor: pointer;
}

#lumisoft-maxi .frontfilterSearch .filterWrapper {
    margin-bottom: 25px;
}

#lumisoft-maxi .frontfilterSearch .filterWrapper .filterTitle {
    // font-family: 'Open Sans', sans-serif;
    // font-weight: 600;
    // line-height: 20px;
    // letter-spacing: .1px;
    position: relative;
}

#lumisoft-maxi .frontfilterSearch .filterWrapper .filterTitle h3 {
    color: $colorDark;
    font-size: 16px;
    font-weight: 700;
    line-height: 18px;
    margin-top: -5px;
    font-family: 'Open Sans', sans-serif;
    text-transform: uppercase;
}

#lumisoft-maxi .frontfilterSearch .filterWrapper .filterLists {
    margin: 0;
    padding: 0;
}

#lumisoft-maxi .frontfilterSearch .filterWrapper .filterLists li {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    border-bottom: 1px solid #e5e5e5;
}

#lumisoft-maxi .frontfilterSearch .filterWrapper .filterLists li:last-child {
    border-bottom: none;
}

#lumisoft-maxi .frontfilterSearch .filterWrapper .filterLists li .filterItem {
    color: #333333;
    font-size: 15px;
    line-height: 1.5;
    padding: 10px;
    display: inline-block;
    text-transform: capitalize;
    width: 100%;
    cursor: pointer;
}

#lumisoft-maxi .frontfilterSearch .filterWrapper .filterLists li .filterItem:hover {
    background-color: rgba(0, 0, 0, 0.1);
}

#lumisoft-maxi .frontfilterSearch .filterWrapper .filterLists li span {
    padding-bottom: 2px;
    float: right;
    margin-right: 5px;
}

#lumisoft-maxi .frontfilterSearch .filterWrapper .filterLists li input {
    float: right;
}

// DROPDOWN FILTER
#lumisoft-maxi #gallerypage .filterSortWrapper .dropdownWrapper {
    position: absolute;
    width: 90%;
    max-width: 210px;
    top: 98%;
    right: 0;
    z-index: 1000;
    float: left;
    padding: 5px 0;
    margin: 0 15px;
    list-style: none;
    font-size: 13px;
    text-align: left;
    background-color: #fafafa;
    border: 1px solid #5a5a5a;
    border-radius: 0;
    // -webkit-box-shadow: 0 1px 1px 1px rgba(255,255,255,.2);
    // box-shadow: 0 1px 1px 1px rgba(255,255,255,.2);
    background-clip: padding-box;
    max-height: 250px;
    // overflow-y: scroll;
}

#lumisoft-maxi #gallerypage .filterSortWrapper .dropdownWrapper .dd-list-item {
    padding: 8px 10px;
    cursor: pointer;
    display: flex;
    align-items: center;
}

#lumisoft-maxi #gallerypage .filterSortWrapper .dropdownWrapper .dd-list-item svg {
    margin-left: 5px;
}

// CARD GALLERY
#lumisoft-maxi #gallerypage .productcard {
    cursor: pointer;
}

#lumisoft-maxi #gallerypage .productimage {
    position: relative;
    width: 100%;
    height: auto;
    max-height: 339px;
    overflow: hidden;
    background-color: #F0F0F0;
}

#lumisoft-maxi #gallerypage .productimage img {
    max-height: 339px;
}

#lumisoft-maxi #gallerypage .productdesc {
    padding: 15px;
    font-size: 14px;
    font-family: Lato, sans-serif;
    // border-radius: 5px;
    border-top: 0px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}

#lumisoft-maxi #gallerypage .productimage {
    border-bottom: 0px !important;
}

#lumisoft-maxi #gallerypage .productdesc {
    border-top: 0px !important;
}

#lumisoft-maxi.light #gallerypage .productdesc {
    border: 1px solid rgba(0, 0, 0, 0.1);
}

#lumisoft-maxi.dark #gallerypage .productdesc {
    border: 1px solid rgba(255, 255, 255, 0.1);
}

#lumisoft-maxi #gallerypage .productdesc .product_title h3 {
    margin: 0;
    font-size: 18px;
}

#lumisoft-maxi #gallerypage .productdesc .product_price {
    font-size: 17px;
    font-weight: 700;
    color: #444;
    margin-bottom: 4px;
}

#lumisoft-maxi #gallerypage .productdesc .product_price del {
    font-weight: 400;
    font-size: 90%;
    color: #888;
}

#lumisoft-maxi #gallerypage .productdesc .product_brand {
    font-size: 16px;
    font-weight: 700;
    color: #444;
}

#lumisoft-maxi #gallerypage .productdesc .product_category {
    font-size: 14px;
    color: #444;
    margin-bottom: 4px;
}

#lumisoft-maxi #gallerypage .slick-prev {
    left: 5px;
    z-index: 1;
}

#lumisoft-maxi #gallerypage .slick-next {
    right: 5px;
    z-index: 1;
}

#lumisoft-maxi #gallerypage .slick-prev::before,
#lumisoft-maxi #gallerypage .slick-next::before {
    color: $colorDark
}

@media (max-width: 767.98px) {
    #lumisoft-maxi #gallerypage {
        margin-top: 70px;
        padding-top: 10px;
    }
    #lumisoft-maxi #gallerypage .contentwrap .section .headingblock .aboutTitle {
        font-size: 1.5rem;
    }
    #lumisoft-maxi #gallerypage .productdesc {
        padding: 6px;
        height: 43.2px;
    }
    #lumisoft-maxi #gallerypage .productimage img {
        max-height: 206px;
    }
    #lumisoft-maxi #gallerypage .productdesc .product_title h3 {
        font-size: 13px;
    }
}