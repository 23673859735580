@import '../fonts.scss';
@import '../colors.scss';

.lumiGalleryWrapper {
    width: 100%;
    height: 30vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.lumiGalleryWrapper.light {
    border: 1px solid rgba(0, 0, 0, 0.2);
}

.lumiGalleryWrapper.dark {
    border: 1px solid rgba(255, 255, 255, 0.2);
}

.lumiGallery .imgGallery {
    column-count: 4;
    column-gap: 10px;
}

.lumiGallery .imgGallery img {
    max-width: 100%;
    display: block;
    padding: 5px 0px;
    border-radius: 20px;
    transition: transform 0.2s;
}

.lumiGallery .imgGallery img:hover {
    filter: opacity(.95);
    transform: scale(1.01);
    cursor: pointer;
}

// GALLERY WRAPPER
#client .clientpagelayout .cardBody .galleryCardWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
}

#client .clientpagelayout .cardBody .galleryCardWrapper .galleryCard {
    width: 200px;
    height: 200px;
    max-width: 300px;
    cursor: pointer;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
}

#client .clientpagelayout .cardBody .galleryCardWrapper .galleryCard span {
    position: absolute;
    top: 0;
    left: 10;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
}

#client .clientpagelayout .cardBody .galleryCardWrapper .galleryCard span svg {
    font-size: 35px;
    padding: 3px;
    color: $colorWhite;
}

#client .clientpagelayout.light .cardBody .galleryCardWrapper .galleryCard span {
    background-color: $colorBlue;
}

#client .clientpagelayout.dark .cardBody .galleryCardWrapper .galleryCard span {
    background-color: $colorGreen;
}

#client .clientpagelayout.light .cardBody .galleryCardWrapper .galleryCard {
    background-color: $colorWhiteBackground;
    border: 1px solid $colorVeryLightGray;
}

#client .clientpagelayout.light .cardBody .galleryCardWrapper .galleryCard:hover {
    background-color: $colorWhite;
    border: 1px solid $colorBlue;
}

#client .clientpagelayout.dark .cardBody .galleryCardWrapper .galleryCard {
    background-color: $colorVeryDarkGray;
    border: 1px solid $colorDarkGrayPlus;
}

#client .clientpagelayout.dark .cardBody .galleryCardWrapper .galleryCard:hover {
    background-color: $colorVeryDark;
    border: 1px solid $colorGreen;
}

#client .clientpagelayout .cardBody .galleryCardWrapper .galleryCard .galleryThumbnails {
    padding: 10px;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

#client .clientpagelayout .cardBody .galleryCardWrapper .galleryCard .galleryThumbnails .singleImage {
    width: 33.33%;
}

#client .clientpagelayout .cardBody .galleryCardWrapper .galleryCard .galleryThumbnails .singleImage img {
    width: 100%;
    height: 48px;
    display: block;
    object-fit: cover;
    transition: transform 0.2s;
}

#client .clientpagelayout .cardBody .galleryCardWrapper .galleryCard .galleryText {
    position: absolute;
    bottom: 0;
    padding: 10px;
}

#client .clientpagelayout .cardBody .emptyGallery {
    width: 100%;
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
}