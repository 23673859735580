/***********************************************
                    PADDING
***********************************************/
.p0 {
    padding: 0 !important;
}
.p3 {
    padding: 3px !important;
}
.p5 {
    padding: 5px !important;
}
.p8 {
    padding: 8px !important;
}
.p10 {
    padding: 10px !important;
}
.p15 {
    padding: 15px !important;
}
.p20 {
    padding: 20px !important;
}
.p25 {
    padding: 25px !important;
}
.p30 {
    padding: 30px !important;
}
.p35 {
    padding: 35px !important;
}
.p40 {
    padding: 40px !important;
}
.p45 {
    padding: 45px !important;
}
.p50 {
    padding: 50px !important;
}
.p55 {
    padding: 55px !important;
}
.p60 {
    padding: 60px !important;
}
.p65 {
    padding: 65px !important;
}
.p70 {
    padding: 70px !important;
}
.p75 {
    padding: 75px !important;
}
.p80 {
    padding: 80px !important;
}
.p85 {
    padding: 85px !important;
}
.p90 {
    padding: 90px !important;
}
.p95 {
    padding: 95px !important;
}
.p100 {
    padding: 100px !important;
}
/***********************************************
                PADDING LEFT
***********************************************/
.pl0 {
    padding-left: 0 !important;
}
.pl3 {
    padding-left: 3px !important;
}
.pl5 {
    padding-left: 5px !important;
}
.pl8 {
    padding-left: 8px !important;
}
.pl10 {
    padding-left: 10px !important;
}
.pl15 {
    padding-left: 15px !important;
}
.pl20 {
    padding-left: 20px !important;
}
.pl25 {
    padding-left: 25px !important;
}
.pl30 {
    padding-left: 30px !important;
}
.pl35 {
    padding-left: 35px !important;
}
.pl40 {
    padding-left: 40px !important;
}
.pl45 {
    padding-left: 45px !important;
}
.pl50 {
    padding-left: 50px !important;
}
.pl55 {
    padding-left: 55px !important;
}
.pl60 {
    padding-left: 60px !important;
}
.pl65 {
    padding-left: 65px !important;
}
.pl70 {
    padding-left: 70px !important;
}
.pl75 {
    padding-left: 75px !important;
}
.pl80 {
    padding-left: 80px !important;
}
.pl85 {
    padding-left: 85px !important;
}
.pl90 {
    padding-left: 90px !important;
}
.pl95 {
    padding-left: 95px !important;
}
.pl100 {
    padding-left: 100px !important;
}
/***********************************************
                PADDING RIGHT
***********************************************/
.pr0 {
    padding-right: 0 !important;
}
.pr3 {
    padding-right: 3px !important;
}
.pr5 {
    padding-right: 5px !important;
}
.pr8 {
    padding-right: 8px !important;
}
.pr10 {
    padding-right: 10px !important;
}
.pr15 {
    padding-right: 15px !important;
}
.pr20 {
    padding-right: 20px !important;
}
.pr25 {
    padding-right: 25px !important;
}
.pr30 {
    padding-right: 30px !important;
}
.pr35 {
    padding-right: 35px !important;
}
.pr40 {
    padding-right: 40px !important;
}
.pr45 {
    padding-right: 45px !important;
}
.pr50 {
    padding-right: 50px !important;
}
.pr55 {
    padding-right: 55px !important;
}
.pr60 {
    padding-right: 60px !important;
}
.pr65 {
    padding-right: 65px !important;
}
.pr70 {
    padding-right: 70px !important;
}
.pr75 {
    padding-right: 75px !important;
}
.pr80 {
    padding-right: 80px !important;
}
.pr85 {
    padding-right: 85px !important;
}
.pr90 {
    padding-right: 90px !important;
}
.pr95 {
    padding-right: 95px !important;
}
.pr100 {
    padding-right: 100px !important;
}
/***********************************************
                PADDING BOTTOp
***********************************************/
.pb0 {
    padding-bottom: 0 !important;
}
.pb3 {
    padding-bottom: 3px !important;
}
.pb5 {
    padding-bottom: 5px !important;
}
.pb8 {
    padding-bottom: 8px !important;
}
.pb10 {
    padding-bottom: 10px !important;
}
.pb15 {
    padding-bottom: 15px !important;
}
.pb20 {
    padding-bottom: 20px !important;
}
.pb25 {
    padding-bottom: 25px !important;
}
.pb30 {
    padding-bottom: 30px !important;
}
.pb35 {
    padding-bottom: 35px !important;
}
.pb40 {
    padding-bottom: 40px !important;
}
.pb45 {
    padding-bottom: 45px !important;
}
.pb50 {
    padding-bottom: 50px !important;
}
.pb55 {
    padding-bottom: 55px !important;
}
.pb60 {
    padding-bottom: 60px !important;
}
.pb65 {
    padding-bottom: 65px !important;
}
.pb70 {
    padding-bottom: 70px !important;
}
.pb75 {
    padding-bottom: 75px !important;
}
.pb80 {
    padding-bottom: 80px !important;
}
.pb85 {
    padding-bottom: 85px !important;
}
.pb90 {
    padding-bottom: 90px !important;
}
.pb95 {
    padding-bottom: 95px !important;
}
.pb100 {
    padding-bottom: 100px !important;
}
/***********************************************
                PADDING TOP
***********************************************/
.pt0 {
    padding-top: 0 !important;
}
.pt3 {
    padding-top: 3px !important;
}
.pt5 {
    padding-top: 5px !important;
}
.pt8 {
    padding-top: 8px !important;
}
.pt10 {
    padding-top: 10px !important;
}
.pt15 {
    padding-top: 15px !important;
}
.pt20 {
    padding-top: 20px !important;
}
.pt25 {
    padding-top: 25px !important;
}
.pt30 {
    padding-top: 30px !important;
}
.pt35 {
    padding-top: 35px !important;
}
.pt40 {
    padding-top: 40px !important;
}
.pt45 {
    padding-top: 45px !important;
}
.pt50 {
    padding-top: 50px !important;
}
.pt55 {
    padding-top: 55px !important;
}
.pt60 {
    padding-top: 60px !important;
}
.pt65 {
    padding-top: 65px !important;
}
.pt70 {
    padding-top: 70px !important;
}
.pt75 {
    padding-top: 75px !important;
}
.pt80 {
    padding-top: 80px !important;
}
.pt85 {
    padding-top: 85px !important;
}
.pt90 {
    padding-top: 90px !important;
}
.pt95 {
    padding-top: 95px !important;
}
.pt100 {
    padding-top: 100px !important;
}

@media (max-width: 767.98px) {
    .p-xs-0 {
        padding: 0 !important;
    }
    .p-xs-3 {
        padding: 3px !important;
    }
    .p-xs-5 {
        padding: 5px !important;
    }
    .p-xs-8 {
        padding: 8px !important;
    }
    .p-xs-10 {
        padding: 10px !important;
    }
    .p-xs-15 {
        padding: 15px !important;
    }
    .p-xs-20 {
        padding: 20px !important;
    }
    .p-xs-25 {
        padding: 25px !important;
    }
    .p-xs-30 {
        padding: 30px !important;
    }
    .p-xs-35 {
        padding: 35px !important;
    }
    .p-xs-40 {
        padding: 40px !important;
    }
    .p-xs-45 {
        padding: 45px !important;
    }
    .p-xs-50 {
        padding: 50px !important;
    }
    .p-xs-55 {
        padding: 55px !important;
    }
    .p-xs-60 {
        padding: 60px !important;
    }
    .p-xs-65 {
        padding: 65px !important;
    }
    .p-xs-70 {
        padding: 70px !important;
    }
    .p-xs-75 {
        padding: 75px !important;
    }
    .p-xs-80 {
        padding: 80px !important;
    }
    .p-xs-85 {
        padding: 85px !important;
    }
    .p-xs-90 {
        padding: 90px !important;
    }
    .p-xs-95 {
        padding: 95px !important;
    }
    .p-xs-100 {
        padding: 100px !important;
    }
    /***********************************************
                    PADDING LEFT
    ***********************************************/
    .pl-xs-0 {
        padding-left: 0 !important;
    }
    .pl-xs-3 {
        padding-left: 3px !important;
    }
    .pl-xs-5 {
        padding-left: 5px !important;
    }
    .pl-xs-8 {
        padding-left: 8px !important;
    }
    .pl-xs-10 {
        padding-left: 10px !important;
    }
    .pl-xs-15 {
        padding-left: 15px !important;
    }
    .pl-xs-20 {
        padding-left: 20px !important;
    }
    .pl-xs-25 {
        padding-left: 25px !important;
    }
    .pl-xs-30 {
        padding-left: 30px !important;
    }
    .pl-xs-35 {
        padding-left: 35px !important;
    }
    .pl-xs-40 {
        padding-left: 40px !important;
    }
    .pl-xs-45 {
        padding-left: 45px !important;
    }
    .pl-xs-50 {
        padding-left: 50px !important;
    }
    .pl-xs-55 {
        padding-left: 55px !important;
    }
    .pl-xs-60 {
        padding-left: 60px !important;
    }
    .pl-xs-65 {
        padding-left: 65px !important;
    }
    .pl-xs-70 {
        padding-left: 70px !important;
    }
    .pl-xs-75 {
        padding-left: 75px !important;
    }
    .pl-xs-80 {
        padding-left: 80px !important;
    }
    .pl-xs-85 {
        padding-left: 85px !important;
    }
    .pl-xs-90 {
        padding-left: 90px !important;
    }
    .pl-xs-95 {
        padding-left: 95px !important;
    }
    .pl-xs-100 {
        padding-left: 100px !important;
    }
    /***********************************************
                    PADDING RIGHT
    ***********************************************/
    .pr-xs-0 {
        padding-right: 0 !important;
    }
    .pr-xs-3 {
        padding-right: 3px !important;
    }
    .pr-xs-5 {
        padding-right: 5px !important;
    }
    .pr-xs-8 {
        padding-right: 8px !important;
    }
    .pr-xs-10 {
        padding-right: 10px !important;
    }
    .pr-xs-15 {
        padding-right: 15px !important;
    }
    .pr-xs-20 {
        padding-right: 20px !important;
    }
    .pr-xs-25 {
        padding-right: 25px !important;
    }
    .pr-xs-30 {
        padding-right: 30px !important;
    }
    .pr-xs-35 {
        padding-right: 35px !important;
    }
    .pr-xs-40 {
        padding-right: 40px !important;
    }
    .pr-xs-45 {
        padding-right: 45px !important;
    }
    .pr-xs-50 {
        padding-right: 50px !important;
    }
    .pr-xs-55 {
        padding-right: 55px !important;
    }
    .pr-xs-60 {
        padding-right: 60px !important;
    }
    .pr-xs-65 {
        padding-right: 65px !important;
    }
    .pr-xs-70 {
        padding-right: 70px !important;
    }
    .pr-xs-75 {
        padding-right: 75px !important;
    }
    .pr-xs-80 {
        padding-right: 80px !important;
    }
    .pr-xs-85 {
        padding-right: 85px !important;
    }
    .pr-xs-90 {
        padding-right: 90px !important;
    }
    .pr-xs-95 {
        padding-right: 95px !important;
    }
    .pr-xs-100 {
        padding-right: 100px !important;
    }
    /***********************************************
                    PADDING BOTTOM
    ***********************************************/
    .pb-xs-0{
        padding-bottom: 0 !important;
    }
    .pb-xs-3 {
        padding-bottom: 3px !important;
    }
    .pb-xs-5 {
        padding-bottom: 5px !important;
    }
    .pb-xs-8 {
        padding-bottom: 8px !important;
    }
    .pb-xs-10 {
        padding-bottom: 10px !important;
    }
    .pb-xs-15 {
        padding-bottom: 15px !important;
    }
    .pb-xs-20 {
        padding-bottom: 20px !important;
    }
    .pb-xs-25 {
        padding-bottom: 25px !important;
    }
    .pb-xs-30 {
        padding-bottom: 30px !important;
    }
    .pb-xs-35 {
        padding-bottom: 35px !important;
    }
    .pb-xs-40 {
        padding-bottom: 40px !important;
    }
    .pb-xs-45 {
        padding-bottom: 45px !important;
    }
    .pb-xs-50 {
        padding-bottom: 50px !important;
    }
    .pb-xs-55 {
        padding-bottom: 55px !important;
    }
    .pb-xs-60 {
        padding-bottom: 60px !important;
    }
    .pb-xs-65 {
        padding-bottom: 65px !important;
    }
    .pb-xs-70 {
        padding-bottom: 70px !important;
    }
    .pb-xs-75 {
        padding-bottom: 75px !important;
    }
    .pb-xs-80 {
        padding-bottom: 80px !important;
    }
    .pb-xs-85 {
        padding-bottom: 85px !important;
    }
    .pb-xs-90 {
        padding-bottom: 90px !important;
    }
    .pb-xs-95 {
        padding-bottom: 95px !important;
    }
    .pb-xs-100 {
        padding-bottom: 100px !important;
    }
    /***********************************************
                    PADDING TOP
    ***********************************************/
    .pt-xs-0 {
        padding-top: 0 !important;
    }
    .pt-xs-3 {
        padding-top: 3px !important;
    }
    .pt-xs-5 {
        padding-top: 5px !important;
    }
    .pt-xs-8 {
        padding-top: 8px !important;
    }
    .pt-xs-10 {
        padding-top: 10px !important;
    }
    .pt-xs-15 {
        padding-top: 15px !important;
    }
    .pt-xs-20 {
        padding-top: 20px !important;
    }
    .pt-xs-25 {
        padding-top: 25px !important;
    }
    .pt-xs-30 {
        padding-top: 30px !important;
    }
    .pt-xs-35 {
        padding-top: 35px !important;
    }
    .pt-xs-40 {
        padding-top: 40px !important;
    }
    .pt-xs-45 {
        padding-top: 45px !important;
    }
    .pt-xs-50 {
        padding-top: 50px !important;
    }
    .pt-xs-55 {
        padding-top: 55px !important;
    }
    .pt-xs-60 {
        padding-top: 60px !important;
    }
    .pt-xs-65 {
        padding-top: 65px !important;
    }
    .pt-xs-70 {
        padding-top: 70px !important;
    }
    .pt-xs-75 {
        padding-top: 75px !important;
    }
    .pt-xs-80 {
        padding-top: 80px !important;
    }
    .pt-xs-85 {
        padding-top: 85px !important;
    }
    .pt-xs-90 {
        padding-top: 90px !important;
    }
    .pt-xs-95 {
        padding-top: 95px !important;
    }
    .pt-xs-100 {
        padding-top: 100px !important;
    }
}

@media (min-width: 767.99px) and (max-width: 835.98px) {
/***********************************************
                        PADDING
    ***********************************************/
    .p-sm-0 {
        padding: 0 !important;
    }
    .p-sm-3 {
        padding: 3px !important;
    }
    .p-sm-5 {
        padding: 5px !important;
    }
    .p-sm-8 {
        padding: 8px !important;
    }
    .p-sm-10 {
        padding: 10px !important;
    }
    .p-sm-15 {
        padding: 15px !important;
    }
    .p-sm-20 {
        padding: 20px !important;
    }
    .p-sm-25 {
        padding: 25px !important;
    }
    .p-sm-30 {
        padding: 30px !important;
    }
    .p-sm-35 {
        padding: 35px !important;
    }
    .p-sm-40 {
        padding: 40px !important;
    }
    .p-sm-45 {
        padding: 45px !important;
    }
    .p-sm-50 {
        padding: 50px !important;
    }
    .p-sm-55 {
        padding: 55px !important;
    }
    .p-sm-60 {
        padding: 60px !important;
    }
    .p-sm-65 {
        padding: 65px !important;
    }
    .p-sm-70 {
        padding: 70px !important;
    }
    .p-sm-75 {
        padding: 75px !important;
    }
    .p-sm-80 {
        padding: 80px !important;
    }
    .p-sm-85 {
        padding: 85px !important;
    }
    .p-sm-90 {
        padding: 90px !important;
    }
    .p-sm-95 {
        padding: 95px !important;
    }
    .p-sm-100 {
        padding: 100px !important;
    }
    /***********************************************
                    PADDING LEFT
    ***********************************************/
    .pl-sm-0 {
        padding-left: 0 !important;
    }
    .pl-sm-3 {
        padding-left: 3px !important;
    }
    .pl-sm-5 {
        padding-left: 5px !important;
    }
    .pl-sm-8 {
        padding-left: 8px !important;
    }
    .pl-sm-10 {
        padding-left: 10px !important;
    }
    .pl-sm-15 {
        padding-left: 15px !important;
    }
    .pl-sm-20 {
        padding-left: 20px !important;
    }
    .pl-sm-25 {
        padding-left: 25px !important;
    }
    .pl-sm-30 {
        padding-left: 30px !important;
    }
    .pl-sm-35 {
        padding-left: 35px !important;
    }
    .pl-sm-40 {
        padding-left: 40px !important;
    }
    .pl-sm-45 {
        padding-left: 45px !important;
    }
    .pl-sm-50 {
        padding-left: 50px !important;
    }
    .pl-sm-55 {
        padding-left: 55px !important;
    }
    .pl-sm-60 {
        padding-left: 60px !important;
    }
    .pl-sm-65 {
        padding-left: 65px !important;
    }
    .pl-sm-70 {
        padding-left: 70px !important;
    }
    .pl-sm-75 {
        padding-left: 75px !important;
    }
    .pl-sm-80 {
        padding-left: 80px !important;
    }
    .pl-sm-85 {
        padding-left: 85px !important;
    }
    .pl-sm-90 {
        padding-left: 90px !important;
    }
    .pl-sm-95 {
        padding-left: 95px !important;
    }
    .pl-sm-100 {
        padding-left: 100px !important;
    }
    /***********************************************
                    PADDING RIGHT
    ***********************************************/
    .pr-sm-0 {
        padding-right: 0 !important;
    }
    .pr-sm-3 {
        padding-right: 3px !important;
    }
    .pr-sm-5 {
        padding-right: 5px !important;
    }
    .pr-sm-8 {
        padding-right: 8px !important;
    }
    .pr-sm-10 {
        padding-right: 10px !important;
    }
    .pr-sm-15 {
        padding-right: 15px !important;
    }
    .pr-sm-20 {
        padding-right: 20px !important;
    }
    .pr-sm-25 {
        padding-right: 25px !important;
    }
    .pr-sm-30 {
        padding-right: 30px !important;
    }
    .pr-sm-35 {
        padding-right: 35px !important;
    }
    .pr-sm-40 {
        padding-right: 40px !important;
    }
    .pr-sm-45 {
        padding-right: 45px !important;
    }
    .pr-sm-50 {
        padding-right: 50px !important;
    }
    .pr-sm-55 {
        padding-right: 55px !important;
    }
    .pr-sm-60 {
        padding-right: 60px !important;
    }
    .pr-sm-65 {
        padding-right: 65px !important;
    }
    .pr-sm-70 {
        padding-right: 70px !important;
    }
    .pr-sm-75 {
        padding-right: 75px !important;
    }
    .pr-sm-80 {
        padding-right: 80px !important;
    }
    .pr-sm-85 {
        padding-right: 85px !important;
    }
    .pr-sm-90 {
        padding-right: 90px !important;
    }
    .pr-sm-95 {
        padding-right: 95px !important;
    }
    .pr-sm-100 {
        padding-right: 100px !important;
    }
    /***********************************************
                    PADDING BOTTOM
    ***********************************************/
    .pb-sm-0{
        padding-bottom: 0 !important;
    }
    .pb-sm-3 {
        padding-bottom: 3px !important;
    }
    .pb-sm-5 {
        padding-bottom: 5px !important;
    }
    .pb-sm-8 {
        padding-bottom: 8px !important;
    }
    .pb-sm-10 {
        padding-bottom: 10px !important;
    }
    .pb-sm-15 {
        padding-bottom: 15px !important;
    }
    .pb-sm-20 {
        padding-bottom: 20px !important;
    }
    .pb-sm-25 {
        padding-bottom: 25px !important;
    }
    .pb-sm-30 {
        padding-bottom: 30px !important;
    }
    .pb-sm-35 {
        padding-bottom: 35px !important;
    }
    .pb-sm-40 {
        padding-bottom: 40px !important;
    }
    .pb-sm-45 {
        padding-bottom: 45px !important;
    }
    .pb-sm-50 {
        padding-bottom: 50px !important;
    }
    .pb-sm-55 {
        padding-bottom: 55px !important;
    }
    .pb-sm-60 {
        padding-bottom: 60px !important;
    }
    .pb-sm-65 {
        padding-bottom: 65px !important;
    }
    .pb-sm-70 {
        padding-bottom: 70px !important;
    }
    .pb-sm-75 {
        padding-bottom: 75px !important;
    }
    .pb-sm-80 {
        padding-bottom: 80px !important;
    }
    .pb-sm-85 {
        padding-bottom: 85px !important;
    }
    .pb-sm-90 {
        padding-bottom: 90px !important;
    }
    .pb-sm-95 {
        padding-bottom: 95px !important;
    }
    .pb-sm-100 {
        padding-bottom: 100px !important;
    }
    /***********************************************
                    PADDING TOP
    ***********************************************/
    .pt-sm-0 {
        padding-top: 0 !important;
    }
    .pt-sm-3 {
        padding-top: 3px !important;
    }
    .pt-sm-5 {
        padding-top: 5px !important;
    }
    .pt-sm-8 {
        padding-top: 8px !important;
    }
    .pt-sm-10 {
        padding-top: 10px !important;
    }
    .pt-sm-15 {
        padding-top: 15px !important;
    }
    .pt-sm-20 {
        padding-top: 20px !important;
    }
    .pt-sm-25 {
        padding-top: 25px !important;
    }
    .pt-sm-30 {
        padding-top: 30px !important;
    }
    .pt-sm-35 {
        padding-top: 35px !important;
    }
    .pt-sm-40 {
        padding-top: 40px !important;
    }
    .pt-sm-45 {
        padding-top: 45px !important;
    }
    .pt-sm-50 {
        padding-top: 50px !important;
    }
    .pt-sm-55 {
        padding-top: 55px !important;
    }
    .pt-sm-60 {
        padding-top: 60px !important;
    }
    .pt-sm-65 {
        padding-top: 65px !important;
    }
    .pt-sm-70 {
        padding-top: 70px !important;
    }
    .pt-sm-75 {
        padding-top: 75px !important;
    }
    .pt-sm-80 {
        padding-top: 80px !important;
    }
    .pt-sm-85 {
        padding-top: 85px !important;
    }
    .pt-sm-90 {
        padding-top: 90px !important;
    }
    .pt-sm-95 {
        padding-top: 95px !important;
    }
    .pt-sm-100 {
        padding-top: 100px !important;
    }
}