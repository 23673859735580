@import '../fonts.scss';
@import '../colors.scss';

#client .mainImageScreen.light {
    background-color: $colorWhite;
    color: $colorVeryDarkGray;
}

#client .mainImageScreen.dark {
    background-color: $colorVeryDark;
    color: $colorWhite;
}

#client .mainBodyWrapper.mediacss .mediaLibraryClose {
    display: flex;
    flex-direction: row;
    padding: 20px;
    justify-content: flex-end;
}

#client .mainImageScreen .mediaLibraryButtonWrapper .mediaLibraryButton,
#client .mainBodyWrapper.mediacss .mediaLibraryClose .acceptButton,
#client .mainBodyWrapper.mediacss .mediaLibraryClose .closeButton {
    height: 44px;
    width: 44px;
    display: flex;
    cursor: pointer;
    border-radius: 8px;
    margin-right: 10px;
}

#client .mainImageScreen .mediaLibraryButtonWrapper .mediaLibraryButton svg,
#client .mainBodyWrapper.mediacss .mediaLibraryClose .acceptButton svg,
#client .mainBodyWrapper.mediacss .mediaLibraryClose .closeButton svg {
    vertical-align: middle;
    height: 18px;
    width: 18px;
    margin: 12px;
}

#client .mainImageScreen .mediaLibraryButtonWrapper .mediaLibraryButton.green {
    border: 1px solid $colorGreen !important;
    color: $colorGreen !important;
}

#client .mainImageScreen .mediaLibraryButtonWrapper .mediaLibraryButton.red {
    border: 1px solid $colorRed !important;
    color: $colorRed !important;
}

#client .mainImageScreen.dark .mediaLibraryButtonWrapper .mediaLibraryButton,
#client .floatingScreen.dark .mainBodyWrapper.mediacss .mediaLibraryClose .acceptButton,
#client .floatingScreen.dark .mainBodyWrapper.mediacss .mediaLibraryClose .closeButton {
    border: 1px solid $colorDarkGrayPlus;
    background-color: $colorVeryDarkGray;
}

#client .mainImageScreen.light .mediaLibraryButtonWrapper .mediaLibraryButton,
#client .floatingScreen.light .mainBodyWrapper.mediacss .mediaLibraryClose .acceptButton,
#client .floatingScreen.light .mainBodyWrapper.mediacss .mediaLibraryClose .closeButton {
    border: 1px solid $colorVeryLightGray;
    background-color: $colorWhiteBackground;
}

#client .floatingScreen.light .mainBodyWrapper.mediacss .mediaLibraryClose .acceptButton.disabled svg,
#client .floatingScreen.light .mainBodyWrapper.mediacss .mediaLibraryClose .closeButton.disabled svg{
    color: $colorLightGray;
}

#client .floatingScreen.dark .mainBodyWrapper.mediacss .mediaLibraryClose .acceptButton.disabled svg,
#client .floatingScreen.dark .mainBodyWrapper.mediacss .mediaLibraryClose .closeButton.disabled svg{
    color: $colorDarkGrayPlus;
}

#client .mainImageScreen.dark .mediaLibraryButtonWrapper .mediaLibraryButton:hover,
#client .mainImageScreen.dark .mediaLibraryButtonWrapper .mediaLibraryButton.active,
#client .floatingScreen.dark .mainBodyWrapper.mediacss .mediaLibraryClose .acceptButton:hover,
#client .floatingScreen.dark .mainBodyWrapper.mediacss .mediaLibraryClose .closeButton:hover {
    border: 1px solid $colorGreen;
}

#client .mainImageScreen.light .mediaLibraryButtonWrapper .mediaLibraryButton:hover,
#client .mainImageScreen.light .mediaLibraryButtonWrapper .mediaLibraryButton.active,
#client .floatingScreen.light .mainBodyWrapper.mediacss .mediaLibraryClose .acceptButton:hover,
#client .floatingScreen.light .mainBodyWrapper.mediacss .mediaLibraryClose .closeButton:hover {
    border: 1px solid $colorBlueLogo;
}

#client .floatingScreen.dark .mainBodyWrapper.mediacss .mediaLibraryClose .acceptButton.multiactive,
#client .floatingScreen.dark .mainBodyWrapper.mediacss .mediaLibraryClose .acceptButton.singleactive {
    color: $colorGreen;
}

#client .floatingScreen.light .mainBodyWrapper.mediacss .mediaLibraryClose .acceptButton.multiactive,
#client .floatingScreen.light .mainBodyWrapper.mediacss .mediaLibraryClose .acceptButton.singleactive {
    color: $colorBlueLogo;
}

#client .floatingScreen.dark .mainBodyWrapper.mediacss .mediaLibraryClose .acceptButton.multiactive:hover,
#client .floatingScreen.dark .mainBodyWrapper.mediacss .mediaLibraryClose .acceptButton.singleactive:hover {
    color: $colorGreen;
}

#client .floatingScreen.light .mainBodyWrapper.mediacss .mediaLibraryClose .acceptButton.multiactive:hover,
#client .floatingScreen.light .mainBodyWrapper.mediacss .mediaLibraryClose .acceptButton.singleactive:hover {
    color: $colorBlueLogo;
}

#client .mainImageScreen {
    // box-shadow: 1px 0px 7px rgba(0, 0, 0, 0.5);
    // position: fixed;
    // top: 50%;
    // left: 50%;
    // right: 0;
    // width: 100%; // kalau ini ga 100%, mobile phone ga full width
    // max-width: 1000px;
    // z-index: 950;
    display: block;
    // padding: 0 30px 35px;
    pointer-events: all;
    visibility: visible;
    opacity: 1;
    border-radius: 20px;
    // transition: visibility 0s, opacity 0.5s linear;
    transition: transform 0.5s ease, opacity 0.3s ease;
    // -webkit-transform: translateX(110%);
    // -moz-transform: translateX(110%);
    // -ms-transform: translateX(110%);
    // transform: translateX(110%);
    // transition: transform 0.2s ease;
    // transform: translate(-50%, -50%);
    will-change: transform;
}

// #client .mainImageScreen.open {
//     visibility: visible;
//     opacity: 1;
//     top: 50%;
//     left: 50%;
//     /* bring your own prefixes */
//     transform: translate(-50%, -50%);
//     // -webkit-overflow-scrolling: touch;
// }

#client .mainImageScreen .errorSubmit {
    position: absolute;
    font-size: 15px;
    font-family: "Roboto", sans-serif;
    color: $colorRed;
    text-transform: uppercase;
    left: 20px;
    bottom: 20px;
}

#client .mainImageScreen .mediaLibraryWrapper {
    display: flex;
    width: 100%;
    justify-content: center;
    height: 44px;
    margin-top: 20px;
}

// #client .mainImageScreen .mediaLibraryWrapper.displayBlock {
//     display: block !important;
// }

#client .mainImageScreen .mediaLibraryWrapper .mediaLibraryTitle {
    text-transform: uppercase;
    font-size: 1.25rem;
    font-weight: 700;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

#client .mainImageScreen .mediaLibraryWrapper .mediaLibraryClose {
    position: absolute;
    top: -13px;
    right: 30px;
    font-size: 40px;
    align-items: center;
    display: flex;
    justify-content: flex-end;
}

#client .mainImageScreen .mediaLibraryButtonWrapper {
    padding: 15px 20px;
    display: flex;
    flex-direction: row;
    position: relative;
    min-height: 1px;
    width: 100%;
    justify-content: center;
    align-items: center;
}

#client .mainImageScreen .showImgWrapper {
    display: flex;
    width: 100%;
    justify-content: center;
}

#client .mainImageScreen .showImgWrapper img {
    object-fit: contain;
    width: 100%;
}

#client .mainImageScreen .showImgWrapper .imgWrapper {
    width: 100%;
    padding: 30px;
}

#client .mainImageScreen .showImgWrapper .imgWrapper .mediaTitleWrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    padding: 0 20px;
}

#client .mainImageScreen .showImgWrapper .imgWrapper .mediaTitleWrapper.flexend {
    justify-content: flex-end !important;
}

#client .mainImageScreen .showImgWrapper .imgWrapper .mediaTitleWrapper .mediaTitle {
    font-weight: 700;
    font-size: 20px;
}

#client .mainImageScreen .showImgWrapper .imgWrapper .mediaTitleWrapper .mediaPick {
    display: flex;
    flex-direction: row;
}

#client .mainImageScreen .showImgWrapper .imgWrapper .mediaTitleWrapper .mediaPick .mediaPickDetails {
    font-size: 12px;
    margin-right: 15px;
    display: flex;
    align-self: center;
}

// CROP WRAPPER WITH ASPECT 
#client .clientpagelayout.light .mainBodyWrapper .showImgCropWrapper {
    background-color: $colorWhite;
}

#client .clientpagelayout.dark .mainBodyWrapper .showImgCropWrapper {
    background-color: $colorVeryDark;
}

#client .mainBodyWrapper .showImgCropWrapper {
    margin: 0 auto;
    padding: 20px;
    justify-content: center;
    align-items: center;
    display: flex;
    border-radius: 10px;
}

#client .mainBodyWrapper .showImgCropWrapper .imageCropStyle {
    object-fit: cover;
    max-height: 75%;
    height: 75%;
}

#client .mainBodyWrapper .dashboardBody {
    display: flex;
    justify-content: center;
}

#client .mainBodyWrapper .dashboardBody .row.width100p {
    width: 100%;
}

#client .mainBodyWrapper .dashboardBody .cropWrapper {
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding-bottom: 0;
    width: 100%;
}

#client .mainBodyWrapper .dashboardBody .cropWrapper .prevImgWrapper {
    display: flex;
    width: 100%;
    padding: 0 30px;
    justify-content: center;
    flex-direction: row;
}

#client .mainBodyWrapper .dashboardBody .cropWrapper .prevImgWrapper .columnWrapper {
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 10px 0;
}

#client .mainBodyWrapper .dashboardBody .cropWrapper .prevImgWrapper .theRow {
    display: flex;
    flex-direction: row;
}

#client .mainBodyWrapper .dashboardBody .cropWrapper .prevImgWrapper .prevImgButton,
#client .mainBodyWrapper .dashboardBody .cropWrapper .prevImgWrapper .nextImgButton {
    margin-right: 20px;
}

#client .mainBodyWrapper .dashboardBody .cropWrapper .prevImgWrapper .prevImgButton,
#client .mainBodyWrapper .dashboardBody .cropWrapper .prevImgWrapper .nextImgButton {
    padding: 10px;
    margin-top: 0;
    background-color: $colorGreen;
    width: 100px;
    text-align: center;
    color: $colorWhite;
    border-radius: 7px;
    text-transform: uppercase;
    cursor: pointer;
    font-size: 13px;
}

#client .mainImageScreen .textareaWrapper {
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    position: absolute;
    bottom: 2%;
    width: 500px;
    z-index: 500;
}

#client .mainImageScreen .textareaWrapper textarea {
    opacity: 0.9;
    box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
}

#client .mainImageScreen .image_picker {
    padding: 0 5px !important;
    display: flex;
    flex-wrap: wrap;
}

#client .mainImageScreen .imgCardWrapper {
    margin: 15px 0;
    cursor: pointer;
    padding-left: 5px !important;
    padding-right: 5px !important;
}

#client .mainImageScreen .imgCardWrapper .imgCardInnerWrapper {
    padding: 10px;
    border: 1px solid $colorLightGray;
}

#client .mainImageScreen.light .imgCardWrapper.selected .imgCardInnerWrapper {
    border: 1px solid $colorBlueLogo;
    background-color: $colorBlueLogo;
    color: $colorWhite;
}

#client .mainImageScreen.dark .imgCardWrapper.selected .imgCardInnerWrapper {
    border: 1px solid $colorGreen;
    background-color: $colorGreen;
    color: $colorWhite;
}

#client .mainImageScreen .imgCardWrapper .imgCardInnerWrapper .imgText {
    padding-top: 10px;
    padding-left: 3px;
    font-size: 16px;
}

#client .mainImageScreen .imgCardWrapper .imgCard:hover img,
#client .mainImageScreen .imgCardWrapper.selected .imgCard img {
    transform: scale(1.1);
}

#client .mainImageScreen .imgCardWrapper .imgCard img {
    transition: transform .5s ease;
    min-height: 227px;
    max-height: 227px;
    display: block
}

#client .mainImageScreen.light .imgCardWrapper .imgCard {
    border: 1px solid rgba(0, 0, 0, 0.1);
}

#client .mainImageScreen.dark .imgCardWrapper .imgCard {
    border: 1px solid rgba(255, 255, 255, 0.1);
}

#client .mainImageScreen .imgCardWrapper .imgCard {
    border: 1px solid rgba(255, 255, 255, 0.1);
    overflow: hidden;
    justify-content: center;
    display: flex;
}

#client .mainImageScreen .imgCardWrapper .imgCard span {
    font-size: 30px;
    text-transform: uppercase;
}

.skeleton {
    animation: skeleton-loading 1s linear infinite alternate;
}

@keyframes skeleton-loading {
    0% {
        background-color: hsl(200, 20%, 80%);
    }

    100% {
        background-color: hsl(200, 20%, 95%);
    }
}

.skeleton-text {
    width: 100%;
    height: 0.7rem;
    margin-bottom: 0.5rem;
    border-radius: 0.25rem;
}

/* container is loading, hide the img */
#client .mainImageScreen .imgCardWrapper .imgCard.isLoading img {
    display: none !important;
}

#client .mainImageScreen .imgCardWrapper .imgCard.isLoading .imgLoader {
    display: block !important;
}

/* container not loading, hide placeholder */
#client .mainImageScreen .imgCardWrapper .imgCard .imgLoader {
    display: none !important;
}

// AT GALLERY, PORTFOLIO AND SLIDERS
#client .masterDashboardContainer .showimageWrapper {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
}

#client .masterDashboardContainer .showimageWrapper .showimageCard {
    border: 1px solid rgba(255, 255, 255, 0.1);
    cursor: pointer;
}

// SEARCH HANDLER
#client .mainImageScreen .searchContainer {
    display: flex;
    justify-content: center;
    align-items: center;
}

#client .mainImageScreen .searchContainer .searchWrapper {
    width: 300px;
    position: relative;
}

#client .mainImageScreen .searchContainer .searchWrapper .iconPosition.active {
    cursor: pointer;
}

#client .mainImageScreen .searchContainer .searchWrapper .iconPosition.inactive {
    cursor: default;
}

#client .mainImageScreen .searchContainer .searchWrapper .iconPosition {
    top: 50%;
    position: absolute;
    transform: translateY(-50%);
    line-height: 0;
    color: #878787;
    z-index: 3;
    margin-left: 10px;
}

// #client .mainImageScreen .uploadWrapper .upLoadButtonWrapper .upLoadButton {
//     margin-right: 15px;
//     position: relative;
//     cursor: pointer;
//     display: flex;
//     overflow: hidden;
//     -webkit-user-select: none;
//     user-select: none;
//     border-radius: 100%;
//     padding: 6px;
//     font-size: 20px;
//     line-height: 1.5;
//     background-color: #dedede;
//     color: $colorVeryDarkGray;
//     border: 1px solid rgba(255, 255, 255, 0.07);
//     transition: color .15s ease-in-out,
//         background-color .15s ease-in-out,
//         border-color .15s ease-in-out,
//         box-shadow .15s ease-in-out;
//     justify-content: center;
//     align-items: center;
// }

#client .mainImageScreen .uploadSection .imgCardWrapper {
    cursor: default;
}

// LOGO WRAPPER
#client .clientpagelayout .clientPageWrapper .insidecontentWrapper .mainBodyWrapper .cardWrapper .cardBody .cardOptions .logowrapper {
    display: flex;
}
#client .clientpagelayout .clientPageWrapper .insidecontentWrapper .mainBodyWrapper .cardWrapper .cardBody .cardOptions .logowrapper .logoinnerwrapper {
    width: 150px;
    height: 37.45px;
    border-radius: 10px;
}

#client .clientpagelayout .clientPageWrapper .insidecontentWrapper .mainBodyWrapper .cardWrapper .cardBody .cardOptions .logowrapper {
    display: flex;
    margin-bottom: 30px;
    cursor: pointer;
}

#client .clientpagelayout.light .clientPageWrapper .insidecontentWrapper .mainBodyWrapper .cardWrapper .cardBody .cardOptions .logowrapper .logoinnerwrapper {
    border: 1px solid $colorVeryLightGray;
    background-color: $colorWhite;
}

#client .clientpagelayout.dark .clientPageWrapper .insidecontentWrapper .mainBodyWrapper .cardWrapper .cardBody .cardOptions .logowrapper .logoinnerwrapper {
    border: 1px solid $colorDarkGrayPlus;
    background-color: $colorVeryDarkGray;
}

#client .clientpagelayout.dark .clientPageWrapper .insidecontentWrapper .mainBodyWrapper .cardWrapper .cardBody .cardOptions .mobilelogowrapper .innerWrapper,
#client .clientpagelayout.dark .clientPageWrapper .insidecontentWrapper .mainBodyWrapper .cardWrapper .cardBody .cardOptions .logowrapper .innerWrapper,
#client .clientpagelayout.dark .clientPageWrapper .insidecontentWrapper .mainBodyWrapper .cardWrapper .cardBody .cardOptions .logowrapper .logoinnerwrapper span{
    color: $colorWhite
}

#client .clientpagelayout .clientPageWrapper .insidecontentWrapper .mainBodyWrapper .cardWrapper .cardBody .cardOptions .logowrapper .logoinnerwrapper,
#client .clientpagelayout .clientPageWrapper .insidecontentWrapper .mainBodyWrapper .cardWrapper .cardBody .cardOptions .logowrapper .logoinnerwrapper span {
    display: flex;
    justify-content: center;
    align-items: center;
}

#client .clientpagelayout .clientPageWrapper .insidecontentWrapper .mainBodyWrapper .cardWrapper .cardBody .cardOptions .logowrapper .logoinnerwrapper svg {
    display: flex;
    font-size: 25px;
    margin-right: 8px;
}

#client .clientpagelayout.light .clientPageWrapper .insidecontentWrapper .mainBodyWrapper .cardWrapper .cardBody .cardOptions .logowrapper .logoinnerwrapper svg {
    color: $colorDarkGrayPlus
}

#client .clientpagelayout.dark .clientPageWrapper .insidecontentWrapper .mainBodyWrapper .cardWrapper .cardBody .cardOptions .logowrapper .logoinnerwrapper svg {
    color: $colorWhite
}

#client .clientpagelayout .clientPageWrapper .insidecontentWrapper .mainBodyWrapper .cardWrapper .cardBody .cardOptions .logowrapper .logoinnerwrapper img {
    object-fit: contain;
    width: 100%;
    border-radius: 10px;
}

// MOBILE LOGO WRAPPER 
#client .clientpagelayout .clientPageWrapper .insidecontentWrapper .mainBodyWrapper .cardWrapper .cardBody .cardOptions .mobilelogowrapper {
    display: flex;
}
#client .clientpagelayout .clientPageWrapper .insidecontentWrapper .mainBodyWrapper .cardWrapper .cardBody .cardOptions .mobilelogowrapper .mobilelogoinnerwrapper {
    width: 40px;
    height: 40px;
    border-radius: 10px;
}

#client .clientpagelayout .clientPageWrapper .insidecontentWrapper .mainBodyWrapper .cardWrapper .cardBody .cardOptions .mobilelogowrapper {
    display: flex;
    margin-bottom: 30px;
    cursor: pointer;
}

#client .clientpagelayout.light .clientPageWrapper .insidecontentWrapper .mainBodyWrapper .cardWrapper .cardBody .cardOptions .mobilelogowrapper .mobilelogoinnerwrapper {
    border: 1px solid $colorVeryLightGray;
    background-color: $colorWhite;
}

#client .clientpagelayout.light .clientPageWrapper .insidecontentWrapper .mainBodyWrapper .cardWrapper .cardBody .cardOptions .logowrapper .logoinnerwrapper.disabled,
#client .clientpagelayout.light .clientPageWrapper .insidecontentWrapper .mainBodyWrapper .cardWrapper .cardBody .cardOptions .mobilelogowrapper .mobilelogoinnerwrapper.disabled {
    background-color: $colorVeryLightGray;
}

#client .clientpagelayout.dark .clientPageWrapper .insidecontentWrapper .mainBodyWrapper .cardWrapper .cardBody .cardOptions .mobilelogowrapper .mobilelogoinnerwrapper {
    border: 1px solid $colorDarkGrayPlus;
    background-color: $colorVeryDarkGray;
}

#client .clientpagelayout.dark .clientPageWrapper .insidecontentWrapper .mainBodyWrapper .cardWrapper .cardBody .cardOptions .logowrapper .logoinnerwrapper.disabled,
#client .clientpagelayout.dark .clientPageWrapper .insidecontentWrapper .mainBodyWrapper .cardWrapper .cardBody .cardOptions .mobilelogowrapper .mobilelogoinnerwrapper.disabled {
    background-color: $colorDarkGrayPlus;
}

#client .clientpagelayout .clientPageWrapper .insidecontentWrapper .mainBodyWrapper .cardWrapper .cardBody .cardOptions .mobilelogowrapper .mobilelogoinnerwrapper,
#client .clientpagelayout .clientPageWrapper .insidecontentWrapper .mainBodyWrapper .cardWrapper .cardBody .cardOptions .mobilelogowrapper .mobilelogoinnerwrapper span {
    display: flex;
    justify-content: center;
    align-items: center;
}

#client .clientpagelayout .clientPageWrapper .insidecontentWrapper .mainBodyWrapper .cardWrapper .cardBody .cardOptions .mobilelogowrapper .mobilelogoinnerwrapper svg {
    display: flex;
    font-size: 25px;
}

#client .clientpagelayout.light .clientPageWrapper .insidecontentWrapper .mainBodyWrapper .cardWrapper .cardBody .cardOptions .mobilelogowrapper .mobilelogoinnerwrapper svg {
    color: $colorDarkGrayPlus
}

#client .clientpagelayout.dark .clientPageWrapper .insidecontentWrapper .mainBodyWrapper .cardWrapper .cardBody .cardOptions .mobilelogowrapper .mobilelogoinnerwrapper svg {
    color: $colorWhite
}

#client .clientpagelayout .clientPageWrapper .insidecontentWrapper .mainBodyWrapper .cardWrapper .cardBody .cardOptions .mobilelogowrapper .mobilelogoinnerwrapper img {
    object-fit: contain;
    width: 100%;
    border-radius: 10px;
}


@media (max-width: 767.98px) {
    #client .floatingScreen {
        max-width: 400px !important;
    }
    #client .floatingScreen .mediaLibraryWrapper,
    #client .floatingScreen .mediaTitle {
        display: none !important;
    }
    #client .mainImageScreen .showImgWrapper .imgWrapper .mediaTitleWrapper {
        margin-bottom: 0 !important;
    }
    #client .mainImageScreen .showImgWrapper .imgWrapper .mediaTitleWrapper .mediaPick .mediaPickDetails:first-child {
        margin-bottom: 10px !important;
    }
    #client .mainImageScreen .mediaLibraryButtonWrapper {
        padding-bottom: 0 !important;
    }
    #client .mainImageScreen .imgCardWrapper .imgCard img {
        min-height: 50px;
    }

    #client .mainImageScreen .imgCardWrapper .imgCardInnerWrapper .imgText {
        font-size: 13px;
    }

    #client .mainImageScreen .showImgWrapper .imgWrapper {
        padding: 0 0 30px 0;
    }

    #client .mainImageScreen .showImgWrapper .imgWrapper .mediaTitleWrapper {
        flex-direction: column;
        justify-content: normal;
    }

    #client .mainImageScreen .showImgWrapper .imgWrapper .mediaTitleWrapper .mediaTitle {
        width: 100%;
        display: flex;
        justify-content: center;
        font-weight: 700;
        font-size: 20px;
        margin-bottom: 30px;
    }

    #client .mainImageScreen .showImgWrapper .imgWrapper .mediaTitleWrapper .mediaPick {
        flex-direction: column;
    }

    #client .mainImageScreen .showImgWrapper .imgWrapper .mediaTitleWrapper .mediaPick .mediaPickDetails:first-child {
        margin-bottom: 30px;
    }

    #client .mainImageScreen .showImgWrapper .imgWrapper .mediaTitleWrapper .mediaPick .mediaPickDetails {
        margin-bottom: 5px;
    }

    #client .mainBodyWrapper .dashboardBody .cropWrapper .prevImgWrapper {
        flex-direction: column;
    }

    #client .mainBodyWrapper .dashboardBody .cropWrapper .prevImgWrapper .theRow {
        margin-bottom: 20px;
    }

    // #client .mainImageScreen .uploadWrapper .upLoadButtonWrapper .upLoadButton:last-child {
    //     margin: 0;
    // }

    // #client .mainImageScreen .uploadWrapper .uploadTitle {
    //     justify-content: flex-start;
    // }

    #client .mainImageScreen .errorSubmit {
        bottom: 60px;
        left: 50%;
        transform: translateX(-50%);
    }

    #client .mainImageScreen .mediaLibraryButtonWrapper .mediaLibraryButton {
        min-width: 46px !important;
    }

    #client .mainImageScreen .mediaLibraryWrapper .mediaLibraryTitle {
        justify-content: center;
        transform: none;
    }

    #client .masterDashboardContainer .showimageWrapper .showimageCard {
        width: 100%;
    }

    #client .mainImageScreen .searchContainer .searchWrapper {
        width: 100%;
        padding: 0 20px
    }

    #client .mainImageScreen .textareaWrapper {
        width: 90%;
    }

    #client .mainBodyWrapper .prevImgWrapper {
        justify-content: center;
    }

    #client .mainBodyWrapper .prevImgWrapper .prevImgButton {
        min-width: 55px;
    }

    #client .mainBodyWrapper .prevImgWrapper .prevImgButton,
    #client .mainBodyWrapper .prevImgWrapper .nextImgButton {
        margin-top: 10px;
    }

    #client .mainImageScreen .textareaWrapper {
        bottom: 15%;
    }

    #client .mainImageScreen .imgCardWrapper {
        margin: 12px 0;
        padding-left: 10px !important;
        padding-right: 10px !important;
    }

    // #client .mainImageScreen .imgCardWrapper.firstimage{
    //     margin: 10px 0 10px 10px;
    // }
    // #client .mainImageScreen .imgCardWrapper.lastimage{
    //     margin: 10px 10px 10px 0;
    // }
}

@media (min-width: 767.99px) and (max-width: 835.98px) {
    #client .mainImageScreen .showImgWrapper .imgWrapper .mediaTitleWrapper {
        flex-direction: column;
        justify-content: normal;
    }

    #client .mainImageScreen .showImgWrapper .imgWrapper .mediaTitleWrapper .mediaTitle {
        width: 100%;
        display: flex;
        justify-content: center;
        font-weight: 700;
        font-size: 20px;
        margin-bottom: 30px;
    }

    #client .mainImageScreen .showImgWrapper .imgWrapper .mediaTitleWrapper .mediaPick {
        flex-direction: column;
    }

    #client .mainImageScreen .showImgWrapper .imgWrapper .mediaTitleWrapper .mediaPick .mediaPickDetails:first-child {
        margin-bottom: 30px;
    }

    #client .mainImageScreen .showImgWrapper .imgWrapper .mediaTitleWrapper .mediaPick .mediaPickDetails {
        margin-bottom: 5px;
    }
}

@media (min-width: 767.99px) and (max-width: 1279.98px) {
    #client .mainImageScreen .imgCardWrapper .imgCard img {
        min-height: 50px;
    }

    #client .mainBodyWrapper .prevImgWrapper {
        justify-content: center;
    }

    #client .mainBodyWrapper .prevImgWrapper .prevImgButton,
    #client .mainBodyWrapper .prevImgWrapper .nextImgButton {
        margin-top: 20px;
    }

    #client .mainImageScreen .textareaWrapper {
        bottom: 15%;
    }

    #client .mainImageScreen .imgCardWrapper {
        margin: 10px 0;
    }
}