@import './colors.scss';
@import './fonts.scss';

// HEADER BACKGROUND
#lumisoft-maxi header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 101;
}


#lumisoft-maxi .frontendwebsite.topBar {
    padding: 0 25px;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    align-items: center;
    -webkit-transition: margin-left 0.3s ease;
    transition: margin-left 0.3s ease;
}

#lumisoft-maxi .frontendwebsite.topBar {
    border-bottom: 0;
    height: 120px;
    transition: all .2s ease-in-out;
}

#lumisoft-maxi .frontendwebsite.topBar.small {
    transition: all .2s ease-in-out;
    height: 90px;
}

#lumisoft-maxi .frontendwebsite.topBar.after {
    border-top: 0;
}

#lumisoft-maxi.light .frontendwebsite.topBar {
    background-color: $colorWhite;
    color: $colorVeryDark;
    // box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);
}

#lumisoft-maxi.dark .frontendwebsite.topBar {
    background-color: $colorVeryDark;
    color: $colorWhite;
    // box-shadow: 0 4px 8px 0 rgba(255, 255, 255, 0.1);
}

// #lumisoft-maxi.light .frontendwebsite.topBar,
// #lumisoft-maxi.dark .frontendwebsite.topBar {
//     box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.1), 0 1px 20px 0 rgba(0, 0, 0, 0.1);
// }
// #lumisoft-maxi.dark .frontendwebsite.topBar {
//     box-shadow: 0 -3px 8px $colorGray;
//     // box-shadow: 0 1px 2px 0 rgba(255, 255, 255, 0.08), 0 1px 10px 0 rgba(255, 255, 255, 0.05);
// }

#lumisoft-maxi header .frontendwebsite .clear {
    flex: 1;
}

// HEADER CENTER
#lumisoft-maxi .frontendwebsite .headerinner {
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    flex-direction: row;
    height: 55px;
}

// HEADER LEFT
#lumisoft-maxi .frontendwebsite .topBarBottom {
    display: flex;
    flex-direction: row;
}

// HEADER LEFT
#lumisoft-maxi .frontendwebsite .topBarLeft {
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
}

#lumisoft-maxi .frontendwebsite .topBarLeft .logoImageWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
}

#lumisoft-maxi .frontendwebsite .topBarLeft .logoImageWrapper span,
#lumisoft-maxi .sideMobileWrapper .sidelogo span {
    font-family: $centurygothic;
    font-weight: 400;
    font-size: 24px;
    margin-left: 10px;
    letter-spacing: 2px;
}

#lumisoft-maxi.light .frontendwebsite .topBarLeft .logoImageWrapper span,
#lumisoft-maxi.light .sideMobileWrapper .sidelogo span {
    color: $colorVeryDark;
}

#lumisoft-maxi.dark .frontendwebsite .topBarLeft .logoImageWrapper span,
#lumisoft-maxi.dark .sideMobileWrapper .sidelogo span {
    color: $colorWhite;
}

#lumisoft-maxi .frontendwebsite .headerinner .left ul,
#lumisoft-maxi .frontendwebsite .headerinner .right ul {
    display: flex;
    flex-wrap: wrap;
    list-style-type: none;
    margin: 0;
}

#lumisoft-maxi .frontendwebsite .headerinner .left ul li,
#lumisoft-maxi .frontendwebsite .headerinner .right ul li {
    display: flex;
    margin-right: 15px;
    margin-left: 15px;
}

#lumisoft-maxi .frontendwebsite .headerinner .right ul li a {
    // width: 35px;
    // height: 35px;
    line-height: 35px;
    font-size: 35px;
    border-radius: 3px;
    // background: #aaaaaa;
    display: inline-block;
    -webkit-transition: all 0.2s linear;
    -o-transition: all 0.2s linear;
    transition: all 0.2s linear;
}

#lumisoft-maxi .frontendwebsite .headerinner .right ul li img {
    line-height: 35px;
    border-radius: 3px;
    display: inline-block;
    -webkit-transition: all 0.2s linear;
    -o-transition: all 0.2s linear;
    transition: all 0.2s linear;
    margin-bottom: 6px;
    width: 25px;
    height: 25px;
}

#lumisoft-maxi.dark .frontendwebsite .headerinner .right ul li a {
    color: $colorWhite
}

#lumisoft-maxi.dark .frontendwebsite .headerinner .right ul li a svg:hover {
    color: $colorGreen;
}

#lumisoft-maxi.light .frontendwebsite .headerinner .right ul li a {
    color: $colorDark;
}

#lumisoft-maxi.light .frontendwebsite .headerinner .right ul li a svg:hover {
    color: $colorBlueLogo;
}

#lumisoft-maxi .frontendwebsite .headerinner .left ul li:not(:last-child)::after {
    content: "";
    border-color: #ddd;
    border-left-style: solid;
    border-left-width: 1px;
}

#lumisoft-maxi .frontendwebsite .headerinner .left ul li::after {
    width: auto;
    left: auto;
    right: -15px;
    position: relative;
    height: 100%;
    border-top: 0;
    border-bottom: 0;
    border-right: 0;
    border-style: solid;
}

#lumisoft-maxi .frontendwebsite .headerinner .left ul li span {
    font-size: 13px;
    display: flex;
    flex-wrap: wrap;
}

#lumisoft-maxi.light .frontendwebsite .headerinner .left ul li span a {
    color: $colorDark;
}

#lumisoft-maxi.dark .frontendwebsite .headerinner .left ul li span a {
    color: $colorWhite;
}

#lumisoft-maxi .frontendwebsite .headerinner .left ul li .contactIcon {
    margin-right: 10px;
    display: flex;
    align-items: center;
}

#lumisoft-maxi .frontendwebsite .headerinner .left ul li .contactIcon.phone {
    font-size: 13px;
}

#lumisoft-maxi .frontendwebsite .headerinner .left ul li .contactIcon.mail {
    font-size: 17px;
}

// HEADER RIGHT
#lumisoft-maxi .frontendwebsite .topBarRight {
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
    flex-direction: row-reverse;
}

#lumisoft-maxi .topBarRight .navigation_items ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
}

#lumisoft-maxi .topBarRight .navigation_items ul li {
    position: relative;
    float: left;
    display: block;
}

#lumisoft-maxi.light header .navigation_items ul li a {
    color: $colorVeryDark;
}

#lumisoft-maxi.dark header .navigation_items ul li a {
    color: $colorWhite;
}

#lumisoft-maxi.light header .navigation_items ul li a:hover,
#lumisoft-maxi.light header .navigation_items ul li a:active,
#lumisoft-maxi.light header .navigation_items ul li a.active {
    color: $colorPurple;
}

#lumisoft-maxi.dark header .navigation_items ul li a:hover,
#lumisoft-maxi.dark header .navigation_items ul li a:active,
#lumisoft-maxi.dark header .navigation_items ul li a.active {
    color: $colorGreen;
}

#lumisoft-maxi header .navigation_items ul li a {
    text-decoration: none;
    display: inline-block;
    font-weight: 500;
    font-size: 14px;
    font-family: $montserrat;
    letter-spacing: 1.3px;
    text-transform: uppercase;
    // padding: 1rem 1rem 0.6rem 1rem;
    // border-bottom: 3px solid transparent;
    background-color: transparent !important;
    -webkit-transition: .2s linear;
    transition: .2s linear;
}

#lumisoft-maxi .navWrapper {
    height: 30px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

#lumisoft-maxi .navWrapper .topBarRight ul {
    display: flex;
    flex-wrap: wrap;
    list-style-type: none;
    margin: 0;
}

#lumisoft-maxi .navWrapper .topBarRight ul li {
    display: flex;
    margin-right: 15px;
    margin-left: 15px;
}

#lumisoft-maxi .navWrapper .topBarRight ul li::after {
    width: auto;
    left: auto;
    right: -15px;
    position: relative;
    height: 100%;
    border-top: 0;
    border-bottom: 0;
    border-right: 0;
    border-style: solid;
}

#lumisoft-maxi .navWrapper .topBarRight ul li span {
    font-size: 13px;
    display: flex;
    flex-wrap: wrap;
}

#lumisoft-maxi .navWrapper .topBarRight ul li .contactIcon {
    margin-right: 10px;
    display: flex;
    align-items: center;
}

#lumisoft-maxi .navWrapper .topBarRight ul li .contactIcon.phone {
    font-size: 13px;
}
#lumisoft-maxi .navWrapper .topBarRight ul li .contactIcon.mail {
    font-size: 17px;
}

// SIDEDRAWER
#lumisoft-maxi header .sideWebsiteWrapper {
    display: none;
}

#lumisoft-maxi .sideMobileWrapper {
    display: none;
}

@media (max-width: 767.98px) {
    #lumisoft-maxi .frontendwebsite.topBar {
        height: 70px;
    }

    #lumisoft-maxi #homepage {
        margin-top: 70px !important;
    }

    #lumisoft-maxi .frontendwebsite .headerinner {
        justify-content: unset !important;
    }
    #lumisoft-maxi .navWrapper,
    #lumisoft-maxi .headerinner .left {
        display: none;
    }

    #lumisoft-maxi .headerinner .right {
        display: none;
    }

    #lumisoft-maxi header .topBarRight {
        display: none;
    }

    #lumisoft-maxi header .navigation_items {
        display: none;
    }

    #lumisoft-maxi header .sideWebsiteWrapper {
        border-bottom: 0;
        float: left;
        margin-right: 0;
        overflow: hidden;
        position: relative;
        width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
    }

    #lumisoft-maxi .sideMobileWrapper .right {
        width: 100%;
        display: flex;
        justify-content: center;
    }

    #lumisoft-maxi .sideMobileWrapper .right ul {
        display: flex;
        flex-wrap: wrap;
        list-style-type: none;
        margin: 0;
    }

    #lumisoft-maxi .sideMobileWrapper .right ul li {
        display: flex;
        margin-right: 15px;
        margin-left: 15px;
    }

    #lumisoft-maxi .sideMobileWrapper .right ul li a {
        line-height: 35px;
        font-size: 35px;
        border-radius: 3px;
        display: inline-block;
        -webkit-transition: all 0.2s linear;
        -o-transition: all 0.2s linear;
        transition: all 0.2s linear;
    }

    #lumisoft-maxi .sideMobileWrapper .right ul li img {
        line-height: 35px;
        border-radius: 3px;
        display: inline-block;
        -webkit-transition: all 0.2s linear;
        -o-transition: all 0.2s linear;
        transition: all 0.2s linear;
        margin-bottom: 6px;
        width: 25px;
        height: 25px;
    }

    #lumisoft-maxi.dark .sideMobileWrapper .right ul li a {
        color: $colorWhite
    }

    #lumisoft-maxi.dark .sideMobileWrapper .right ul li a svg:hover {
        color: $colorGreen;
    }

    #lumisoft-maxi.light .sideMobileWrapper .right ul li a {
        color: $colorDark;
    }

    #lumisoft-maxi.light .sideMobileWrapper .right ul li a svg:hover {
        color: $colorBlueLogo;
    }

    #lumisoft-maxi header .sideWebsiteWrapper .sideWebsiteButton {
        cursor: pointer;
    }

    #lumisoft-maxi header .sideWebsiteWrapper .sideWebsiteButton span {
        display: flex;
        align-items: center;
        font-weight: 700;
        font-size: 24px;
    }

    #lumisoft-maxi.light header .sideWebsiteWrapper .sideWebsiteButton span {
        color: $colorVeryDark;
    }

    #lumisoft-maxi.dark header .sideWebsiteWrapper .sideWebsiteButton span {
        color: $colorWhite;
    }

    #lumisoft-maxi.light .sideMobileWrapper {
        box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.1);
        background: $colorWhiteBackground;
    }

    #lumisoft-maxi.dark .sideMobileWrapper {
        box-shadow: 2px 2px 2px rgba(255, 255, 255, .03);
        background: $colorVeryDarkGray;
    }

    #lumisoft-maxi .sideMobileWrapper {
        display: block;
        height: 100%;
        background: $colorVeryDark;
        box-shadow: 1px 0px 7px rgba(0, 0, 0, 0.5);
        position: fixed;
        top: 0;
        left: 0;
        width: 80%;
        max-width: 400px;
        z-index: 950;
        -webkit-transform: translateX(-110%);
        -moz-transform: translateX(-110%);
        -ms-transform: translateX(-110%);
        transform: translateX(-110%);
        transition: transform 0.2s ease;
        border-radius: 0;
        border: initial;
        margin: 0;
        padding: 10px 20px;
    }

    #lumisoft-maxi .sideMobileWrapper.open {
        transform: translateX(0);
        overflow-y: auto;
        top: 0%;
        bottom: 0%;
        position: fixed;
    }

    #lumisoft-maxi .sideMobileWrapper .sidelogo {
        padding: 15px 15px 15px 18px;
    }

    #lumisoft-maxi .sideMobileWrapper .navContainer ul {
        padding: 15px;
    }

    #lumisoft-maxi .sideMobileWrapper .navContainer li {
        display: flex;
        align-items: center;
        cursor: pointer;
    }

    #lumisoft-maxi .sideMobileWrapper .navContainer li {
        margin-bottom: 20px;
    }

    #lumisoft-maxi.light .sideMobileWrapper .navContainer li {
        background-color: $colorWhite;
    }

    #lumisoft-maxi.dark .sideMobileWrapper .navContainer li {
        background-color: $colorVeryDark;
    }

    #lumisoft-maxi .sideMobileWrapper .navContainer li a {
        text-decoration: none;
        display: inline-block;
        font-weight: 500;
        font-size: 14px;
        font-family: $montserrat;
        letter-spacing: 1.3px;
        text-transform: uppercase;
        // border-bottom: 3px solid transparent;
        background-color: transparent !important;
        -webkit-transition: .2s linear;
        transition: .2s linear;
        width: 100%;
        padding: 15px 10px;
        border-radius: 8px;
    }

    #lumisoft-maxi.light .sideMobileWrapper .navContainer li a {
        color: $colorVeryDark;
        border: 1px solid $colorVeryLightGray;
    }

    #lumisoft-maxi.dark .sideMobileWrapper .navContainer li a {
        color: $colorWhite;
        border: 1px solid $colorDarkGrayPlus;
    }

    #lumisoft-maxi.light .sideMobileWrapper .navContainer li a:active,
    #lumisoft-maxi.light .sideMobileWrapper .navContainer li a.active {
        color: $colorBlueLogo;
        border: 1px solid $colorBlueLogo;
    }

    #lumisoft-maxi.dark .sideMobileWrapper .navContainer li a:active,
    #lumisoft-maxi.dark .sideMobileWrapper .navContainer li a.active {
        color: $colorGreen;
        border: 1px solid $colorGreen;
    }

    #lumisoft-maxi .sideMobileWrapper .navContainer li a:active,
    #lumisoft-maxi .sideMobileWrapper .navContainer li a.active {
        font-weight: 500;
    }

    #lumisoft-maxi .sideMobileWrapper .subNavContainer li span {
        display: flex;
        align-items: center;
    }
}

@media (min-width: 767.99px) and (max-width: 835.98px) {
    #lumisoft-maxi .headerinner .left {
        display: none;
    }
}

// BACKDROP
#lumisoft-maxi .backdrop {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.5);
    z-index: 940;
    cursor: pointer;
}

#lumisoft-maxi .subbackdrop {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.5);
    z-index: 790;
    cursor: pointer;
}