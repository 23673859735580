@import '../colors.scss';
@import '../fonts.scss';

#root #client .sideMobileWrapper {
    display: none;
}

@media (max-width: 767.98px) {
    #root #client .sideMobileWrapper.light {
        box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.1);
        background: $colorWhiteBackground;
    }
    #root #client .sideMobileWrapper.dark {
        box-shadow: 2px 2px 2px rgba(255, 255, 255, .03);
        background: $colorVeryDarkGray;
    }
    #root #client .sideMobileWrapper {
        display: block;
        height: 100%;
        background: $colorVeryDarkGray;
        box-shadow: 1px 0px 7px rgba(0, 0, 0, 0.5);
        position: fixed;
        top: 0;
        left: 0;
        width: 80%;
        max-width: 400px;
        z-index: 950;
        -webkit-transform: translateX(-110%);
        -moz-transform: translateX(-110%);
        -ms-transform: translateX(-110%);
        transform: translateX(-110%);
        transition: transform 0.2s ease;
        border-radius: 0;
        border: initial;
        margin: 0;
        padding: 10px 0;
    }
    #root #client .sideMobileWrapper .navContainer li,
    #root #client .sideMobileWrapper .subNavContainer li {
        font-family: $montserrat;
        font-weight: 300;
        display: flex;
        align-items: center;
        cursor: pointer;
    }
    #root #client .sideMobileWrapper .navContainer li {
        border-radius: 8px;
    }
    #root #client .sideMobileWrapper .subNavContainer ul {
        margin-top: 10px;
    }
    #root #client .sideMobileWrapper .subNavContainer li {
        padding: 10px;
    }
    #root #client .sideMobileWrapper.light .navContainer li {
        background-color: $colorWhite;
        border: 1px solid $colorVeryLightGray;
    }
    #root #client .sideMobileWrapper.dark .navContainer li {
        background-color: $colorVeryDark;
        border: 1px solid $colorDarkGrayPlus;
    }

    #root #client .sideMobileWrapper.light .navContainer li.active {
        border: 1px solid $colorBlueLogo;
    }

    #root #client .sideMobileWrapper.light .navContainer li.active span {
        color: $colorBlueLogo;
    }

    #root #client .sideMobileWrapper.dark .navContainer li.active {
        border: 1px solid $colorGreen;
    }

    #root #client .sideMobileWrapper.dark .navContainer li.active span {
        color: $colorGreen;
    }

    #root #client .sideMobileWrapper.light .subNavContainer li.active span {
        color: $colorBlueLogo;
    }

    #root #client .sideMobileWrapper.dark .subNavContainer li.active span {
        color: $colorGreen;
    }

    #root #client .sideMobileWrapper .subNavContainer li.active {
        font-weight: 500;
    }
    #root #client .sideMobileWrapper.light .navContainer li span,
    #root #client .sideMobileWrapper.light .subNavContainer li span {
        color: $colorVeryDarkGray
    }
    #root #client .sideMobileWrapper.dark .navContainer li span,
    #root #client .sideMobileWrapper.dark .subNavContainer li span {
        color: $colorWhite
    }
    #root #client .sideMobileWrapper .subNavContainer li span {
        display: flex;
        align-items: center;
    }
    #root #client .sideMobileWrapper .navContainer li span svg,
    #root #client .sideMobileWrapper .subNavContainer li span svg {
        vertical-align: middle;
        height: 18px;
        width: 18px;
        margin: 12px;
    }

    #root #client .sideMobileWrapper .subNavContainer li span:first-child {
        margin-right: 10px;
    }

    #root #client .sideMobileWrapper.open {
        transform: translateX(0);
        overflow-y: auto;
        // height: 100%;
        top: 0%;
        bottom: 0%;
        position: fixed;
        // -webkit-overflow-scrolling: touch;
    }

    #root #client .sideMobileWrapper .navContainer ul {
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        padding-top: 10px;
    }
    #root #client .sideMobileWrapper .navContainer li .navTitle {
        display: none;
    }
}