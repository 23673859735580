@import './colors.scss';
@import './fonts.scss';

#lumisoft-maxi #homepage .contentwrap .section .headingblock {
    margin: auto;
}

#lumisoft-maxi.dark #homepage .contentwrap .section .headingblock h2 {
    color: $colorWhite;
}

#lumisoft-maxi.light #homepage .contentwrap .section .headingblock h2 {
    color: $colorDark;
}

#lumisoft-maxi.dark #homepage .contentwrap .section .headingblock small {
    color: $colorWhite;
}

#lumisoft-maxi.light #homepage .contentwrap .section .headingblock small {
    color: $colorDark;
}

#lumisoft-maxi #homepage .contentwrap .section .headingblock .aboutTitle {
    font-family: $montserrat;
    font-weight: 300;
    font-size: 3rem;
    line-height: 1.0625;
    text-align: center;
    color: #3e3e3e;
    letter-spacing: 0;
    width: -moz-fit-content;
    width: fit-content;
    margin: auto;
}

#lumisoft-maxi.dark #homepage .contentwrap .section .headingblock .aboutTitle {
    color: $colorWhite;
}

#lumisoft-maxi.light #homepage .contentwrap .section .headingblock .aboutTitle {
    color: $colorDark;
}

#lumisoft-maxi #homepage .contentwrap .section .headingblock .productlistwrapper {
    width: 100%;
    margin: auto;
    padding-top: 3rem;
}

#lumisoft-maxi #homepage .contentwrap .section .headingblock .productlistwrapper nav {
    color: #3e3e3e;
    margin-bottom: 27px;
}

#lumisoft-maxi #homepage .contentwrap .section .headingblock .productlistwrapper nav ul {
    width: 100%;
    display: flex;
    overflow-x: auto;
    list-style: none !important;
}

#lumisoft-maxi #homepage .contentwrap .section .headingblock .productlistwrapper nav ul li:not(:last-child) {
    margin-right: 15px;
}

#lumisoft-maxi #homepage .contentwrap .section .headingblock .productlistwrapper nav ul li {
    display: block;
    flex-shrink: 0;
}

#lumisoft-maxi #homepage .contentwrap .section .headingblock .productlistwrapper nav ul li button {
    font-family: Roboto, sans-serif;
    font-weight: 500;
    padding: 0;
    border: none;
    background: 0 0;
    transition: opacity .3s;
    opacity: 0.5;
    color: #4f565d;
    text-decoration: none;
    cursor: pointer;
}

#lumisoft-maxi #homepage .contentwrap .section .headingblock .productlistwrapper nav ul li:active button,
#lumisoft-maxi #homepage .contentwrap .section .headingblock .productlistwrapper nav ul li.active button,
#lumisoft-maxi #homepage .contentwrap .section .headingblock .productlistwrapper nav ul li button:hover {
    opacity: 1;
}

/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// PRODUCT LISTS
#lumisoft-maxi #homepage .contentwrap .section .headingblock .productlistwrapper .productlists {
    position: relative;
    display: inline-flex;
    align-content: flex-start;
    width: 100%;
    overflow-x: auto;
    column-gap: 20px;
}

#lumisoft-maxi #homepage .contentwrap .section .headingblock .productlistwrapper .productlists .productitemwrapper {
    padding: 0;
}

#lumisoft-maxi #homepage .contentwrap .section .headingblock .productlistwrapper .productlists a {
    text-decoration: none;
    cursor: pointer;
}

#lumisoft-maxi #homepage .contentwrap .section .headingblock .productlistwrapper .productlists a .productitem .productimage {
    transition: opacity .3s;
    opacity: 0.8;
}

#lumisoft-maxi #homepage .contentwrap .section .headingblock .productlistwrapper .productlists a:hover .productitem .productimage {
    opacity: 1;
}

#lumisoft-maxi #homepage .contentwrap .section .headingblock .productlistwrapper .productlists .productdesc {
    padding: 0 5px;
    font-family: $montserrat;
}

#lumisoft-maxi.dark #homepage .contentwrap .section .headingblock .productlistwrapper .productlists .productdesc {
    color: $colorWhite;
}

#lumisoft-maxi.light #homepage .contentwrap .section .headingblock .productlistwrapper .productlists .productdesc {
    color: $colorDark;
}

#lumisoft-maxi #homepage .contentwrap .section .headingblock .productlistwrapper .productlists .productdesc h3 {
    font-family: $montserrat;
    font-weight: 400;
    font-size: 1rem;
    line-height: 2.9rem;
    margin-bottom: 0;
    margin-top: .6rem;
}

#lumisoft-maxi #homepage .contentwrap .section .headingblock .productlistwrapper .productlists .productdesc span {
    max-width: 60px;
}

/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// TESTIMONY
#lumisoft-maxi #homepage .contentwrap .section .gridContainer {
    display: grid;
    grid-template: repeat(2, [row] auto)/repeat(2, [column] 50%);
    max-height: 1000px;
}

#lumisoft-maxi.dark #homepage .contentwrap .section .gridContainer .gridHeader h2 {
    color: $colorWhite;
}

#lumisoft-maxi.light #homepage .contentwrap .section .gridContainer .gridHeader h2 {
    color: $colorDark;
}

#lumisoft-maxi #homepage .contentwrap .section .gridContainer .gridHeader {
    grid-row: 1;
    grid-column: 1;
    translate: -7% 0%;
    width: fit-content;
    height: fit-content;
    margin: auto;
}

#lumisoft-maxi #homepage .contentwrap .section .gridContainer .gridHeader .headingInfinity {
    font-size: 5rem;
    height: fit-content;
    width: fit-content;
    font-family: $greatvibes;
    color: #4f565d;
    font-weight: 100;
    line-height: 1;
    letter-spacing: .28px;
    text-align: center;
}

#lumisoft-maxi #homepage .contentwrap .section .gridContainer .gridHeader .headingInfinity.alignRight {
    translate: 18% 8%;
}

#lumisoft-maxi #homepage .contentwrap .section .gridContainer .leftImage {
    translate: 30% -6%;
    display: block;
    width: fit-content;
    height: fit-content;
    margin: auto;
    grid-column: 1;
    grid-row: 2;
}

#lumisoft-maxi #homepage .contentwrap .section .gridContainer .leftImage .sketchImage {
    width: 26vw;
    max-width: 403px;
}

#lumisoft-maxi #homepage .contentwrap .section .gridContainer .leftImage .corsetImage {
    width: 16.5vw;
    max-width: 256px;
    translate: -74% -135%;
}


#lumisoft-maxi #homepage .contentwrap .section .gridContainer .leftImage .sketchImage img,
#lumisoft-maxi #homepage .contentwrap .section .gridContainer .leftImage .corsetImage img,
#lumisoft-maxi #homepage .contentwrap .section .gridContainer .rightImage .sketchImage img,
#lumisoft-maxi #homepage .contentwrap .section .gridContainer .rightImage .corsetImage img {
    height: auto;
    max-width: 100%;
}

#lumisoft-maxi #homepage .contentwrap .section .gridContainer .rightImage {
    translate: 28% 24%;
    display: flex;
    width: fit-content;
    grid-row: 1;
    grid-column: 2;
    margin: 5rem auto 0;
}

#lumisoft-maxi #homepage .contentwrap .section .gridContainer .rightImage .sketchImage {
    width: 100%;
    max-width: 269px;
    display: block;
    height: fit-content;
    line-height: 0;
}

#lumisoft-maxi #homepage .contentwrap .section .gridContainer .rightImage .corsetImage {
    translate: -22% 26%;
    width: 100%;
    max-width: 390px;
    bottom: 0;
    left: 0;
    position: relative;
    height: fit-content;
    display: block;
    line-height: 0;
}

#lumisoft-maxi #homepage .contentwrap .section .gridContainer .gridCopyRight {
    translate: 28% 24%;
    padding-top: 0;
    bottom: 8rem;
    grid-row: 2;
    grid-column: 2;
    position: relative;
    margin: auto;
    max-width: 414px;
}

#lumisoft-maxi #homepage .contentwrap .section .gridContainer .gridCopyRight p {
    font-family: $montserrat;
    text-align: center;
    font-size: 1rem;
    line-height: 1.5rem;
    letter-spacing: .3px;
}

#lumisoft-maxi #homepage .contentwrap .section .gridContainer .gridCopyRight .aboutButton {
    width: fit-content;
    margin: 4rem auto 0;
}

#lumisoft-maxi #homepage .contentwrap .section .gridContainer .gridCopyRight .aboutButton a {
    display: block;
    font-family: Montserrat;
    height: -moz-fit-content;
    height: fit-content;
    width: -moz-fit-content;
    width: fit-content;
    line-height: 0;
}

#lumisoft-maxi #homepage .contentwrap .section .gridContainer .gridCopyRight .aboutButton .button {
    text-decoration: none;
    background: 0 0;
    color: #4f565d;
    padding: 1.5rem 2.7rem;
    transition: background-color .3s, color .3s;
    font-weight: 500;
}

#lumisoft-maxi.dark #homepage .contentwrap .section .gridContainer .gridCopyRight .aboutButton .button {
    border: 1px solid $colorGreen;
    color: $colorGreen;
}

#lumisoft-maxi.light #homepage .contentwrap .section .gridContainer .gridCopyRight .aboutButton .button {
    border: 1px solid $colorBlueLogo;
    color: $colorBlueLogo;
}

#lumisoft-maxi.dark #homepage .contentwrap .section .gridContainer .gridCopyRight .aboutButton .button:hover {
    background-color: $colorGreen;
    color: $colorWhite;
}

#lumisoft-maxi.light #homepage .contentwrap .section .gridContainer .gridCopyRight .aboutButton .button:hover {
    background-color: $colorBlueLogo;
    color: $colorWhite;
}


/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// Contact US
#lumisoft-maxi #homepage .contentwrap .headingblock h2 {
    font-size: 30px;
    color: $colorDark;
    letter-spacing: 0 !important;
    font-weight: 600 !important;
    text-transform: none !important;
    margin-bottom: 5px;
    font-family: $roboto;
}

#lumisoft-maxi #homepage .contentwrap .headingblock small {
    letter-spacing: 3px !important;
    font-weight: 600 !important;
    text-transform: uppercase !important;
    color: rgba(0, 0, 0, .5);
    font-size: 80%;
    margin-bottom: 1rem !important;
    display: block;
    font-family: $roboto;
}

#lumisoft-maxi #homepage .contentwrap .buttoncontactwrapper {
    -ms-flex-pack: end !important;
    justify-content: flex-end !important;
    display: inline-flex !important;
}

#lumisoft-maxi #homepage .contentwrap .buttonmobilewrapper {
    -ms-flex-pack: start !important;
    justify-content: flex-start !important;
    display: inline-flex !important;
}

#lumisoft-maxi #homepage .contentwrap .buttoncontactwrapper .buttoncontact {
    border-radius: 3px;
    padding: 0 32px;
    font-size: 18px;
    letter-spacing: 0;
    height: 52px;
    line-height: 52px;
    display: inline-block;
    position: relative;
    cursor: pointer;
    outline: 0;
    white-space: nowrap;
    margin: 5px;
    color: #fff;
    border: none;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, .2);
    font-weight: 400 !important;
    text-transform: none !important;
    background: #25d366;
}

#lumisoft-maxi #homepage .contentwrap .buttoncontactwrapper .buttoncontact .buttonicon,
#lumisoft-maxi #homepage .contentwrap .buttonmobilewrapper .buttoncontact .buttonicon {
    position: absolute;
    top: 3px;
}

#lumisoft-maxi #homepage .contentwrap .buttoncontactwrapper .buttoncontact .buttonchat,
#lumisoft-maxi #homepage .contentwrap .buttonmobilewrapper .buttoncontact .buttonchat {
    padding-left: 23px;
}

#lumisoft-maxi #homepage .contentwrap .buttoncontactwrapper .buttoncontact.green,
#lumisoft-maxi #homepage .contentwrap .buttonmobilewrapper .buttoncontact.green {
    background: #25d366;
}

#lumisoft-maxi #homepage .contentwrap .buttonmobilewrapper .buttoncontact {
    border-radius: 3px;
    padding: 0 32px;
    font-size: 18px;
    letter-spacing: 0;
    height: 52px;
    line-height: 52px;
    display: inline-block;
    position: relative;
    cursor: pointer;
    outline: 0;
    white-space: nowrap;
    margin: 10px 0 0 0;
    color: #fff;
    border: none;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, .2);
    font-weight: 400 !important;
    text-transform: none !important;
}

#lumisoft-maxi #homepage .contentwrap .buttoncontactwrapper .buttoncontact.default,
#lumisoft-maxi #homepage .contentwrap .buttonmobilewrapper .buttoncontact.default {
    background: #333;
}

/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// RESPONSIVE
@media (max-width: 767.98px) {
    #lumisoft-maxi #homepage .contentwrap .section .gridContainer {
        display: flex;
        flex-direction: column;
    }

    #lumisoft-maxi #homepage .contentwrap .section .gridContainer .gridHeader {
        width: 100%;
        translate: 0;
    }

    #lumisoft-maxi #homepage .contentwrap .section .gridContainer .gridCopyRight {
        width: 100%;
        translate: 0;
        bottom: 0
    }

    #lumisoft-maxi #homepage .contentwrap .section .gridContainer .leftImage,
    #lumisoft-maxi #homepage .contentwrap .section .gridContainer .rightImage {
        display: none;
    }

    #lumisoft-maxi #homepage .contentwrap .section .headingblock .productlistwrapper {
        padding-top: 1.5rem;
    }

    #lumisoft-maxi #homepage .contentwrap .section .headingblock .productlistwrapper .productlists {
        display: block;
    }

    #lumisoft-maxi #homepage .contentwrap .section .headingblock .productlistwrapper .productlists a {
        margin-bottom: 50px;
    }

    #lumisoft-maxi #homepage .contentwrap .section .headingblock .aboutTitle {
        padding-top: 30px;
        font-size: 30px;
    }
}