@use "sass:math";
@import '../colors.scss';
@import '../fonts.scss';

$squarew: 15px;
$squareh: 15px;
$squaremargin: 45px;
$loaderw: $squarew * 3 + $squaremargin *2;
$loaderh: $squareh * 3 + $squaremargin *2;

.emailVerifyWrapper {
    position: absolute;
    width: 100%;
    height: 100%;
}

.emailVerifyWrapper.light {
    background-color: $colorVeryLightGray;
}
.emailVerifyWrapper.dark {
    background-color: $colorVeryDarkGray;
}

.emailVerifyWrapper .emailIcon {
    position: absolute;
    left: 50%;
    top: 45%;
    margin-left: math.div((-$loaderh), 2);
    margin-top: math.div((-$loaderh), 2);
}

.emailVerifyWrapper .emailIcon .centerWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.emailVerifyWrapper .emailIcon .centerWrapper h5 {
    font-size: 30px;
    font-family: $montserrat;
    margin-top: -30px;
    margin-bottom: 30px;
    text-transform: uppercase;
}

.emailVerifyWrapper.light .emailIcon .centerWrapper h5 {
    color: $colorVeryDark;
}
.emailVerifyWrapper.light .emailIcon .centerWrapper svg {
    color: $colorVeryDark;
}
.emailVerifyWrapper.light .emailIcon .centerWrapper span.colors svg {
    color: $colorBlueLogo;
}

.emailVerifyWrapper.dark .emailIcon .centerWrapper h5 {
    color: $colorWhite;
}
.emailVerifyWrapper.dark .emailIcon .centerWrapper svg {
    color: $colorWhite;
}
.emailVerifyWrapper.dark .emailIcon .centerWrapper span.colors svg {
    color: $colorGreen;
}

.emailVerifyWrapper .emailIcon .centerWrapper svg {
    font-size: 100px;
}