/***********************************************
                    MARGIN
***********************************************/
.m0 {
    margin: 0 !important;
}
.m3 {
    margin: 3px !important;
}
.m5 {
    margin: 5px !important;
}
.m8 {
    margin: 8px !important;
}
.m10 {
    margin: 10px !important;
}
.m15 {
    margin: 15px !important;
}
.m20 {
    margin: 20px !important;
}
.m25 {
    margin: 25px !important;
}
.m30 {
    margin: 30px !important;
}
.m35 {
    margin: 35px !important;
}
.m40 {
    margin: 40px !important;
}
.m45 {
    margin: 45px !important;
}
.m50 {
    margin: 50px !important;
}
.m55 {
    margin: 55px !important;
}
.m60 {
    margin: 60px !important;
}
.m65 {
    margin: 65px !important;
}
.m70 {
    margin: 70px !important;
}
.m75 {
    margin: 75px !important;
}
.m80 {
    margin: 80px !important;
}
.m85 {
    margin: 85px !important;
}
.m90 {
    margin: 90px !important;
}
.m95 {
    margin: 95px !important;
}
.m100 {
    margin: 100px !important;
}
/***********************************************
                MARGIN LEFT
***********************************************/
.ml0 {
    margin-left: 0 !important;
}
.ml3 {
    margin-left: 3px !important;
}
.ml5 {
    margin-left: 5px !important;
}
.ml8 {
    margin-left: 8px !important;
}
.ml10 {
    margin-left: 10px !important;
}
.ml15 {
    margin-left: 15px !important;
}
.ml20 {
    margin-left: 20px !important;
}
.ml25 {
    margin-left: 25px !important;
}
.ml30 {
    margin-left: 30px !important;
}
.ml35 {
    margin-left: 35px !important;
}
.ml40 {
    margin-left: 40px !important;
}
.ml45 {
    margin-left: 45px !important;
}
.ml50 {
    margin-left: 50px !important;
}
.ml55 {
    margin-left: 55px !important;
}
.ml60 {
    margin-left: 60px !important;
}
.ml65 {
    margin-left: 65px !important;
}
.ml70 {
    margin-left: 70px !important;
}
.ml75 {
    margin-left: 75px !important;
}
.ml80 {
    margin-left: 80px !important;
}
.ml85 {
    margin-left: 85px !important;
}
.ml90 {
    margin-left: 90px !important;
}
.ml95 {
    margin-left: 95px !important;
}
.ml100 {
    margin-left: 100px !important;
}
/***********************************************
                MARGIN RIGHT
***********************************************/
.mr0 {
    margin-right: 0 !important;
}
.mr3 {
    margin-right: 3px !important;
}
.mr5 {
    margin-right: 5px !important;
}
.mr8 {
    margin-right: 8px !important;
}
.mr10 {
    margin-right: 10px !important;
}
.mr15 {
    margin-right: 15px !important;
}
.mr20 {
    margin-right: 20px !important;
}
.mr25 {
    margin-right: 25px !important;
}
.mr30 {
    margin-right: 30px !important;
}
.mr35 {
    margin-right: 35px !important;
}
.mr40 {
    margin-right: 40px !important;
}
.mr45 {
    margin-right: 45px !important;
}
.mr50 {
    margin-right: 50px !important;
}
.mr55 {
    margin-right: 55px !important;
}
.mr60 {
    margin-right: 60px !important;
}
.mr65 {
    margin-right: 65px !important;
}
.mr70 {
    margin-right: 70px !important;
}
.mr75 {
    margin-right: 75px !important;
}
.mr80 {
    margin-right: 80px !important;
}
.mr85 {
    margin-right: 85px !important;
}
.mr90 {
    margin-right: 90px !important;
}
.mr95 {
    margin-right: 95px !important;
}
.mr100 {
    margin-right: 100px !important;
}
/***********************************************
                MARGIN BOTTOM
***********************************************/
.mb0{
    margin-bottom: 0 !important;
}
.mb3 {
    margin-bottom: 3px !important;
}
.mb5 {
    margin-bottom: 5px !important;
}
.mb8 {
    margin-bottom: 8px !important;
}
.mb10 {
    margin-bottom: 10px !important;
}
.mb15 {
    margin-bottom: 15px !important;
}
.mb20 {
    margin-bottom: 20px !important;
}
.mb25 {
    margin-bottom: 25px !important;
}
.mb30 {
    margin-bottom: 30px !important;
}
.mb35 {
    margin-bottom: 35px !important;
}
.mb40 {
    margin-bottom: 40px !important;
}
.mb45 {
    margin-bottom: 45px !important;
}
.mb50 {
    margin-bottom: 50px !important;
}
.mb55 {
    margin-bottom: 55px !important;
}
.mb60 {
    margin-bottom: 60px !important;
}
.mb65 {
    margin-bottom: 65px !important;
}
.mb70 {
    margin-bottom: 70px !important;
}
.mb75 {
    margin-bottom: 75px !important;
}
.mb80 {
    margin-bottom: 80px !important;
}
.mb85 {
    margin-bottom: 85px !important;
}
.mb90 {
    margin-bottom: 90px !important;
}
.mb95 {
    margin-bottom: 95px !important;
}
.mb100 {
    margin-bottom: 100px !important;
}
/***********************************************
                MARGIN TOP
***********************************************/
.mt0 {
    margin-top: 0 !important;
}
.mt3 {
    margin-top: 3px !important;
}
.mt5 {
    margin-top: 5px !important;
}
.mt8 {
    margin-top: 8px !important;
}
.mt10 {
    margin-top: 10px !important;
}
.mt15 {
    margin-top: 15px !important;
}
.mt20 {
    margin-top: 20px !important;
}
.mt25 {
    margin-top: 25px !important;
}
.mt30 {
    margin-top: 30px !important;
}
.mt35 {
    margin-top: 35px !important;
}
.mt40 {
    margin-top: 40px !important;
}
.mt45 {
    margin-top: 45px !important;
}
.mt50 {
    margin-top: 50px !important;
}
.mt55 {
    margin-top: 55px !important;
}
.mt60 {
    margin-top: 60px !important;
}
.mt65 {
    margin-top: 65px !important;
}
.mt70 {
    margin-top: 70px !important;
}
.mt75 {
    margin-top: 75px !important;
}
.mt80 {
    margin-top: 80px !important;
}
.mt85 {
    margin-top: 85px !important;
}
.mt90 {
    margin-top: 90px !important;
}
.mt95 {
    margin-top: 95px !important;
}
.mt100 {
    margin-top: 100px !important;
}

@media (max-width: 767.98px) {
    /***********************************************
                        MARGIN
    ***********************************************/
    .m-xs-0 {
        margin: 0 !important;
    }
    .m-xs-3 {
        margin: 3px !important;
    }
    .m-xs-5 {
        margin: 5px !important;
    }
    .m-xs-8 {
        margin: 8px !important;
    }
    .m-xs-10 {
        margin: 10px !important;
    }
    .m-xs-15 {
        margin: 15px !important;
    }
    .m-xs-20 {
        margin: 20px !important;
    }
    .m-xs-25 {
        margin: 25px !important;
    }
    .m-xs-30 {
        margin: 30px !important;
    }
    .m-xs-35 {
        margin: 35px !important;
    }
    .m-xs-40 {
        margin: 40px !important;
    }
    .m-xs-45 {
        margin: 45px !important;
    }
    .m-xs-50 {
        margin: 50px !important;
    }
    .m-xs-55 {
        margin: 55px !important;
    }
    .m-xs-60 {
        margin: 60px !important;
    }
    .m-xs-65 {
        margin: 65px !important;
    }
    .m-xs-70 {
        margin: 70px !important;
    }
    .m-xs-75 {
        margin: 75px !important;
    }
    .m-xs-80 {
        margin: 80px !important;
    }
    .m-xs-85 {
        margin: 85px !important;
    }
    .m-xs-90 {
        margin: 90px !important;
    }
    .m-xs-95 {
        margin: 95px !important;
    }
    .m-xs-100 {
        margin: 100px !important;
    }
    /***********************************************
                    MARGIN LEFT
    ***********************************************/
    .ml-xs-0 {
        margin-left: 0 !important;
    }
    .ml-xs-3 {
        margin-left: 3px !important;
    }
    .ml-xs-5 {
        margin-left: 5px !important;
    }
    .ml-xs-8 {
        margin-left: 8px !important;
    }
    .ml-xs-10 {
        margin-left: 10px !important;
    }
    .ml-xs-15 {
        margin-left: 15px !important;
    }
    .ml-xs-20 {
        margin-left: 20px !important;
    }
    .ml-xs-25 {
        margin-left: 25px !important;
    }
    .ml-xs-30 {
        margin-left: 30px !important;
    }
    .ml-xs-35 {
        margin-left: 35px !important;
    }
    .ml-xs-40 {
        margin-left: 40px !important;
    }
    .ml-xs-45 {
        margin-left: 45px !important;
    }
    .ml-xs-50 {
        margin-left: 50px !important;
    }
    .ml-xs-55 {
        margin-left: 55px !important;
    }
    .ml-xs-60 {
        margin-left: 60px !important;
    }
    .ml-xs-65 {
        margin-left: 65px !important;
    }
    .ml-xs-70 {
        margin-left: 70px !important;
    }
    .ml-xs-75 {
        margin-left: 75px !important;
    }
    .ml-xs-80 {
        margin-left: 80px !important;
    }
    .ml-xs-85 {
        margin-left: 85px !important;
    }
    .ml-xs-90 {
        margin-left: 90px !important;
    }
    .ml-xs-95 {
        margin-left: 95px !important;
    }
    .ml-xs-100 {
        margin-left: 100px !important;
    }
    /***********************************************
                    MARGIN RIGHT
    ***********************************************/
    .mr-xs-0 {
        margin-right: 0 !important;
    }
    .mr-xs-3 {
        margin-right: 3px !important;
    }
    .mr-xs-5 {
        margin-right: 5px !important;
    }
    .mr-xs-8 {
        margin-right: 8px !important;
    }
    .mr-xs-10 {
        margin-right: 10px !important;
    }
    .mr-xs-15 {
        margin-right: 15px !important;
    }
    .mr-xs-20 {
        margin-right: 20px !important;
    }
    .mr-xs-25 {
        margin-right: 25px !important;
    }
    .mr-xs-30 {
        margin-right: 30px !important;
    }
    .mr-xs-35 {
        margin-right: 35px !important;
    }
    .mr-xs-40 {
        margin-right: 40px !important;
    }
    .mr-xs-45 {
        margin-right: 45px !important;
    }
    .mr-xs-50 {
        margin-right: 50px !important;
    }
    .mr-xs-55 {
        margin-right: 55px !important;
    }
    .mr-xs-60 {
        margin-right: 60px !important;
    }
    .mr-xs-65 {
        margin-right: 65px !important;
    }
    .mr-xs-70 {
        margin-right: 70px !important;
    }
    .mr-xs-75 {
        margin-right: 75px !important;
    }
    .mr-xs-80 {
        margin-right: 80px !important;
    }
    .mr-xs-85 {
        margin-right: 85px !important;
    }
    .mr-xs-90 {
        margin-right: 90px !important;
    }
    .mr-xs-95 {
        margin-right: 95px !important;
    }
    .mr-xs-100 {
        margin-right: 100px !important;
    }
    /***********************************************
                    MARGIN BOTTOM
    ***********************************************/
    .mb-xs-0{
        margin-bottom: 0 !important;
    }
    .mb-xs-3 {
        margin-bottom: 3px !important;
    }
    .mb-xs-5 {
        margin-bottom: 5px !important;
    }
    .mb-xs-8 {
        margin-bottom: 8px !important;
    }
    .mb-xs-10 {
        margin-bottom: 10px !important;
    }
    .mb-xs-15 {
        margin-bottom: 15px !important;
    }
    .mb-xs-20 {
        margin-bottom: 20px !important;
    }
    .mb-xs-25 {
        margin-bottom: 25px !important;
    }
    .mb-xs-30 {
        margin-bottom: 30px !important;
    }
    .mb-xs-35 {
        margin-bottom: 35px !important;
    }
    .mb-xs-40 {
        margin-bottom: 40px !important;
    }
    .mb-xs-45 {
        margin-bottom: 45px !important;
    }
    .mb-xs-50 {
        margin-bottom: 50px !important;
    }
    .mb-xs-55 {
        margin-bottom: 55px !important;
    }
    .mb-xs-60 {
        margin-bottom: 60px !important;
    }
    .mb-xs-65 {
        margin-bottom: 65px !important;
    }
    .mb-xs-70 {
        margin-bottom: 70px !important;
    }
    .mb-xs-75 {
        margin-bottom: 75px !important;
    }
    .mb-xs-80 {
        margin-bottom: 80px !important;
    }
    .mb-xs-85 {
        margin-bottom: 85px !important;
    }
    .mb-xs-90 {
        margin-bottom: 90px !important;
    }
    .mb-xs-95 {
        margin-bottom: 95px !important;
    }
    .mb-xs-100 {
        margin-bottom: 100px !important;
    }
    /***********************************************
                    MARGIN TOP
    ***********************************************/
    .mt-xs-0 {
        margin-top: 0 !important;
    }
    .mt-xs-3 {
        margin-top: 3px !important;
    }
    .mt-xs-5 {
        margin-top: 5px !important;
    }
    .mt-xs-8 {
        margin-top: 8px !important;
    }
    .mt-xs-10 {
        margin-top: 10px !important;
    }
    .mt-xs-15 {
        margin-top: 15px !important;
    }
    .mt-xs-20 {
        margin-top: 20px !important;
    }
    .mt-xs-25 {
        margin-top: 25px !important;
    }
    .mt-xs-30 {
        margin-top: 30px !important;
    }
    .mt-xs-35 {
        margin-top: 35px !important;
    }
    .mt-xs-40 {
        margin-top: 40px !important;
    }
    .mt-xs-45 {
        margin-top: 45px !important;
    }
    .mt-xs-50 {
        margin-top: 50px !important;
    }
    .mt-xs-55 {
        margin-top: 55px !important;
    }
    .mt-xs-60 {
        margin-top: 60px !important;
    }
    .mt-xs-65 {
        margin-top: 65px !important;
    }
    .mt-xs-70 {
        margin-top: 70px !important;
    }
    .mt-xs-75 {
        margin-top: 75px !important;
    }
    .mt-xs-80 {
        margin-top: 80px !important;
    }
    .mt-xs-85 {
        margin-top: 85px !important;
    }
    .mt-xs-90 {
        margin-top: 90px !important;
    }
    .mt-xs-95 {
        margin-top: 95px !important;
    }
    .mt-xs-100 {
        margin-top: 100px !important;
    }
}

@media (min-width: 767.99px) and (max-width: 835.98px) {
/***********************************************
                        MARGIN
    ***********************************************/
    .m-sm-0 {
        margin: 0 !important;
    }
    .m-sm-3 {
        margin: 3px !important;
    }
    .m-sm-5 {
        margin: 5px !important;
    }
    .m-sm-8 {
        margin: 8px !important;
    }
    .m-sm-10 {
        margin: 10px !important;
    }
    .m-sm-15 {
        margin: 15px !important;
    }
    .m-sm-20 {
        margin: 20px !important;
    }
    .m-sm-25 {
        margin: 25px !important;
    }
    .m-sm-30 {
        margin: 30px !important;
    }
    .m-sm-35 {
        margin: 35px !important;
    }
    .m-sm-40 {
        margin: 40px !important;
    }
    .m-sm-45 {
        margin: 45px !important;
    }
    .m-sm-50 {
        margin: 50px !important;
    }
    .m-sm-55 {
        margin: 55px !important;
    }
    .m-sm-60 {
        margin: 60px !important;
    }
    .m-sm-65 {
        margin: 65px !important;
    }
    .m-sm-70 {
        margin: 70px !important;
    }
    .m-sm-75 {
        margin: 75px !important;
    }
    .m-sm-80 {
        margin: 80px !important;
    }
    .m-sm-85 {
        margin: 85px !important;
    }
    .m-sm-90 {
        margin: 90px !important;
    }
    .m-sm-95 {
        margin: 95px !important;
    }
    .m-sm-100 {
        margin: 100px !important;
    }
    /***********************************************
                    MARGIN LEFT
    ***********************************************/
    .ml-sm-0 {
        margin-left: 0 !important;
    }
    .ml-sm-3 {
        margin-left: 3px !important;
    }
    .ml-sm-5 {
        margin-left: 5px !important;
    }
    .ml-sm-8 {
        margin-left: 8px !important;
    }
    .ml-sm-10 {
        margin-left: 10px !important;
    }
    .ml-sm-15 {
        margin-left: 15px !important;
    }
    .ml-sm-20 {
        margin-left: 20px !important;
    }
    .ml-sm-25 {
        margin-left: 25px !important;
    }
    .ml-sm-30 {
        margin-left: 30px !important;
    }
    .ml-sm-35 {
        margin-left: 35px !important;
    }
    .ml-sm-40 {
        margin-left: 40px !important;
    }
    .ml-sm-45 {
        margin-left: 45px !important;
    }
    .ml-sm-50 {
        margin-left: 50px !important;
    }
    .ml-sm-55 {
        margin-left: 55px !important;
    }
    .ml-sm-60 {
        margin-left: 60px !important;
    }
    .ml-sm-65 {
        margin-left: 65px !important;
    }
    .ml-sm-70 {
        margin-left: 70px !important;
    }
    .ml-sm-75 {
        margin-left: 75px !important;
    }
    .ml-sm-80 {
        margin-left: 80px !important;
    }
    .ml-sm-85 {
        margin-left: 85px !important;
    }
    .ml-sm-90 {
        margin-left: 90px !important;
    }
    .ml-sm-95 {
        margin-left: 95px !important;
    }
    .ml-sm-100 {
        margin-left: 100px !important;
    }
    /***********************************************
                    MARGIN RIGHT
    ***********************************************/
    .mr-sm-0 {
        margin-right: 0 !important;
    }
    .mr-sm-3 {
        margin-right: 3px !important;
    }
    .mr-sm-5 {
        margin-right: 5px !important;
    }
    .mr-sm-8 {
        margin-right: 8px !important;
    }
    .mr-sm-10 {
        margin-right: 10px !important;
    }
    .mr-sm-15 {
        margin-right: 15px !important;
    }
    .mr-sm-20 {
        margin-right: 20px !important;
    }
    .mr-sm-25 {
        margin-right: 25px !important;
    }
    .mr-sm-30 {
        margin-right: 30px !important;
    }
    .mr-sm-35 {
        margin-right: 35px !important;
    }
    .mr-sm-40 {
        margin-right: 40px !important;
    }
    .mr-sm-45 {
        margin-right: 45px !important;
    }
    .mr-sm-50 {
        margin-right: 50px !important;
    }
    .mr-sm-55 {
        margin-right: 55px !important;
    }
    .mr-sm-60 {
        margin-right: 60px !important;
    }
    .mr-sm-65 {
        margin-right: 65px !important;
    }
    .mr-sm-70 {
        margin-right: 70px !important;
    }
    .mr-sm-75 {
        margin-right: 75px !important;
    }
    .mr-sm-80 {
        margin-right: 80px !important;
    }
    .mr-sm-85 {
        margin-right: 85px !important;
    }
    .mr-sm-90 {
        margin-right: 90px !important;
    }
    .mr-sm-95 {
        margin-right: 95px !important;
    }
    .mr-sm-100 {
        margin-right: 100px !important;
    }
    /***********************************************
                    MARGIN BOTTOM
    ***********************************************/
    .mb-sm-0{
        margin-bottom: 0 !important;
    }
    .mb-sm-3 {
        margin-bottom: 3px !important;
    }
    .mb-sm-5 {
        margin-bottom: 5px !important;
    }
    .mb-sm-8 {
        margin-bottom: 8px !important;
    }
    .mb-sm-10 {
        margin-bottom: 10px !important;
    }
    .mb-sm-15 {
        margin-bottom: 15px !important;
    }
    .mb-sm-20 {
        margin-bottom: 20px !important;
    }
    .mb-sm-25 {
        margin-bottom: 25px !important;
    }
    .mb-sm-30 {
        margin-bottom: 30px !important;
    }
    .mb-sm-35 {
        margin-bottom: 35px !important;
    }
    .mb-sm-40 {
        margin-bottom: 40px !important;
    }
    .mb-sm-45 {
        margin-bottom: 45px !important;
    }
    .mb-sm-50 {
        margin-bottom: 50px !important;
    }
    .mb-sm-55 {
        margin-bottom: 55px !important;
    }
    .mb-sm-60 {
        margin-bottom: 60px !important;
    }
    .mb-sm-65 {
        margin-bottom: 65px !important;
    }
    .mb-sm-70 {
        margin-bottom: 70px !important;
    }
    .mb-sm-75 {
        margin-bottom: 75px !important;
    }
    .mb-sm-80 {
        margin-bottom: 80px !important;
    }
    .mb-sm-85 {
        margin-bottom: 85px !important;
    }
    .mb-sm-90 {
        margin-bottom: 90px !important;
    }
    .mb-sm-95 {
        margin-bottom: 95px !important;
    }
    .mb-sm-100 {
        margin-bottom: 100px !important;
    }
    /***********************************************
                    MARGIN TOP
    ***********************************************/
    .mt-sm-0 {
        margin-top: 0 !important;
    }
    .mt-sm-3 {
        margin-top: 3px !important;
    }
    .mt-sm-5 {
        margin-top: 5px !important;
    }
    .mt-sm-8 {
        margin-top: 8px !important;
    }
    .mt-sm-10 {
        margin-top: 10px !important;
    }
    .mt-sm-15 {
        margin-top: 15px !important;
    }
    .mt-sm-20 {
        margin-top: 20px !important;
    }
    .mt-sm-25 {
        margin-top: 25px !important;
    }
    .mt-sm-30 {
        margin-top: 30px !important;
    }
    .mt-sm-35 {
        margin-top: 35px !important;
    }
    .mt-sm-40 {
        margin-top: 40px !important;
    }
    .mt-sm-45 {
        margin-top: 45px !important;
    }
    .mt-sm-50 {
        margin-top: 50px !important;
    }
    .mt-sm-55 {
        margin-top: 55px !important;
    }
    .mt-sm-60 {
        margin-top: 60px !important;
    }
    .mt-sm-65 {
        margin-top: 65px !important;
    }
    .mt-sm-70 {
        margin-top: 70px !important;
    }
    .mt-sm-75 {
        margin-top: 75px !important;
    }
    .mt-sm-80 {
        margin-top: 80px !important;
    }
    .mt-sm-85 {
        margin-top: 85px !important;
    }
    .mt-sm-90 {
        margin-top: 90px !important;
    }
    .mt-sm-95 {
        margin-top: 95px !important;
    }
    .mt-sm-100 {
        margin-top: 100px !important;
    }
}

@media (min-width: 835.99px) and (max-width: 1023.98px){

}

@media (min-width: 1024px) and (max-width: 1279.98px){

}