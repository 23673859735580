@import '../colors.scss';
@import '../fonts.scss';


#client .clientpagelayout .tagsOuter {
    height: 100px;
}

#client .clientpagelayout .tagsContainer {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: .5em;
    width: 100%;
    font-family: $arimo;
    -webkit-transition: all .3s;
    -o-transition: all .3s;
    transition: all .3s;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    margin: 0;
    list-style: none;
    position: relative;
    // height: 44px;
    font-size: 15px;
    background-image: none;
    border-radius: 8px;
}

#client .clientpagelayout .tagsContainer .tagsWrapper {
    padding: 0 0 0 30px;
    width: 100%;
    cursor: text;
}

#client .clientpagelayout .tagsContainer .tagsWrapper .tagInput {
    flex-grow: 1;
    padding: .5em 0;
    border: none;
    outline: none;
    width: auto !important;
}

#client .clientpagelayout .tagsContainer .tagsWrapper .tagItem {
    padding: .3em .75em;
    border-radius: 20px;
    display: inline-block;
    margin-right: .4em;
}

#client .clientpagelayout .tagsContainer .tagsWrapper .tagItem .close {
    height: 20px;
    width: 20px;
    border-radius: 50%;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    margin-left: .4em;
    font-size: 18px;
    cursor: pointer;
}

#client .clientpagelayout.light .tagsContainer .tagsWrapper .tagItem .close {
    background-color: $colorBlueLogo;
    color: $colorWhite;
}

#client .clientpagelayout.dark .tagsContainer .tagsWrapper .tagItem .close {
    background-color: $colorGreen;
    color: $colorWhite;
}

#client .clientpagelayout.light .tableWrapper .showTagsWrapper .showTags,
#client .clientpagelayout.light .tagsContainer .tagsWrapper .tagItem {
    background-color: $colorWhite4;
}

#client .clientpagelayout.dark .tableWrapper .showTagsWrapper .showTags,
#client .clientpagelayout.dark .tagsContainer .tagsWrapper .tagItem {
    background-color: $colorVeryDark;
}

#client .clientpagelayout .tableWrapper .showTagsWrapper {
    width: 100%;
}

#client .clientpagelayout .tableWrapper .showTagsWrapper .showTags {
    padding: .3em .75em;
    border-radius: 20px;
    display: inline-block;
    margin-right: 20px;
}

#client .clientpagelayout .tagsContainer .iconPosition {
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    line-height: 0;
    color: $colorDarkGray;
    z-index: 3;
    margin-left: 10px;
}

#client .clientpagelayout.dark .tagsContainer {
    color: $colorWhite;
    background-color: $colorVeryDarkGray;
    border: 1px solid $colorDarkGrayPlus;
}

#client .clientpagelayout.dark .tagsContainer:hover {
    border-color: $colorDarkGray !important;
}

#client .clientpagelayout.dark .tagsContainer:focus {
    border-color: $colorDarkGray !important;
    background-color: $colorVeryDarkGray !important;
    outline: none;
}

#client .clientpagelayout.dark .tagsContainer.disabled {
    background-color: $colorDarkGrayPlus;
    pointer-events: none;
    color: $colorGray !important;
}

#client .clientpagelayout.light .tagsContainer {
    color: $colorVeryDark;
    background-color: $colorWhite;
    border: 1px solid $colorVeryLightGray;
}

#client .clientpagelayout.light .tagsContainer:hover {
    border-color: $colorLightGray !important;
}

#client .clientpagelayout.light .tagsContainer:focus {
    border-color: $colorLightGray !important;
    background-color: $colorWhite !important;
    outline: none;
}

#client .clientpagelayout.light .tagsContainer.disabled {
    background-color: $colorVeryLightGray;
    pointer-events: none;
    color: $colorDarkGrayPlus !important;
}

#client .clientpagelayout .formWrapper.tagsonly {
    margin-bottom: 40px;
}

#client .clientpagelayout .tagsContainer .tagsWrapper .errorLabel {
    bottom: 0;
}

@media (max-width: 767.98px) {
    #client .clientpagelayout .formWrapper.tagsonly {
        height: 240px;
        padding: 10px 0;
    }

    #client .clientpagelayout .tagsContainer {
        border: 0 !important;
    }

    #client .clientpagelayout .tagsContainer .tagsWrapper {
        display: flex;
        flex-direction: column;
        padding-left: 10px;
    }

    #client .clientpagelayout .tagsContainer .tagsWrapper .tagItem {
        margin-bottom: 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    #client .clientpagelayout .tableWrapper td {
        min-width: 120px;
    }
    #client .clientpagelayout .tableWrapper .showTagsWrapper {
        flex-direction: row;
        display: flex;
    }
}