@import './colors.scss';
@import './fonts.scss';

#lumisoft-blank #frontfooter,
#lumisoft-maxi #frontfooter {
    background-color: $colorDark;
    color: $colorWhite;
}
#lumisoft-blank #frontfooter .frontwidget,
#lumisoft-maxi #frontfooter .frontwidget {
    margin-top: 0;
    position: relative;
    padding: 60px 40px;
}
#lumisoft-blank #frontfooter .frontwidget.first,
#lumisoft-maxi #frontfooter .frontwidget.first {
    margin-top: 0;
    position: relative;
    padding: 60px 40px 20px 40px;
}
///////////// ADDRESS
#lumisoft-blank #frontfooter .frontwidget h3,
#lumisoft-maxi #frontfooter .frontwidget h3 {
    font-family: $roboto;
    margin-bottom: 20px;
    color: $colorWhite;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 22px;
}
#lumisoft-blank #frontfooter .frontwidget address,
#lumisoft-maxi #frontfooter .frontwidget address {
    font-style: normal;
    line-height: inherit;
    margin-bottom: 30px;
}
#lumisoft-blank #frontfooter .frontwidget abbr[title], 
#lumisoft-blank #frontfooter .frontwidget abbr[data-original-title],
#lumisoft-maxi #frontfooter .frontwidget abbr[title], 
#lumisoft-maxi #frontfooter .frontwidget abbr[data-original-title] {
    text-decoration: underline;
    -webkit-text-decoration: underline dotted;
    text-decoration: underline dotted;
    cursor: help;
    border-bottom: 0;
    -webkit-text-decoration-skip-ink: none;
    text-decoration-skip-ink: none;
}

///////////// SOCIAL
#lumisoft-blank #frontfooter .frontwidget .footersocial ul,
#lumisoft-maxi #frontfooter .frontwidget .footersocial ul {
    padding-top: 5px;
}
#lumisoft-blank #frontfooter .frontwidget .footersocial ul li,
#lumisoft-maxi #frontfooter .frontwidget .footersocial ul li {
    display: inline-block;
    text-align: center;
    margin-right: 20px;
}
#lumisoft-blank #frontfooter .frontwidget .footersocial ul li a,
#lumisoft-maxi #frontfooter .frontwidget .footersocial ul li a {
    // width: 35px;
    // height: 35px;
    line-height: 35px;
    font-size: 35px;
    color: #777777;
    border-radius: 3px;
    // background: #aaaaaa;
    display: inline-block;
    -webkit-transition: all 0.2s linear;
    -o-transition:all 0.2s linear;
    transition: all 0.2s linear;
}
#lumisoft-blank #frontfooter .frontwidget .footersocial ul li a svg:hover,
#lumisoft-maxi #frontfooter .frontwidget .footersocial ul li a svg:hover {
    color: $colorWhite;
}

///////////// COPYRIGHT
#lumisoft-blank #frontfooter .homecopyright,
#lumisoft-maxi #frontfooter .homecopyright {
    display: flex;
    justify-content: center;
}
#lumisoft-blank #frontfooter .homecopyright p.footercopy,
#lumisoft-maxi #frontfooter .homecopyright p.footercopy {
    margin-bottom: 0;
    font-size: 16px;
    color: #fff;
    padding: 20px 0;
    margin-bottom: 20px;
}
#lumisoft-blank #frontfooter .homecopyright p.footercopy a,
#lumisoft-maxi #frontfooter .homecopyright p.footercopy a {
    color: #888888;
}
#lumisoft-blank #frontfooter .homecopyright p.footercopy a:hover ,
#lumisoft-maxi #frontfooter .homecopyright p.footercopy a:hover {
    color: $colorWhite;
}