@import '../fonts.scss';
@import '../colors.scss';

/***********************************************
                PAGINATION
***********************************************/
#client .cardWrapper .paginationWrapper,
#client .mainImageScreen .paginationWrapper {
    width: 100%;
}

#client .cardWrapper .paginationWrapper .pagination,
#client .mainImageScreen .pagination {
    display: -ms-flexbox;
    display: flex;
    padding-left: 0;
    list-style: none;
    border-radius: .25rem;
    justify-content: center;
}

#client .cardWrapper .paginationWrapper .pagination .pageItem,
#client .mainImageScreen .pageItem {
    height: 38px;
    width: 38px;
    display: flex;
    cursor: pointer;
    border-radius: 8px;
    justify-content: center;
    align-items: center;
}

#client .cardWrapper .paginationWrapper .pagination .pageItem .pageLink,
#client .mainImageScreen .pageItem .pageLink {
    display: flex;
    justify-content: center;
    align-items: center;
}

#client .cardWrapper .paginationWrapper .pagination .pageItem.active .pageLink,
#client .cardWrapper .paginationWrapper .pagination .pageItem.disabled .pageLink,
#client .mainImageScreen .pageItem.active .pageLink,
#client .mainImageScreen .pageItem.disabled .pageLink {
    vertical-align: middle;
    height: 18px;
    width: 18px;
    margin: 12px;
}

#client .cardWrapper .paginationWrapper .pagination .pageItem:not(:last-child),
#client .mainImageScreen .pageItem:not(:last-child) {
    margin-right: 10px;
}

// #client .mainImageScreen .pageItem.disabled .pageLink, 
// #client .pageItem.disabled .pageLink:focus, 
// #client .pageItem.disabled .pageLink:hover {
//     opacity: 0.6;
// }
// #client .mainImageScreen .pageItem.active .pageLink, 
// #client .pageItem.active .pageLink:focus, 
// #client .pageItem.active .pageLink:hover {
//     font-weight: 500;
// }
// #client .mainImageScreen .pageItem.active .pageLink {
//     z-index: 1;
// }
// #client .mainImageScreen .pageItem.disabled .pageLink {
//     pointer-events: none;
//     cursor: auto;
// }
// #client .mainImageScreen .pageItem:first-child .pageLink {
//     margin-left: 0;
//     border-top-left-radius: .25rem;
//     border-bottom-left-radius: .25rem;
// }
// #client .mainImageScreen .pageItem:last-child .pageLink {
//     border-top-right-radius: .25rem;
//     border-bottom-right-radius: .25rem;
// }
// #client .mainImageScreen .pageLink:not(:disabled):not(.disabled) {
//     cursor: pointer;
// }

// #client .mainImageScreen .pageItem .pageLink {
//     font-weight: 400;
//     padding: 0 8px;
//     margin: 0 3px;
//     min-width: 31px;
//     line-height: 29px;
//     text-align: center;
//     border-radius: 2px !important;
//     position: relative;
//     display: block;
// }
// #client .mainImageScreen .pageItem .pageLink svg,
// #client .mainImageScreen .pageItem.disabled .pageLink svg {
//     vertical-align: middle;
//     margin-bottom: 4px;
// }

// DARK
#client .clientpagelayout.dark .cardWrapper .paginationWrapper .pagination .pageItem.disabled,
#client .mainImageScreen.dark .pageItem.disabled {
    color: $colorDarkGrayPlus;
    background-color: $colorVeryDark;
    border-color: $colorDarkGrayPlus;
}

#client .clientpagelayout.dark .cardWrapper .paginationWrapper .pagination .pageItem
#client .mainImageScreen.dark .pageItem {
    color: $colorWhite;
    border: 1px solid $colorDarkGrayPlus;
}

#client .clientpagelayout.dark .cardWrapper .paginationWrapper .pagination .pageItem:hover,
#client .mainImageScreen.dark .pageItem:hover {
    border: 1px solid $colorGreen;
}
#client .clientpagelayout.dark .cardWrapper .paginationWrapper .pagination .pageItem.active,
#client .mainImageScreen.dark .pageItem.active {
    color: $colorGreen;
    border-color: $colorGreen;
}

// LIGHT
#client .clientpagelayout.light .cardWrapper .paginationWrapper .pagination .pageItem.disabled,
#client .mainImageScreen.light .pageItem.disabled {
    color: $colorLightGray !important;
    background-color: $colorWhite;
    border-color: $colorLightGray;
}

#client .clientpagelayout.light .cardWrapper .paginationWrapper .pagination .pageItem,
#client .mainImageScreen.light .pageItem {
    color: $colorVeryDark;
    border: 1px solid $colorLightGray;
}

#client .clientpagelayout.light .cardWrapper .paginationWrapper .pagination .pageItem:hover,
#client .mainImageScreen.light .pageItem:hover {
    border: 1px solid $colorBlueLogo;
}

#client .clientpagelayout.light .cardWrapper .paginationWrapper .pagination .pageItem.active,
#client .mainImageScreen.light .pageItem.active {
    color: $colorBlueLogo;
    border-color: $colorBlueLogo;
}

// FORMS
#client .mainImageScreen input[type="checkbox"],
#client .mainImageScreen input[type="radio"] {
    margin: 4px 0 0;
    padding: 0;
}

#client .mainImageScreen textarea,
#client .mainImageScreen input[type="text"],
#client .mainImageScreen input[type="password"],
#client .mainImageScreen input[type="datetime"],
#client .mainImageScreen input[type="daterange"],
#client .mainImageScreen input[type="datetime-local"],
#client .mainImageScreen input[type="date"],
#client .mainImageScreen input[type="month"],
#client .mainImageScreen input[type="time"],
#client .mainImageScreen input[type="week"],
#client .mainImageScreen input[type="number"],
#client .mainImageScreen input[type="email"],
#client .mainImageScreen input[type="url"],
#client .mainImageScreen input[type="search"],
#client .mainImageScreen input[type="tel"],
#client .mainImageScreen input[type="color"] {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    margin: 0;
    list-style: none;
    position: relative;
    display: inline-block;
    padding: 7px 13px 7px 30px;
    width: 100%;
    height: 44px;
    font-size: 15px;
    line-height: 1.5;
    background-image: none;
    border-radius: 8px;
    -webkit-transition: all .3s;
    -o-transition: all .3s;
    transition: all .3s;
}

#client .mainImageScreen.dark textarea,
#client .mainImageScreen.dark input[type="text"],
#client .mainImageScreen.dark input[type="password"],
#client .mainImageScreen.dark input[type="datetime"],
#client .mainImageScreen.dark input[type="daterange"],
#client .mainImageScreen.dark input[type="datetime-local"],
#client .mainImageScreen.dark input[type="date"],
#client .mainImageScreen.dark input[type="month"],
#client .mainImageScreen.dark input[type="time"],
#client .mainImageScreen.dark input[type="week"],
#client .mainImageScreen.dark input[type="number"],
#client .mainImageScreen.dark input[type="email"],
#client .mainImageScreen.dark input[type="url"],
#client .mainImageScreen.dark input[type="search"],
#client .mainImageScreen.dark input[type="tel"],
#client .mainImageScreen.dark input[type="color"] {
    color: $colorWhite;
    background-color: $colorVeryDarkGray;
    // border: 0;
    border: 1px solid $colorDarkGrayPlus;
}

#client .mainImageScreen.light textarea,
#client .mainImageScreen.light input[type="text"],
#client .mainImageScreen.light input[type="password"],
#client .mainImageScreen.light input[type="datetime"],
#client .mainImageScreen.light input[type="daterange"],
#client .mainImageScreen.light input[type="datetime-local"],
#client .mainImageScreen.light input[type="date"],
#client .mainImageScreen.light input[type="month"],
#client .mainImageScreen.light input[type="time"],
#client .mainImageScreen.light input[type="week"],
#client .mainImageScreen.light input[type="number"],
#client .mainImageScreen.light input[type="email"],
#client .mainImageScreen.light input[type="url"],
#client .mainImageScreen.light input[type="search"],
#client .mainImageScreen.light input[type="tel"],
#client .mainImageScreen.light input[type="color"] {
    color: $colorVeryDark;
    background-color: $colorWhite;
    // border: 0;
    border: 1px solid $colorVeryLightGray;
}

#client .mainImageScreen.dark textarea:hover,
#client .mainImageScreen.dark input[type="text"]:hover,
#client .mainImageScreen.dark input[type="password"]:hover,
#client .mainImageScreen.dark input[type="datetime"]:hover,
#client .mainImageScreen.dark input[type="daterange"]:hover,
#client .mainImageScreen.dark input[type="datetime-local"]:hover,
#client .mainImageScreen.dark input[type="date"]:hover,
#client .mainImageScreen.dark input[type="month"]:hover,
#client .mainImageScreen.dark input[type="time"]:hover,
#client .mainImageScreen.dark input[type="week"]:hover,
#client .mainImageScreen.dark input[type="number"]:hover,
#client .mainImageScreen.dark input[type="email"]:hover,
#client .mainImageScreen.dark input[type="url"]:hover,
#client .mainImageScreen.dark input[type="search"]:hover,
#client .mainImageScreen.dark input[type="tel"]:hover,
#client .mainImageScreen.dark input[type="color"]:hover {
    border-color: $colorDarkGray  !important;
}

#client .mainImageScreen.light textarea:hover,
#client .mainImageScreen.light input[type="text"]:hover,
#client .mainImageScreen.light input[type="password"]:hover,
#client .mainImageScreen.light input[type="datetime"]:hover,
#client .mainImageScreen.light input[type="daterange"]:hover,
#client .mainImageScreen.light input[type="datetime-local"]:hover,
#client .mainImageScreen.light input[type="date"]:hover,
#client .mainImageScreen.light input[type="month"]:hover,
#client .mainImageScreen.light input[type="time"]:hover,
#client .mainImageScreen.light input[type="week"]:hover,
#client .mainImageScreen.light input[type="number"]:hover,
#client .mainImageScreen.light input[type="email"]:hover,
#client .mainImageScreen.light input[type="url"]:hover,
#client .mainImageScreen.light input[type="search"]:hover,
#client .mainImageScreen.light input[type="tel"]:hover,
#client .mainImageScreen.light input[type="color"]:hover {
    border-color: $colorLightGray  !important;
}

#client .mainImageScreen.dark textarea:focus,
#client .mainImageScreen.dark input[type="text"]:focus,
#client .mainImageScreen.dark input[type="password"]:focus,
#client .mainImageScreen.dark input[type="datetime"]:focus,
#client .mainImageScreen.dark input[type="daterange"]:focus,
#client .mainImageScreen.dark input[type="datetime-local"]:focus,
#client .mainImageScreen.dark input[type="date"]:focus,
#client .mainImageScreen.dark input[type="month"]:focus,
#client .mainImageScreen.dark input[type="time"]:focus,
#client .mainImageScreen.dark input[type="week"]:focus,
#client .mainImageScreen.dark input[type="number"]:focus,
#client .mainImageScreen.dark input[type="email"]:focus,
#client .mainImageScreen.dark input[type="url"]:focus,
#client .mainImageScreen.dark input[type="search"]:focus,
#client .mainImageScreen.dark input[type="tel"]:focus,
#client .mainImageScreen.dark input[type="color"]:focus {
    border-color: $colorDarkGray  !important;
    background-color: $colorVeryDarkGray  !important;
    outline: none;
}

#client .mainImageScreen.light textarea:focus,
#client .mainImageScreen.light input[type="text"]:focus,
#client .mainImageScreen.light input[type="password"]:focus,
#client .mainImageScreen.light input[type="datetime"]:focus,
#client .mainImageScreen.light input[type="daterange"]:focus,
#client .mainImageScreen.light input[type="datetime-local"]:focus,
#client .mainImageScreen.light input[type="date"]:focus,
#client .mainImageScreen.light input[type="month"]:focus,
#client .mainImageScreen.light input[type="time"]:focus,
#client .mainImageScreen.light input[type="week"]:focus,
#client .mainImageScreen.light input[type="number"]:focus,
#client .mainImageScreen.light input[type="email"]:focus,
#client .mainImageScreen.light input[type="url"]:focus,
#client .mainImageScreen.light input[type="search"]:focus,
#client .mainImageScreen.light input[type="tel"]:focus,
#client .mainImageScreen.light input[type="color"]:focus {
    border-color: $colorLightGray  !important;
    background-color: $colorWhite  !important;
    outline: none;
}

#client .mainImageScreen.dark textarea:disabled,
#client .mainImageScreen.dark input[type="text"]:disabled,
#client .mainImageScreen.dark input[type="password"]:disabled,
#client .mainImageScreen.dark input[type="datetime"]:disabled,
#client .mainImageScreen.dark input[type="daterange"]:disabled,
#client .mainImageScreen.dark input[type="datetime-local"]:disabled,
#client .mainImageScreen.dark input[type="date"]:disabled,
#client .mainImageScreen.dark input[type="month"]:disabled,
#client .mainImageScreen.dark input[type="time"]:disabled,
#client .mainImageScreen.dark input[type="week"]:disabled,
#client .mainImageScreen.dark input[type="number"]:disabled,
#client .mainImageScreen.dark input[type="email"]:disabled,
#client .mainImageScreen.dark input[type="url"]:disabled,
#client .mainImageScreen.dark input[type="search"]:disabled,
#client .mainImageScreen.dark input[type="tel"]:disabled,
#client .mainImageScreen.dark input[type="color"]:disabled {
    pointer-events: none;
    color: $colorGray  !important;
}

#client .mainImageScreen.light textarea:disabled,
#client .mainImageScreen.light input[type="text"]:disabled,
#client .mainImageScreen.light input[type="password"]:disabled,
#client .mainImageScreen.light input[type="datetime"]:disabled,
#client .mainImageScreen.light input[type="daterange"]:disabled,
#client .mainImageScreen.light input[type="datetime-local"]:disabled,
#client .mainImageScreen.light input[type="date"]:disabled,
#client .mainImageScreen.light input[type="month"]:disabled,
#client .mainImageScreen.light input[type="time"]:disabled,
#client .mainImageScreen.light input[type="week"]:disabled,
#client .mainImageScreen.light input[type="number"]:disabled,
#client .mainImageScreen.light input[type="email"]:disabled,
#client .mainImageScreen.light input[type="url"]:disabled,
#client .mainImageScreen.light input[type="search"]:disabled,
#client .mainImageScreen.light input[type="tel"]:disabled,
#client .mainImageScreen.light input[type="color"]:disabled {
    pointer-events: none;
    color: $colorDarkGrayPlus  !important;
}

@media (max-width: 767.98px) {
    #client .clientpagelayout.light .cardWrapper .paginationWrapper .pagination .pageItem,
    #client .mainImageScreen .pageItem {
        height: 35px;
        width: 35px;
    }
}