@import './colors.scss';
@import './fonts.scss';

#lumisoft-maxi .addressBlock {
    height: 600px;
    background: url(./images/googlemap.png) no-repeat center;
    background-size: auto;
    background-size: contain;
    position: relative;

}

#lumisoft-maxi.light .addressBlock {
    box-shadow: inset -10px -10px 10px 20px $colorWhite;
}

#lumisoft-maxi.dark .addressBlock {
    box-shadow: inset -10px -10px 10px 20px $colorVeryDark;
}

#lumisoft-maxi .addressBlock .dot {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    height: 15px;
    width: 15px;
    border-radius: 50%;
    cursor: pointer;
    aspect-ratio: 1;
    background: #000;
    box-shadow: 0 0 0 0 #0004;
    animation: l1 1s infinite;
}

@keyframes l1 {
    100% {
        box-shadow: 0 0 0 30px #0000
    }
}

#lumisoft-maxi.light .addressBlock .dot {
    background: $colorBlue;
}

#lumisoft-maxi.dark .addressBlock .dot {
    background: $colorGreen;
}

#lumisoft-maxi .addressBlock .dot::before,
#lumisoft-maxi .addressBlock .dot::after {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    content: "";
    background: #1e85ff;
    border-radius: 50%;
    -webkit-animation: wave 2s linear infinite;
    animation: wave 2s linear infinite;
}

#lumisoft-maxi.light .addressBlock .dot::before,
#lumisoft-maxi.light .addressBlock .dot::after {
    background: $colorBlue;
}

#lumisoft-maxi.dark .addressBlock .dot::before,
#lumisoft-maxi.dark .addressBlock .dot::after {
    background: $colorGreen;
}

#lumisoft-maxi .addressBlock .dot::after {
    -webkit-animation-delay: 1s;
    animation-delay: 1s;
}

#lumisoft-maxi .addressBlock .addressWrap {
    min-width: 204px;
    position: absolute;
    -webkit-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
    bottom: calc(50% + 10px);
    left: 50%;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
    border-radius: 10px;
    padding: 15px 25px;
    -webkit-box-shadow: 0px 15px 20px 0px rgba(0, 0, 0, 0.05);
    box-shadow: 0px 15px 20px 0px rgba(0, 0, 0, 0.05);
    text-align: center;
}

#lumisoft-maxi.light .addressBlock .addressWrap {
    background: $colorWhite;
}

#lumisoft-maxi.dark .addressBlock .addressWrap {
    background: $colorVeryDark;
}


#lumisoft-maxi .addressBlock .addressWrap::before {
    position: absolute;
    content: "";
    top: 100%;
    left: 50%;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
}

#lumisoft-maxi.light .addressBlock .addressWrap::before {
    border-top: 10px solid $colorWhite;
}

#lumisoft-maxi.dark .addressBlock .addressWrap::before {
    border-top: 10px solid $colorVeryDark;
}

#lumisoft-maxi .addressBlock .addressWrap img {
    height: 25px;
}

#lumisoft-maxi .addressBlock .addressWrap p {
    margin-top: 10px;
    font-size: 1rem;
    font-weight: 400;
    font-family: "Roboto", sans-serif;
    line-height: 1.5;
    margin-bottom: 5px;
}

#lumisoft-maxi.light .addressBlock .addressWrap p {
    color: $colorDark;
}

#lumisoft-maxi.dark .addressBlock .addressWrap p {
    color: $colorWhite;
}

#lumisoft-maxi .addressBlock .addressWrap a {
    font-size: 1.3rem;
    font-weight: 400;
    font-family: "Roboto", sans-serif;
}

#lumisoft-maxi.light .addressBlock .addressWrap a {
    color: $colorBlue;
}

#lumisoft-maxi.dark .addressBlock .addressWrap a {
    color: $colorGreen;
}