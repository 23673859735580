@import '../colors.scss';
@import '../fonts.scss';

#client .clientpagelayout.light .clientPageWrapper .insidecontentWrapper .mainBodyWrapper .cardWrapper {
    border: 1px solid $colorVeryLightGray;
    background-color: $colorWhite;
}

#client .clientpagelayout.dark .clientPageWrapper .insidecontentWrapper .mainBodyWrapper .cardWrapper {
    border: 1px solid $colorDarkGrayPlus;
    background-color: $colorVeryDark;
}

#client .clientpagelayout .clientPageWrapper .insidecontentWrapper .mainBodyWrapper {
    padding: 30px;
}

#client .clientpagelayout .clientPageWrapper .insidecontentWrapper .mainBodyWrapper .cardWrapper {
    margin-bottom: 30px;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
    letter-spacing: 0.5px;
    border-radius: 10px;
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-clip: border-box;
}

// CARD HEADER

#client .clientpagelayout.light .clientPageWrapper .insidecontentWrapper .mainBodyWrapper .cardWrapper .cardHeader {
    background-color: $colorWhite;
}

#client .clientpagelayout.dark .clientPageWrapper .insidecontentWrapper .mainBodyWrapper .cardWrapper .cardHeader {
    background-color: $colorVeryDark;
}

#client .clientpagelayout .clientPageWrapper .insidecontentWrapper .mainBodyWrapper .cardWrapper .cardHeader {
    padding: 30px 30px 0 30px;
    border-bottom: none;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

#client .clientpagelayout .clientPageWrapper .insidecontentWrapper .mainBodyWrapper .cardWrapper .cardHeader:first-child {
    border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    // border-top-left-radius: calc(-1px + 0.25rem);
    // border-top-right-radius: calc(-1px + 0.25rem);
}

#client .clientpagelayout.light .clientPageWrapper .insidecontentWrapper .mainBodyWrapper .cardWrapper .cardHeader h5 {
    color: $colorVeryDark;
}

#client .clientpagelayout.dark .clientPageWrapper .insidecontentWrapper .mainBodyWrapper .cardWrapper .cardHeader h5 {
    color: $colorWhite;
}

#client .clientpagelayout .clientPageWrapper .insidecontentWrapper .mainBodyWrapper .cardWrapper .cardHeader h5 {
    margin-bottom: 0;
    text-transform: capitalize;
    font-weight: 600;
    line-height: 1.3;
    font-size: 22px;
    text-transform: uppercase;
    height: 44px;
}

// #client .clientpagelayout .clientPageWrapper .insidecontentWrapper .mainBodyWrapper .cardWrapper .cardBody .cardOptions {
//     padding-bottom: 20px;
// }

// #client .clientpagelayout .clientPageWrapper .insidecontentWrapper .mainBodyWrapper .cardWrapper .cardBody .cardToadd {
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     cursor: pointer;
// }

// #client .clientpagelayout .clientPageWrapper .insidecontentWrapper .mainBodyWrapper .cardWrapper .cardBody .cardToadd svg {
//     font-size: 50px;
// }

// #client .clientpagelayout .clientPageWrapper .insidecontentWrapper .mainBodyWrapper .cardWrapper .cardBody .cardToadd,
// #client .clientpagelayout .clientPageWrapper .insidecontentWrapper .mainBodyWrapper .cardWrapper .cardBody .cardOptions.form {
//     padding: 20px;
// }

// #client .clientpagelayout.light .clientPageWrapper .insidecontentWrapper .mainBodyWrapper .cardWrapper .cardBody .cardToadd,
// #client .clientpagelayout.light .clientPageWrapper .insidecontentWrapper .mainBodyWrapper .cardWrapper .cardBody .cardOptions.form {
//     padding: 20px;
//     background-color: $colorWhite4;
//     border: 1px solid $colorVeryLightGray;
// }

// #client .clientpagelayout.dark .clientPageWrapper .insidecontentWrapper .mainBodyWrapper .cardWrapper .cardBody .cardToadd,
// #client .clientpagelayout.dark .clientPageWrapper .insidecontentWrapper .mainBodyWrapper .cardWrapper .cardBody .cardOptions.form {
//     padding: 20px;
//     background-color: $colorDarkGrayPlus;
//     border: 1px solid $colorDarkGrayPlus;
// }


#client .clientpagelayout .clientPageWrapper .insidecontentWrapper .mainBodyWrapper .cardWrapper .cardHeader .cardOptions .inputSearch,
#client .clientpagelayout .clientPageWrapper .insidecontentWrapper .mainBodyWrapper .cardWrapper .cardBody .cardOptions .inputSearch {
    margin-right: 20px;
}

#client .clientpagelayout .clientPageWrapper .insidecontentWrapper .mainBodyWrapper .cardWrapper .cardBody .cardOptions .inputSearch:last-child {
    margin-right: 0;
}

#client .clientpagelayout .clientPageWrapper .insidecontentWrapper .mainBodyWrapper .cardWrapper .cardHeader .cardOptions ul,
#client .clientpagelayout .clientPageWrapper .insidecontentWrapper .mainBodyWrapper .cardWrapper .cardBody .cardOptions ul {
    display: flex;
    flex-direction: row;
}

#client .clientpagelayout .clientPageWrapper .insidecontentWrapper .mainBodyWrapper .cardWrapper .cardHeader .cardOptions li.listgroupitem,
#client .clientpagelayout .clientPageWrapper .insidecontentWrapper .mainBodyWrapper .cardWrapper .cardBody .cardOptions li.listgroupitem {
    border-radius: 0 !important;
}

#client .clientpagelayout .clientPageWrapper .insidecontentWrapper .mainBodyWrapper .cardWrapper .cardHeader .cardOptions li.textbutton,
#client .clientpagelayout .clientPageWrapper .insidecontentWrapper .mainBodyWrapper .cardWrapper .cardBody .cardOptions li.textbutton {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 10px;
    padding-right: 10px;
    height: 42px;
}

#client .clientpagelayout .clientPageWrapper .insidecontentWrapper .mainBodyWrapper .cardWrapper .cardHeader .cardOptions li.iconRefresh,
#client .clientpagelayout .clientPageWrapper .insidecontentWrapper .mainBodyWrapper .cardWrapper .cardBody .cardOptions li.iconRefresh,
#client .clientpagelayout .clientPageWrapper .insidecontentWrapper .mainBodyWrapper .cardWrapper .floatingBody .cardOptions li.iconRefresh {
    margin-right: 20px;
}

#client .clientpagelayout .clientPageWrapper .insidecontentWrapper .mainBodyWrapper .cardWrapper .cardHeader .cardOptions li:last-child.iconRefresh,
#client .clientpagelayout .clientPageWrapper .insidecontentWrapper .mainBodyWrapper .cardWrapper .cardBody .cardOptions li:last-child.iconRefresh,
#client .clientpagelayout .clientPageWrapper .insidecontentWrapper .mainBodyWrapper .cardWrapper .floatingBody .cardOptions li:last-child.iconRefresh {
    margin-right: 0;
}

#client .clientpagelayout.light .clientPageWrapper .insidecontentWrapper .mainBodyWrapper .cardWrapper .cardHeader .cardOptions li.iconRefresh,
#client .clientpagelayout.light .clientPageWrapper .insidecontentWrapper .mainBodyWrapper .cardWrapper .cardBody .cardOptions li.iconRefresh,
#client .clientpagelayout.light .clientPageWrapper .insidecontentWrapper .mainBodyWrapper .cardWrapper .floatingBody .cardOptions li.iconRefresh {
    color: $colorVeryDark;
    background-color: $colorWhiteBackground;
    border: 1px solid $colorVeryLightGray;
}

#client .clientpagelayout .clientPageWrapper .insidecontentWrapper .mainBodyWrapper .cardWrapper .cardBody .cardOptions li#loadingSubmit {
    width: 42px;
    height: 42px;
}

#client .clientpagelayout .clientPageWrapper .insidecontentWrapper .mainBodyWrapper .cardWrapper .floatingBody .cardOptions li#fullSubmit,
#client .clientpagelayout .clientPageWrapper .insidecontentWrapper .mainBodyWrapper .cardWrapper .cardBody .cardOptions li#fullSubmit {
    width: 100%;
    height: 42px;
}

#client .clientpagelayout .clientPageWrapper .insidecontentWrapper .mainBodyWrapper .cardWrapper .floatingBody .cardOptions li#fullSubmit .center,
#client .clientpagelayout .clientPageWrapper .insidecontentWrapper .mainBodyWrapper .cardWrapper .cardBody .cardOptions li#fullSubmit .center {
    display: flex;
    justify-content: center;
}

#client .clientpagelayout .clientPageWrapper .insidecontentWrapper .mainBodyWrapper .cardWrapper .floatingBody .cardOptions li#fullSubmit .formSubmit span,
#client .clientpagelayout .clientPageWrapper .insidecontentWrapper .mainBodyWrapper .cardWrapper .cardBody .cardOptions li#fullSubmit .formSubmit span {
    display: flex;
    justify-content: center;
    align-items: center;
}

#client .clientpagelayout.dark .clientPageWrapper .insidecontentWrapper .mainBodyWrapper .cardWrapper .cardHeader .cardOptions li.iconRefresh,
#client .clientpagelayout.dark .clientPageWrapper .insidecontentWrapper .mainBodyWrapper .cardWrapper .cardBody .cardOptions li.iconRefresh,
#client .clientpagelayout.dark .clientPageWrapper .insidecontentWrapper .mainBodyWrapper .cardWrapper .floatingBody .cardOptions li.iconRefresh {
    color: $colorWhite;
    background-color: $colorVeryDarkGray;
    border: 1px solid $colorDarkGrayPlus;
}

#client .clientpagelayout.light .clientPageWrapper .insidecontentWrapper .mainBodyWrapper .cardWrapper .cardHeader .cardOptions li.iconRefresh:hover,
#client .clientpagelayout.light .clientPageWrapper .insidecontentWrapper .mainBodyWrapper .cardWrapper .cardBody .cardOptions li.iconRefresh:hover,
#client .clientpagelayout.light .clientPageWrapper .insidecontentWrapper .mainBodyWrapper .cardWrapper .floatingBody .cardOptions li.iconRefresh:hover {
    border: 1px solid $colorBlueLogo;
}

#client .clientpagelayout.dark .clientPageWrapper .insidecontentWrapper .mainBodyWrapper .cardWrapper .cardHeader .cardOptions li.iconRefresh:hover,
#client .clientpagelayout.dark .clientPageWrapper .insidecontentWrapper .mainBodyWrapper .cardWrapper .cardBody .cardOptions li.iconRefresh:hover,
#client .clientpagelayout.dark .clientPageWrapper .insidecontentWrapper .mainBodyWrapper .cardWrapper .floatingBody .cardOptions li.iconRefresh:hover {
    border: 1px solid $colorGreen;
}

#client .clientpagelayout .clientPageWrapper .insidecontentWrapper .mainBodyWrapper .cardWrapper .cardHeader .cardOptions li,
#client .clientpagelayout .clientPageWrapper .insidecontentWrapper .mainBodyWrapper .cardWrapper .cardBody .cardOptions li,
#client .clientpagelayout .clientPageWrapper .insidecontentWrapper .mainBodyWrapper .cardWrapper .floatingBody .cardOptions li {
    cursor: pointer;
    border-radius: 8px;
}

#client .clientpagelayout .clientPageWrapper .insidecontentWrapper .mainBodyWrapper .cardWrapper .cardHeader .cardOptions li span svg,
#client .clientpagelayout .clientPageWrapper .insidecontentWrapper .mainBodyWrapper .cardWrapper .cardBody .cardOptions li span svg,
#client .clientpagelayout .clientPageWrapper .insidecontentWrapper .mainBodyWrapper .cardWrapper .floatingBody .cardOptions li span svg {
    vertical-align: middle;
    height: 18px;
    width: 18px;
    margin: 12px;
}

// CARD BODY

#client .clientpagelayout .clientPageWrapper .insidecontentWrapper .mainBodyWrapper .cardWrapper .cardBody {
    padding: 30px;
    background-color: transparent;
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
}

#client .clientpagelayout .clientPageWrapper .insidecontentWrapper .mainBodyWrapper .cardWrapper .cardBody .tableWrapper {
    overflow-x: auto;
    width: 100%;
}

#client .clientpagelayout .clientPageWrapper .insidecontentWrapper .mainBodyWrapper .cardWrapper .cardBody .tableWrapper .table {
    margin-bottom: 0px;
    width: 100%;
}

#client .clientpagelayout .clientPageWrapper .insidecontentWrapper .mainBodyWrapper .cardWrapper .cardBody .tableWrapper table {
    caption-side: bottom;
    border-collapse: collapse;
}

#client .clientpagelayout .clientPageWrapper .insidecontentWrapper .mainBodyWrapper .cardWrapper .cardBody .tableWrapper .table>thead {
    vertical-align: bottom;
}

#client .clientpagelayout.light thead,
#client .clientpagelayout.light tbody,
#client .clientpagelayout.light tfoot,
#client .clientpagelayout.light tr,
#client .clientpagelayout.light td,
#client .clientpagelayout.light th {
    border-color: $colorVeryLightGray;
}

#client .clientpagelayout.dark thead,
#client .clientpagelayout.dark tbody,
#client .clientpagelayout.dark tfoot,
#client .clientpagelayout.dark tr,
#client .clientpagelayout.dark td,
#client .clientpagelayout.dark th {
    border-color: $colorDarkGrayPlus;
}

#client .clientpagelayout thead,
#client .clientpagelayout tbody,
#client .clientpagelayout tfoot,
#client .clientpagelayout tr,
#client .clientpagelayout td,
#client .clientpagelayout th {
    border-color: inherit;
    border-style: solid;
    border-width: 0;
}

#client .clientpagelayout.light .clientPageWrapper .insidecontentWrapper .mainBodyWrapper .cardWrapper .cardBody .tableWrapper .table th {
    border-bottom: 1px solid $colorVeryLightGray;
    color: $colorVeryDark;
}

#client .clientpagelayout.dark .clientPageWrapper .insidecontentWrapper .mainBodyWrapper .cardWrapper .cardBody .tableWrapper .table th {
    border-bottom: 1px solid $colorDarkGrayPlus;
    color: $colorWhite;
}

#client .clientpagelayout .clientPageWrapper .insidecontentWrapper .mainBodyWrapper .cardWrapper .cardBody .tableWrapper .table th,
#client .clientpagelayout .clientPageWrapper .insidecontentWrapper .mainBodyWrapper .cardWrapper .cardBody .tableWrapper .table td {
    padding: 0.75rem;
}

#client .clientpagelayout .clientPageWrapper .insidecontentWrapper .mainBodyWrapper .cardWrapper .cardBody .tableWrapper .table thead tr th {
    border-top: 0;
    font-size: 16px;
    font-weight: 600;
    padding-top: 0;
    text-align: inherit;
}

#client .clientpagelayout .clientPageWrapper .insidecontentWrapper .mainBodyWrapper .cardWrapper .cardBody .tableWrapper .table tbody {
    vertical-align: inherit;
}

#client .clientpagelayout .clientPageWrapper .insidecontentWrapper .mainBodyWrapper .cardWrapper .cardBody .tableWrapper .tablebordernone .u-s-tb {
    padding: 11px;
}

#client .clientpagelayout .clientPageWrapper .insidecontentWrapper .mainBodyWrapper .cardWrapper .cardBody .tableWrapper .tablebordernone .bd-t-none {
    border-top: none;
}

#client .clientpagelayout .clientPageWrapper .insidecontentWrapper .mainBodyWrapper .cardWrapper .cardBody .tableWrapper .table tbody tr {
    cursor: pointer;
}

#client .clientpagelayout.light .clientPageWrapper .insidecontentWrapper .mainBodyWrapper .cardWrapper .cardBody .tableWrapper .table tbody tr:nth-child(even) {
    background-color: $colorWhiteBackground;
}

#client .clientpagelayout.dark .clientPageWrapper .insidecontentWrapper .mainBodyWrapper .cardWrapper .cardBody .tableWrapper .table tbody tr:nth-child(even) {
    background-color: $colorVeryDarkGray;
}

#client .clientpagelayout.light .clientPageWrapper .insidecontentWrapper .mainBodyWrapper .cardWrapper .cardBody .tableWrapper .table tbody tr:hover td {
    color: $colorBlueLogo;
}

#client .clientpagelayout.dark .clientPageWrapper .insidecontentWrapper .mainBodyWrapper .cardWrapper .cardBody .tableWrapper .table tbody tr:hover td {
    color: $colorGreen;
}

#client .clientpagelayout.light .clientPageWrapper .insidecontentWrapper .mainBodyWrapper .cardWrapper .cardBody .tableWrapper .table tbody tr td {
    color: $colorVeryDark;
}

#client .clientpagelayout.dark .clientPageWrapper .insidecontentWrapper .mainBodyWrapper .cardWrapper .cardBody .tableWrapper .table tbody tr td {
    color: $colorWhite;
}

#client .clientpagelayout .clientPageWrapper .insidecontentWrapper .mainBodyWrapper .cardWrapper .cardBody .tableWrapper .table tbody tr td {
    vertical-align: middle;
    font-weight: 300;
    border: 0;
}

#client .clientpagelayout.dark .formGroup .formWrapper .errorLabel {
    color: $colorRed;
    font-weight: 500;
}

#client .clientpagelayout.light .formGroup .formWrapper .errorLabel {
    color: $colorRed;
    font-weight: 500;
}

#client .clientpagelayout .formGroup .formWrapper .errorLabel {
    position: absolute;
    font-size: 12px;
    font-family: $montserrat;
    margin: 8px 3px 3px 2px;
}

#client .clientpagelayout .formGroup .formWrapper .customicon {
    position: absolute;
    left: auto;
    top: 50%;
    right: 12px;
    text-indent: 32px;
    cursor: pointer;
    transform: translateY(-50%);
}

#client .clientpagelayout.dark .formGroup .formWrapper .customicon {
    color: $colorWhite;
}

#client .clientpagelayout.light .formGroup .formWrapper .customicon {
    color: $colorVeryDarkGray;
}

@media (max-width: 767.98px) {

    // #client .clientpagelayout .clientPageWrapper .insidecontentWrapper .mainBodyWrapper .cardWrapper .cardBody .cardToadd {
    //     margin-top: 10px;
    // }

    #client .clientpagelayout .clientPageWrapper .insidecontentWrapper .mainBodyWrapper .cardWrapper .cardHeader .cardOptions ul.textbuttonWrapper {
        flex-direction: row !important;
    }

    #client .clientpagelayout .clientPageWrapper .insidecontentWrapper .mainBodyWrapper .cardWrapper .cardHeader .cardOptions,
    #client .clientpagelayout .clientPageWrapper .insidecontentWrapper .mainBodyWrapper .cardWrapper .cardBody .cardOptions {
        width: 100%;
    }

    #client .clientpagelayout .clientPageWrapper .insidecontentWrapper .mainBodyWrapper .cardWrapper .cardHeader .cardOptions ul,
    #client .clientpagelayout .clientPageWrapper .insidecontentWrapper .mainBodyWrapper .cardWrapper .cardBody .cardOptions ul {
        flex-direction: column !important;
    }

    #client .clientpagelayout .clientPageWrapper .insidecontentWrapper .mainBodyWrapper .cardWrapper .cardHeader .cardOptions ul li.iconRefresh,
    #client .clientpagelayout .clientPageWrapper .insidecontentWrapper .mainBodyWrapper .cardWrapper .cardBody .cardOptions ul li.iconRefresh {
        display: flex;
        justify-content: center;
    }

    #client .clientpagelayout .clientPageWrapper .insidecontentWrapper .mainBodyWrapper .cardWrapper .cardHeader .cardOptions .inputSearch,
    #client .clientpagelayout .clientPageWrapper .insidecontentWrapper .mainBodyWrapper .cardWrapper .cardBody .cardOptions .inputSearch {
        margin-right: 0 !important;
        margin-bottom: 20px;
    }

    #client .clientpagelayout .clientPageWrapper .insidecontentWrapper .mainBodyWrapper .cardWrapper .cardHeader h5 {
        display: block;
        width: 100%;
        font-size: 15px;
    }

    #client .clientpagelayout .clientPageWrapper .insidecontentWrapper .mainBodyWrapper .cardWrapper .cardBody .cardOptions ul {
        flex-direction: column;
    }

    // #client .clientpagelayout .clientPageWrapper .insidecontentWrapper .mainBodyWrapper .cardWrapper .cardBody .cardOptions ul li.inputSearch {
    //     padding: 20px 0;
    // }

    #client .clientpagelayout .clientPageWrapper .insidecontentWrapper .mainBodyWrapper .cardWrapper .cardBody .cardOptions ul #loadingSubmit {
        width: 42px;
        height: 42px;
        margin-top: 20px;
    }

    #client .clientpagelayout .clientPageWrapper {
        padding: 0 30px;
    }

    #client .clientpagelayout .clientPageWrapper .insidecontentWrapper .mainBodyWrapper {
        padding: 0;
    }

    #client .clientpagelayout .clientPageWrapper .insidecontentWrapper .mainBodyWrapper .cardWrapper .cardHeader,
    #client .clientpagelayout .clientPageWrapper .insidecontentWrapper .mainBodyWrapper .cardWrapper .cardBody {
        padding: 20px;
    }

    #client .clientpagelayout .clientPageWrapper .insidecontentWrapper .mainBodyWrapper .cardWrapper .cardHeader {
        display: flex;
        flex-direction: column;
    }

    #client .clientpagelayout .clientPageWrapper .insidecontentWrapper .mainBodyWrapper .cardWrapper .cardHeader h5 {
        margin-bottom: 20px;
    }
}

@media (min-width: 1280px) {
    #client .clientpagelayout .clientPageWrapper .insidecontentWrapper .mainBodyWrapper .cardWrapper .cardBody .cardToadd {
        margin-left: 10px;
    }
}