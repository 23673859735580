#client .clientpagelayout .clientPageWrapper .insidecontentWrapper .mainBodyWrapper .cardWrapper .cardBody .cardMedia {
    width: 100%;
    padding: 20px 0;
}

#client .clientpagelayout .clientPageWrapper .insidecontentWrapper .mainBodyWrapper .cardWrapper .cardBody .cardMedia .mediaList .lg-react-element {
    column-count: 6;
    column-gap: 10px;
}

#client .clientpagelayout .clientPageWrapper .insidecontentWrapper .mainBodyWrapper .cardWrapper .cardBody .cardMedia .mediaList .lg-react-element img {
    max-width: 100%;
    display: block;
    padding: 5px 0px;
    border-radius: 20px;
    transition: transform 0.2s;
}

#client .clientpagelayout .clientPageWrapper .insidecontentWrapper .mainBodyWrapper .cardWrapper .cardBody .cardMedia .mediaList .lg-react-element img:hover {
    // filter: opacity(.9);
    transform: scale(1.01);
}

#client .clientpagelayout .clientPageWrapper .insidecontentWrapper .mainBodyWrapper .galleryScreen {
    position: fixed;
    top: 50%;
    left: 50%;
    right: 0;
    width: 100%; // kalau ini ga 100%, mobile phone ga full width
    height: 101%;
    z-index: 2200;
    display: flex;
    justify-content: center;
    align-self: center;
    pointer-events: all;
    visibility: hidden;
    opacity: 0;
    transition: transform 0.5s ease, opacity 0.3s ease;
    transform: translate(-50%, -50%);
    will-change: transform;
}

#client .clientpagelayout .clientPageWrapper .insidecontentWrapper .mainBodyWrapper .galleryScreen.open {
    visibility: visible;
    opacity: 1;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}


#client .clientpagelayout .galleryScreen .btnClose {
    position: fixed;
    top: 40px;
    right: 40px;
    z-index: 2200;
    color: $colorVeryLightGray;
    font-size: 40px;
    cursor: pointer;
}

#client .clientpagelayout .galleryScreen .btnClose:hover {
    color: $colorWhite;
}

#client .clientpagelayout .galleryScreen .btnPrev {
    position: fixed;
    top: 50%;
    left: 40px;
    transform: translateY(-50%);
    z-index: 2200;
    color: $colorVeryLightGray;
    font-size: 40px;
    cursor: pointer;
}

#client .clientpagelayout .galleryScreen .btnPrev:hover {
    color: $colorWhite;
}

#client .clientpagelayout .galleryScreen .btnNext {
    position: fixed;
    top: 50%;
    right: 40px;
    transform: translateY(-50%);
    z-index: 2200;
    color: $colorVeryLightGray;
    font-size: 40px;
    cursor: pointer;
}

#client .clientpagelayout .galleryScreen .btnNext:hover {
    color: $colorWhite;
}

#client .clientpagelayout .galleryScreen .galleryInfo {
    position: fixed;
    bottom: 40px;
    z-index: 2200;
    color: $colorWhite;
}

#client .clientpagelayout .galleryScreen .gallerybackdrop {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.9);
    z-index: 2100;
    cursor: pointer;
}

#client .clientpagelayout .galleryScreen .fullscreenWrapper {
    max-width: 500px;
    max-height: 700px;
    display: flex;
    justify-content: center;
    align-self: center;
    z-index: 2200;
}

#client .clientpagelayout .galleryScreen .fullscreenWrapper .fullscreenImage {
    display: flex;
    justify-content: center;
    align-self: center;
}

#client .clientpagelayout .galleryScreen .fullscreenWrapper .fullscreenImage img {
    width: 100%;
    max-width: 100%;
    object-fit: cover;
    pointer-events: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

@media (max-width: 767.98px) {
    #client .clientpagelayout .galleryScreen .btnClose {
        top: 10px;
        right: 10px;
    }
    #client .clientpagelayout .galleryScreen .btnPrev {
        top: unset;
        bottom: 0;
        right: 50px;
    }
    #client .clientpagelayout .galleryScreen .btnNext {
        top: unset;
        bottom: 0;
        right: 50px;
    }
}