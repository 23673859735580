@import '../fonts.scss';
@import '../colors.scss';

#root #client header {
    position: relative;
    min-height: 100%;
}

#root #client header.light {
    background-color: $colorWhiteBackground;
}

#root #client header.dark {
    background-color: $colorVeryDarkGray;
}

#root #client header .navbar {
    z-index: 930;
    margin-bottom: 0;
    // height: 56px;
    border-radius: 0;
    border-top: 0;
    border-left: 0;
    border-right: 0;
    box-shadow: none;
}

#root #client header .navbar .headerWrapper {
    display: flex;
    justify-content: space-between;
    margin: 10px 30px;
}

#root #client header .navbar .headerWrapper .logoWrapper {
    border-bottom: 0;
    float: left;
    margin-right: 0;
    overflow: hidden;
    position: relative;
    width: 245px;
}

#root #client header .navbar .headerWrapper .logoWrapper .logoImageWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
}

#root #client header .navbar .headerWrapper .logoWrapper .logoImageWrapper span {
    font-family: 'Breul Grotesk';
    font-weight: 700;
    font-size: 24px;
    margin-left: 10px;
}

#root #client header.light .navbar .headerWrapper .logoWrapper .logoImageWrapper span,
#root #client header.light .navbar .headerWrapper .logoWrapper small {
    color: $colorDarkGrayPlus;
}

#root #client header.dark .navbar .headerWrapper .logoWrapper .logoImageWrapper span,
#root #client header.dark .navbar .headerWrapper .logoWrapper small {
    color: $colorWhite;
}

#root #client header .navbar .headerWrapper .logoWrapper small {
    font-size: 11px;
    display: flex;
    margin-top: 3px;
    margin-left: 18px;
}

//header right
#root #client header .navbar .headerWrapper .rightWrapper {
    display: flex;
    flex-direction: row;
    margin-right: 20px;
}

#root #client header .navbar .headerWrapper .rightWrapper .optionsWrapper {
    display: flex;
    flex-direction: row;
}

#root #client header .navbar .headerWrapper .rightWrapper .optionsWrapper span,
#root #client header .navbar .headerWrapper .rightWrapper .profileWrapper span {
    margin-right: 20px;
}

#root #client header .navbar .headerWrapper .rightWrapper .profileWrapper span:last-child {
    margin-right: 0;
}

#root #client header.light .navbar .headerWrapper .rightWrapper .optionsWrapper span,
#root #client header.light .navbar .headerWrapper .rightWrapper .profileWrapper span {
    color: $colorVeryDarkGray;
    background-color: $colorWhite;
    border: 1px solid $colorVeryLightGray;
}

#root #client header.dark .navbar .headerWrapper .rightWrapper .optionsWrapper span,
#root #client header.dark .navbar .headerWrapper .rightWrapper .profileWrapper span {
    color: $colorWhite;
    background-color: $colorVeryDark;
    border: 1px solid $colorDarkGrayPlus;
}

#root #client header.light .navbar .headerWrapper .rightWrapper .optionsWrapper span:hover,
#root #client header.light .navbar .headerWrapper .rightWrapper .profileWrapper span:hover {
    color: $colorVeryDarkGray;
    background-color: $colorWhite;
    border: 1px solid $colorBlueLogo;
}

#root #client header.dark .navbar .headerWrapper .rightWrapper .optionsWrapper span:hover,
#root #client header.dark .navbar .headerWrapper .rightWrapper .profileWrapper span:hover {
    color: $colorWhite;
    background-color: $colorVeryDark;
    border: 1px solid $colorGreen;
}

#root #client header.light .navbar .headerWrapper .rightWrapper .optionsWrapper span.active,
#root #client header.light .navbar .headerWrapper .rightWrapper .profileWrapper span.active {
    color: $colorBlueLogo;
    border: 1px solid $colorBlueLogo;
}

#root #client header.dark .navbar .headerWrapper .rightWrapper .optionsWrapper span.active,
#root #client header.dark .navbar .headerWrapper .rightWrapper .profileWrapper span.active {
    color: $colorGreen;
    border: 1px solid $colorGreen;
}

#root #client header .navbar .headerWrapper .rightWrapper .optionsWrapper,
#root #client header .navbar .headerWrapper .rightWrapper .profileWrapper {
    display: flex;
    align-items: center;
}

#root #client header .navbar .headerWrapper .rightWrapper .optionsWrapper span,
#root #client header .navbar .headerWrapper .rightWrapper .profileWrapper span {
    // padding: 8px 10px 3px 10px;
    cursor: pointer;
    border-radius: 8px;
}

#root #client header .navbar .headerWrapper .rightWrapper .optionsWrapper svg,
#root #client header .navbar .headerWrapper .rightWrapper .profileWrapper svg {
    vertical-align: middle;
    height: 18px;
    width: 18px;
    margin: 12px;
}

#root #client header .navbar .headerWrapper .sideClientWrapper {
    display: none;
}

@media (max-width: 767.98px) {
    #root #client header .navbar .headerWrapper {
        display: block;
        padding: 10px 0;
    }

    #root #client header .navbar .headerWrapper .logoWrapper small {
        display: none;
    }

    #root #client header .navbar .headerWrapper .logoWrapper {
        width: 40px;
        justify-content: flex-start;
    }

    #root #client header .navbar .headerWrapper .rightWrapper {
        justify-content: flex-end;
        margin-right: 0;
    }

    #root #client header .navbar .headerWrapper .rightWrapper .optionsWrapper span,
    #root #client header .navbar .headerWrapper .rightWrapper .profileWrapper span {
        margin-right: 10px;
    }

    #root #client header .navbar .headerWrapper .sideClientWrapper {
        border-bottom: 0;
        float: left;
        margin-right: 0;
        margin-left: 10px;
        overflow: hidden;
        position: relative;
        width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    #root #client header .navbar .headerWrapper .sideClientWrapper .sideClientButton {
        cursor: pointer;
    }

    #root #client header .navbar .headerWrapper .sideClientWrapper .sideClientButton span {
        display: flex;
        align-items: center;
        font-weight: 700;
        font-size: 24px;
    }

    #root #client header.light .navbar .headerWrapper .sideClientWrapper .sideClientButton span {
        color: $colorDarkGrayPlus;
    }

    #root #client header.dark .navbar .headerWrapper .sideClientWrapper .sideClientButton span {
        color: $colorWhite;
    }
}