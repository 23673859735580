@import './colors.scss';
@import './fonts.scss';

#lumisoft-maxi .frontendlumisoftnomatch {
    padding-top: 10% !important;
    margin: 0 !important;
    width: 100%;
    height: 100%;
}

#lumisoft-maxi .frontendlumisoftnomatch .lumisoftnomatchwrapper {
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    height: 500px;
}

#lumisoft-maxi .frontendlumisoftnomatch .lumisoftnomatchwrapper .lumisoftnomatchtitle {
    font-size: 170px !important;
    margin-bottom: 50px !important;
    line-height: 1;
    padding-top: 40px;
}

#lumisoft-maxi.light .frontendlumisoftnomatch .lumisoftnomatchwrapper .lumisoftnomatchtitle {
    color: $colorBlue;
}
#lumisoft-maxi.dark .frontendlumisoftnomatch .lumisoftnomatchwrapper .lumisoftnomatchtitle {
    color: $colorGreen;
}

#lumisoft-maxi .frontendlumisoftnomatch .lumisoftnomatchwrapper .textUppercase {
    text-transform: uppercase !important;
}

#lumisoft-maxi.light .frontendlumisoftnomatch .lumisoftnomatchwrapper .lead,
#lumisoft-maxi.light .frontendlumisoftnomatch .lumisoftnomatchwrapper .textUppercase {
    color: $colorBlack
}

#lumisoft-maxi.dark .frontendlumisoftnomatch .lumisoftnomatchwrapper .lead,
#lumisoft-maxi.dark .frontendlumisoftnomatch .lumisoftnomatchwrapper .textUppercase {
    color: $colorWhite
}

#lumisoft-maxi .frontendlumisoftnomatch .lumisoftnomatchwrapper .lead {
    font-size: 16px;
    line-height: 32px;
    font-weight: 300;
}

//============================================== RESPONSIVE
@media (max-width: 767.98px) {

    #lumisoft-maxi .frontendlumisoftnomatch {
        padding-top: 30% !important;
    }
}
@media (min-width: 767.99px) and (max-width: 835.98px) {
 
    #lumisoft-maxi .frontendlumisoftnomatch {
        padding-top: 20% !important;
    }
}
@media (min-width: 1024px) and (max-width: 1279.98px){
 
    #lumisoft-maxi .frontendlumisoftnomatch {
        padding-top: 15% !important;
    }
}