@import '../colors.scss';
@import '../fonts.scss';

#client .clientpinWrapper .formGroup,
#client .clientpagelayout .formGroup {
    display: block;
    margin-left: 0;
    margin-right: 0;
    position: relative;
}

#client .clientpinWrapper .formGroup .formWrapper,
#client .clientpagelayout .formGroup .formWrapper {
    font-size: 15px;
    line-height: 15px;
    color: rgba(0, 0, 0, .65);
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    list-style: none;
    position: relative;
    display: inline-block;
    width: 100%;
    height: 44px;
}

#client .clientpinWrapper .formGroup .formWrapper .pincode-input-container {
    display: flex;
    flex-direction: row;
}

#client .clientpinWrapper .formGroup .formWrapper .pincode-input-container .inputclasspinsix {
    margin-right: 5px;
    padding: 7px 7px 7px 21px;
}

#client .clientpagelayout .formGroup .iconPosition {
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    line-height: 0;
    color: $colorDarkGray;
    z-index: 3;
    margin-left: 10px;
    // cursor: pointer;
}

#client .clientpagelayout input[type="checkbox"],
#client .clientpagelayout input[type="radio"] {
    box-sizing: border-box;
    cursor: pointer;
    line-height: normal;
}

#client .clientpagelayout input[type="checkbox"],
#client .clientpagelayout input[type="radio"] {
    margin: 4px 0 0;
    padding: 0;
}

#client .clientpagelayout .formBlock.selectobject .inputclass,
#client .clientpagelayout .formBlock.selectboolean .inputclass {
    display: grid !important;
    min-width: 229px;
}

#client .clientpagelayout .formBlock.selectobject .inputclass .name,
#client .clientpagelayout .formBlock.selectboolean .inputclass .name {
    align-self: center !important;
}

#client .clientpagelayout .cardBody .cardOptions .formBlock .dropdowninput {
    display: flex;
    align-items: center;
    color: $colorGray;
    font-weight: 300;
}

#client .clientpagelayout textarea {
    resize: none;
    height: 200px !important;
}

#client .clientpagelayout textarea,
#client .clientpagelayout input[type="text"],
#client .clientpagelayout input[type="password"],
#client .clientpagelayout input[type="datetime"],
#client .clientpagelayout input[type="daterange"],
#client .clientpagelayout input[type="datetime-local"],
#client .clientpagelayout input[type="date"],
#client .clientpagelayout input[type="month"],
#client .clientpagelayout input[type="time"],
#client .clientpagelayout input[type="week"],
#client .clientpagelayout input[type="number"],
#client .clientpagelayout input[type="email"],
#client .clientpagelayout input[type="url"],
#client .clientpagelayout input[type="search"],
#client .clientpagelayout input[type="tel"],
#client .clientpagelayout input[type="color"] {
    display: inline-block;
    width: 100%;
    // min-width: 257px;
    // max-width: 299px;
    -webkit-transition: all .3s;
    -o-transition: all .3s;
    transition: all .3s;
}

#client .clientpagelayout .clientPageWrapper .insidecontentWrapper .mainBodyWrapper .cardWrapper .cardBody .cardOptions ul li.inputSearch {
    width: 100%;
}

#client .clientpagelayout .cardHeader .cardOptions .formBlock.selectobject textarea,
#client .clientpagelayout .cardHeader .cardOptions .formBlock.selectobject input[type="text"],
#client .clientpagelayout .cardHeader .cardOptions .formBlock.selectobject input[type="password"],
#client .clientpagelayout .cardHeader .cardOptions .formBlock.selectobject input[type="datetime"],
#client .clientpagelayout .cardHeader .cardOptions .formBlock.selectobject input[type="daterange"],
#client .clientpagelayout .cardHeader .cardOptions .formBlock.selectobject input[type="datetime-local"],
#client .clientpagelayout .cardHeader .cardOptions .formBlock.selectobject input[type="date"],
#client .clientpagelayout .cardHeader .cardOptions .formBlock.selectobject input[type="month"],
#client .clientpagelayout .cardHeader .cardOptions .formBlock.selectobject input[type="time"],
#client .clientpagelayout .cardHeader .cardOptions .formBlock.selectobject input[type="week"],
#client .clientpagelayout .cardHeader .cardOptions .formBlock.selectobject input[type="number"],
#client .clientpagelayout .cardHeader .cardOptions .formBlock.selectobject input[type="email"],
#client .clientpagelayout .cardHeader .cardOptions .formBlock.selectobject input[type="url"],
#client .clientpagelayout .cardHeader .cardOptions .formBlock.selectobject input[type="search"],
#client .clientpagelayout .cardHeader .cardOptions .formBlock.selectobject input[type="tel"],
#client .clientpagelayout .cardHeader .cardOptions .formBlock.selectobject input[type="color"],
#client .clientpagelayout .cardHeader .cardOptions .formBlock.selectobject .inputclass,
#client .clientpagelayout .cardHeader .cardOptions .formBlock.selectboolean textarea,
#client .clientpagelayout .cardHeader .cardOptions .formBlock.selectboolean input[type="text"],
#client .clientpagelayout .cardHeader .cardOptions .formBlock.selectboolean input[type="password"],
#client .clientpagelayout .cardHeader .cardOptions .formBlock.selectboolean input[type="datetime"],
#client .clientpagelayout .cardHeader .cardOptions .formBlock.selectboolean input[type="daterange"],
#client .clientpagelayout .cardHeader .cardOptions .formBlock.selectboolean input[type="datetime-local"],
#client .clientpagelayout .cardHeader .cardOptions .formBlock.selectboolean input[type="date"],
#client .clientpagelayout .cardHeader .cardOptions .formBlock.selectboolean input[type="month"],
#client .clientpagelayout .cardHeader .cardOptions .formBlock.selectboolean input[type="time"],
#client .clientpagelayout .cardHeader .cardOptions .formBlock.selectboolean input[type="week"],
#client .clientpagelayout .cardHeader .cardOptions .formBlock.selectboolean input[type="number"],
#client .clientpagelayout .cardHeader .cardOptions .formBlock.selectboolean input[type="email"],
#client .clientpagelayout .cardHeader .cardOptions .formBlock.selectboolean input[type="url"],
#client .clientpagelayout .cardHeader .cardOptions .formBlock.selectboolean input[type="search"],
#client .clientpagelayout .cardHeader .cardOptions .formBlock.selectboolean input[type="tel"],
#client .clientpagelayout .cardHeader .cardOptions .formBlock.selectboolean input[type="color"],
#client .clientpagelayout .cardHeader .cardOptions .formBlock.selectboolean .inputclass {
    font-family: $arimo;
    // max-width: 229px;
}

#client .clientpagelayout .cardBody .cardOptions .formBlock.selectobject textarea,
#client .clientpagelayout .cardBody .cardOptions .formBlock.selectobject input[type="text"],
#client .clientpagelayout .cardBody .cardOptions .formBlock.selectobject input[type="password"],
#client .clientpagelayout .cardBody .cardOptions .formBlock.selectobject input[type="datetime"],
#client .clientpagelayout .cardBody .cardOptions .formBlock.selectobject input[type="daterange"],
#client .clientpagelayout .cardBody .cardOptions .formBlock.selectobject input[type="datetime-local"],
#client .clientpagelayout .cardBody .cardOptions .formBlock.selectobject input[type="date"],
#client .clientpagelayout .cardBody .cardOptions .formBlock.selectobject input[type="month"],
#client .clientpagelayout .cardBody .cardOptions .formBlock.selectobject input[type="time"],
#client .clientpagelayout .cardBody .cardOptions .formBlock.selectobject input[type="week"],
#client .clientpagelayout .cardBody .cardOptions .formBlock.selectobject input[type="number"],
#client .clientpagelayout .cardBody .cardOptions .formBlock.selectobject input[type="email"],
#client .clientpagelayout .cardBody .cardOptions .formBlock.selectobject input[type="url"],
#client .clientpagelayout .cardBody .cardOptions .formBlock.selectobject input[type="search"],
#client .clientpagelayout .cardBody .cardOptions .formBlock.selectobject input[type="tel"],
#client .clientpagelayout .cardBody .cardOptions .formBlock.selectobject input[type="color"],
#client .clientpagelayout .cardBody .cardOptions .formBlock.selectobject .inputclass,
#client .clientpagelayout .cardBody .cardOptions .formBlock.selectboolean textarea,
#client .clientpagelayout .cardBody .cardOptions .formBlock.selectboolean input[type="text"],
#client .clientpagelayout .cardBody .cardOptions .formBlock.selectboolean input[type="password"],
#client .clientpagelayout .cardBody .cardOptions .formBlock.selectboolean input[type="datetime"],
#client .clientpagelayout .cardBody .cardOptions .formBlock.selectboolean input[type="daterange"],
#client .clientpagelayout .cardBody .cardOptions .formBlock.selectboolean input[type="datetime-local"],
#client .clientpagelayout .cardBody .cardOptions .formBlock.selectboolean input[type="date"],
#client .clientpagelayout .cardBody .cardOptions .formBlock.selectboolean input[type="month"],
#client .clientpagelayout .cardBody .cardOptions .formBlock.selectboolean input[type="time"],
#client .clientpagelayout .cardBody .cardOptions .formBlock.selectboolean input[type="week"],
#client .clientpagelayout .cardBody .cardOptions .formBlock.selectboolean input[type="number"],
#client .clientpagelayout .cardBody .cardOptions .formBlock.selectboolean input[type="email"],
#client .clientpagelayout .cardBody .cardOptions .formBlock.selectboolean input[type="url"],
#client .clientpagelayout .cardBody .cardOptions .formBlock.selectboolean input[type="search"],
#client .clientpagelayout .cardBody .cardOptions .formBlock.selectboolean input[type="tel"],
#client .clientpagelayout .cardBody .cardOptions .formBlock.selectboolean input[type="color"],
#client .clientpagelayout .cardBody .cardOptions .formBlock.selectboolean .inputclass,
#client .clientpagelayout .cardBody .cardOptions .formBlock .dropdowninput {
    font-family: $arimo;
    // max-width: 257px;
}

#client .clientpagelayout textarea,
#client .clientpagelayout input[type="text"],
#client .clientpagelayout input[type="password"],
#client .clientpagelayout input[type="datetime"],
#client .clientpagelayout input[type="daterange"],
#client .clientpagelayout input[type="datetime-local"],
#client .clientpagelayout input[type="date"],
#client .clientpagelayout input[type="month"],
#client .clientpagelayout input[type="time"],
#client .clientpagelayout input[type="week"],
#client .clientpagelayout input[type="number"],
#client .clientpagelayout input[type="email"],
#client .clientpagelayout input[type="url"],
#client .clientpagelayout input[type="search"],
#client .clientpagelayout input[type="tel"],
#client .clientpagelayout input[type="color"],
#client .clientpagelayout .formBlock.selectobject .inputclass,
#client .clientpagelayout .formBlock.selectboolean .inputclass,
#client .clientpagelayout .dropdowninput {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    margin: 0;
    list-style: none;
    position: relative;
    padding: 7px 13px 7px 30px;
    width: 100%;
    height: 44px;
    font-size: 15px;
    line-height: 1.5;
    background-image: none;
    border-radius: 8px;
}

#client .clientpagelayout.dark textarea,
#client .clientpagelayout.dark input[type="text"],
#client .clientpagelayout.dark input[type="password"],
#client .clientpagelayout.dark input[type="datetime"],
#client .clientpagelayout.dark input[type="daterange"],
#client .clientpagelayout.dark input[type="datetime-local"],
#client .clientpagelayout.dark input[type="date"],
#client .clientpagelayout.dark input[type="month"],
#client .clientpagelayout.dark input[type="time"],
#client .clientpagelayout.dark input[type="week"],
#client .clientpagelayout.dark input[type="number"],
#client .clientpagelayout.dark input[type="email"],
#client .clientpagelayout.dark input[type="url"],
#client .clientpagelayout.dark input[type="search"],
#client .clientpagelayout.dark input[type="tel"],
#client .clientpagelayout.dark input[type="color"],
#client .clientpagelayout.dark .formBlock.selectobject .inputclass,
#client .clientpagelayout.dark .formBlock.selectboolean .inputclass,
#client .clientpagelayout.dark .dropdowninput {
    color: $colorWhite;
    background-color: $colorVeryDarkGray;
    // border: 0;
    border: 1px solid $colorDarkGrayPlus;
}

#client .clientpagelayout.dark .dropdowninput.disabled,
#client .clientpagelayout.dark .inputclass.disabled,
#client .clientpagelayout.dark .inputbuttonForSelect.disabled,
#client .clientpagelayout.dark .formBlock.selectobject .inputclass.inputclass.disable,
#client .clientpagelayout.dark .formBlock.selectboolean .inputclass.inputclass.disabled {
    background-color: $colorDarkGrayPlus;
}

#client .clientpagelayout.light .dropdowninput.disabled,
#client .clientpagelayout.light .inputclass.disabled,
#client .clientpagelayout.light .inputbuttonForSelect.disabled,
#client .clientpagelayout.light .formBlock.selectobject .inputclass.inputclass.disabled,
#client .clientpagelayout.light .formBlock.selectboolean .inputclass.inputclass.disabled {
    background-color: $colorVeryLightGray;
}

#client .clientpagelayout.light textarea,
#client .clientpagelayout.light input[type="text"],
#client .clientpagelayout.light input[type="password"],
#client .clientpagelayout.light input[type="datetime"],
#client .clientpagelayout.light input[type="daterange"],
#client .clientpagelayout.light input[type="datetime-local"],
#client .clientpagelayout.light input[type="date"],
#client .clientpagelayout.light input[type="month"],
#client .clientpagelayout.light input[type="time"],
#client .clientpagelayout.light input[type="week"],
#client .clientpagelayout.light input[type="number"],
#client .clientpagelayout.light input[type="email"],
#client .clientpagelayout.light input[type="url"],
#client .clientpagelayout.light input[type="search"],
#client .clientpagelayout.light input[type="tel"],
#client .clientpagelayout.light input[type="color"],
#client .clientpagelayout.light .formBlock.selectobject .inputclass,
#client .clientpagelayout.light .formBlock.selectboolean .inputclass,
#client .clientpagelayout.light .dropdowninput {
    color: $colorVeryDark;
    background-color: $colorWhite;
    // border: 0;
    border: 1px solid $colorVeryLightGray;
}

#client .clientpagelayout.dark textarea:hover,
#client .clientpagelayout.dark input[type="text"]:hover,
#client .clientpagelayout.dark input[type="password"]:hover,
#client .clientpagelayout.dark input[type="datetime"]:hover,
#client .clientpagelayout.dark input[type="daterange"]:hover,
#client .clientpagelayout.dark input[type="datetime-local"]:hover,
#client .clientpagelayout.dark input[type="date"]:hover,
#client .clientpagelayout.dark input[type="month"]:hover,
#client .clientpagelayout.dark input[type="time"]:hover,
#client .clientpagelayout.dark input[type="week"]:hover,
#client .clientpagelayout.dark input[type="number"]:hover,
#client .clientpagelayout.dark input[type="email"]:hover,
#client .clientpagelayout.dark input[type="url"]:hover,
#client .clientpagelayout.dark input[type="search"]:hover,
#client .clientpagelayout.dark input[type="tel"]:hover,
#client .clientpagelayout.dark input[type="color"]:hover,
#client .clientpagelayout.dark .formBlock.selectobject .inputclass:hover,
#client .clientpagelayout.dark .formBlock.selectboolean .inputclass:hover,
#client .clientpagelayout.dark .dropdowninput:hover {
    border-color: $colorDarkGray !important;
}

#client .clientpagelayout.light textarea:hover,
#client .clientpagelayout.light input[type="text"]:hover,
#client .clientpagelayout.light input[type="password"]:hover,
#client .clientpagelayout.light input[type="datetime"]:hover,
#client .clientpagelayout.light input[type="daterange"]:hover,
#client .clientpagelayout.light input[type="datetime-local"]:hover,
#client .clientpagelayout.light input[type="date"]:hover,
#client .clientpagelayout.light input[type="month"]:hover,
#client .clientpagelayout.light input[type="time"]:hover,
#client .clientpagelayout.light input[type="week"]:hover,
#client .clientpagelayout.light input[type="number"]:hover,
#client .clientpagelayout.light input[type="email"]:hover,
#client .clientpagelayout.light input[type="url"]:hover,
#client .clientpagelayout.light input[type="search"]:hover,
#client .clientpagelayout.light input[type="tel"]:hover,
#client .clientpagelayout.light input[type="color"]:hover,
#client .clientpagelayout.light .formBlock.selectobject .inputclass:hover,
#client .clientpagelayout.light .formBlock.selectboolean .inputclass:hover,
#client .clientpagelayout.light .dropdowninput:hover {
    border-color: $colorLightGray !important;
}

#client .clientpagelayout.dark textarea:focus,
#client .clientpagelayout.dark input[type="text"]:focus,
#client .clientpagelayout.dark input[type="password"]:focus,
#client .clientpagelayout.dark input[type="datetime"]:focus,
#client .clientpagelayout.dark input[type="daterange"]:focus,
#client .clientpagelayout.dark input[type="datetime-local"]:focus,
#client .clientpagelayout.dark input[type="date"]:focus,
#client .clientpagelayout.dark input[type="month"]:focus,
#client .clientpagelayout.dark input[type="time"]:focus,
#client .clientpagelayout.dark input[type="week"]:focus,
#client .clientpagelayout.dark input[type="number"]:focus,
#client .clientpagelayout.dark input[type="email"]:focus,
#client .clientpagelayout.dark input[type="url"]:focus,
#client .clientpagelayout.dark input[type="search"]:focus,
#client .clientpagelayout.dark input[type="tel"]:focus,
#client .clientpagelayout.dark input[type="color"]:focus,
#client .clientpagelayout.dark .formBlock.selectobject .inputclass:focus,
#client .clientpagelayout.dark .formBlock.selectboolean .inputclass:focus,
#client .clientpagelayout.dark .dropdowninput:focus {
    border-color: $colorDarkGray !important;
    background-color: $colorVeryDarkGray !important;
    outline: none;
}

#client .clientpagelayout.light textarea:focus,
#client .clientpagelayout.light input[type="text"]:focus,
#client .clientpagelayout.light input[type="password"]:focus,
#client .clientpagelayout.light input[type="datetime"]:focus,
#client .clientpagelayout.light input[type="daterange"]:focus,
#client .clientpagelayout.light input[type="datetime-local"]:focus,
#client .clientpagelayout.light input[type="date"]:focus,
#client .clientpagelayout.light input[type="month"]:focus,
#client .clientpagelayout.light input[type="time"]:focus,
#client .clientpagelayout.light input[type="week"]:focus,
#client .clientpagelayout.light input[type="number"]:focus,
#client .clientpagelayout.light input[type="email"]:focus,
#client .clientpagelayout.light input[type="url"]:focus,
#client .clientpagelayout.light input[type="search"]:focus,
#client .clientpagelayout.light input[type="tel"]:focus,
#client .clientpagelayout.light input[type="color"]:focus,
#client .clientpagelayout.light .formBlock.selectobject .inputclass:focus,
#client .clientpagelayout.light .formBlock.selectboolean .inputclass:focus,
#client .clientpagelayout.light .dropdowninput:focus {
    border-color: $colorLightGray !important;
    background-color: $colorWhite !important;
    outline: none;
}

#client .clientpagelayout.dark textarea:disabled,
#client .clientpagelayout.dark input[type="text"]:disabled,
#client .clientpagelayout.dark input[type="password"]:disabled,
#client .clientpagelayout.dark input[type="datetime"]:disabled,
#client .clientpagelayout.dark input[type="daterange"]:disabled,
#client .clientpagelayout.dark input[type="datetime-local"]:disabled,
#client .clientpagelayout.dark input[type="date"]:disabled,
#client .clientpagelayout.dark input[type="month"]:disabled,
#client .clientpagelayout.dark input[type="time"]:disabled,
#client .clientpagelayout.dark input[type="week"]:disabled,
#client .clientpagelayout.dark input[type="number"]:disabled,
#client .clientpagelayout.dark input[type="email"]:disabled,
#client .clientpagelayout.dark input[type="url"]:disabled,
#client .clientpagelayout.dark input[type="search"]:disabled,
#client .clientpagelayout.dark input[type="tel"]:disabled,
#client .clientpagelayout.dark input[type="color"]:disabled,
#client .clientpagelayout.dark .formBlock.selectobject .inputclass:disabled,
#client .clientpagelayout.dark .formBlock.selectobject .inputclass.disabled,
#client .clientpagelayout.dark .formBlock.selectboolean .inputclass:disabled,
#client .clientpagelayout.dark .formBlock.selectboolean .inputclass.disabled,
#client .clientpagelayout.dark .dropdowninput:disabled {
    pointer-events: none;
    color: $colorGray !important;
}

#client .clientpagelayout.light textarea:disabled,
#client .clientpagelayout.light input[type="text"]:disabled,
#client .clientpagelayout.light input[type="password"]:disabled,
#client .clientpagelayout.light input[type="datetime"]:disabled,
#client .clientpagelayout.light input[type="daterange"]:disabled,
#client .clientpagelayout.light input[type="datetime-local"]:disabled,
#client .clientpagelayout.light input[type="date"]:disabled,
#client .clientpagelayout.light input[type="month"]:disabled,
#client .clientpagelayout.light input[type="time"]:disabled,
#client .clientpagelayout.light input[type="week"]:disabled,
#client .clientpagelayout.light input[type="number"]:disabled,
#client .clientpagelayout.light input[type="email"]:disabled,
#client .clientpagelayout.light input[type="url"]:disabled,
#client .clientpagelayout.light input[type="search"]:disabled,
#client .clientpagelayout.light input[type="tel"]:disabled,
#client .clientpagelayout.light input[type="color"]:disabled,
#client .clientpagelayout.light .formBlock.selectobject .inputclass:disabled,
#client .clientpagelayout.light .formBlock.selectobject .inputclass.disabled,
#client .clientpagelayout.light .formBlock.selectboolean .inputclass:disabled,
#client .clientpagelayout.light .formBlock.selectboolean .inputclass.disabled,
#client .clientpagelayout.light .dropdowninput:disabled {
    pointer-events: none;
    color: $colorDarkGrayPlus !important;
}

#client .clientpagelayout .cardBody .cardOptions .formBlock.selectobject .inputclass,
#client .clientpagelayout .cardBody .cardOptions .formBlock.selectboolean .inputclass {
    display: flex;
    align-items: center;
}


// BOOLEAN WITH BUTTON

#client .clientpagelayout .formGroup .buttonWrapper {
    display: flex;
    flex-direction: row;
}

#client .clientpagelayout .formGroup .iconButton {
    font-size: 25px;
    cursor: pointer;
    border-radius: 10px;
    display: flex;
    flex-direction: row;
    transition: all 0.5s ease;
    width: 42px;
}

#client .clientpagelayout.light .formGroup .iconButton {
    background-color: $colorBlue;
    color: $colorWhite;
}

#client .clientpagelayout.dark .formGroup .iconButton {
    background-color: $colorGreen;
    color: $colorWhite;
}

#client .clientpagelayout .formGroup .iconButton svg {
    height: 18px;
    width: 18px;
    margin: 12px;
}

#client .clientpagelayout .formGroup .buttonWrapper .inputarrayWrapper {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    margin: 0;
    list-style: none;
    position: relative;
    display: flex;
    align-items: center;
    width: 450px;
    min-width: 450px;
    margin-right: 20px;
}

#client .clientpagelayout .formGroup .buttonWrapper .inputarray {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    margin: 0;
    list-style: none;
    position: relative;
    display: flex;
    align-items: center;
    padding: 7px 13px;
    width: 450px;
    min-width: 450px;
    height: 44px;
    font-size: 15px;
    line-height: 1.5;
    background-image: none;
    border-radius: 8px;
    font-family: $roboto;
    // -webkit-transition: all .3s;
    // -o-transition: all .3s;
    // transition: all .3s;
    cursor: text;
}

#client .clientpagelayout .formGroup .tablearray {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    margin: 0;
    list-style: none;
    position: relative;
    display: flex;
    align-items: center;
    padding: 7px 13px;
    width: 512px;
    min-width: 512px;
    height: 44px;
    font-size: 15px;
    line-height: 1.5;
    background-image: none;
    border-radius: 8px;
    font-family: $roboto;
    margin-top: 10px;
    cursor:default;
}

#client .clientpagelayout.dark .formGroup .tablearray {
    color: $colorWhite;
    background-color: $colorVeryDarkGray;
    border: 1px solid $colorDarkGrayPlus;
}

#client .clientpagelayout.light .formGroup .tablearray {
    color: $colorVeryDark;
    background-color: $colorWhite;
    border: 1px solid $colorVeryLightGray;
}

#client .clientpagelayout.dark .formGroup .buttonWrapper .inputarray.disabled {
    color: $colorGray;
}

#client .clientpagelayout.light .formGroup .buttonWrapper .inputarray.disabled {
    color: $colorGray !important;
}

#client .clientpagelayout.dark .formGroup .buttonWrapper .inputarray {
    color: $colorWhite;
    background-color: $colorVeryDarkGray;
    border: 1px solid $colorDarkGrayPlus;
}

#client .clientpagelayout.light .formGroup .buttonWrapper .inputarray {
    color: $colorVeryDark;
    background-color: $colorWhite;
    border: 1px solid $colorVeryLightGray;
}

#client .clientpagelayout .formGroup .buttonWrapper .inputarray:hover {
    cursor: pointer;
}

#client .clientpagelayout.dark .formGroup .buttonWrapper .inputarray:hover,
#client .clientpagelayout.dark .formGroup .buttonWrapper .inputarray:focus {
    border-color: $colorDarkGray !important;
}

#client .clientpagelayout.light .formGroup .buttonWrapper .inputarray:hover,
#client .clientpagelayout.light .formGroup .buttonWrapper .inputarray:focus {
    border-color: $colorLightGray !important;
}

#client .clientpagelayout .formGroup .buttonWrapper .dropdownmenu {
    position: absolute;
    width: 100%;
    // height: 100%;
    // max-width: 290px;
    top: 98%;
    left: 0;
    z-index: 900;
    float: left;
    padding: 0;
    list-style: none;
    font-size: 13px;
    text-align: left;
    background-color: $colorWhite;
    border-radius: 0;
    background-clip: padding-box;
    max-height: 250px;
    overflow-y: scroll;
    scrollbar-width: thin;
}

@media (max-width: 767.98px) {
    #client .mobileHide {
        display: none !important
    }

    #client .clientpagelayout textarea,
    #client .clientpagelayout input[type="text"],
    #client .clientpagelayout input[type="password"],
    #client .clientpagelayout input[type="datetime"],
    #client .clientpagelayout input[type="daterange"],
    #client .clientpagelayout input[type="datetime-local"],
    #client .clientpagelayout input[type="date"],
    #client .clientpagelayout input[type="month"],
    #client .clientpagelayout input[type="time"],
    #client .clientpagelayout input[type="week"],
    #client .clientpagelayout input[type="number"],
    #client .clientpagelayout input[type="email"],
    #client .clientpagelayout input[type="url"],
    #client .clientpagelayout input[type="search"],
    #client .clientpagelayout input[type="tel"],
    #client .clientpagelayout input[type="color"] {
        max-width: none;
        width: 100%;
    }

    #client .formGroup .formWrapper .pincode-input-container .inputclasspinsix {
        margin-right: 5px;
        padding: 7px 7px 7px 14px;
        height: 38px;
    }
}