@import './colors.scss';
@import './fonts.scss';

#lumisoft-maxi #gallerypage .cardWrapper {
    margin-bottom: 30px;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
    letter-spacing: 0.5px;
    border-radius: 10px;
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-clip: border-box;
}

#lumisoft-maxi.light #gallerypage .cardWrapper {
    // border: 1px solid $colorVeryLightGray;
    background-color: $colorWhite;
}

#lumisoft-maxi.dark #gallerypage .cardWrapper {
    // border: 1px solid $colorDarkGrayPlus;
    background-color: $colorVeryDark;
}

#lumisoft-maxi #gallerypage .cardWrapper .cardMedia {
    width: 100%;
    padding: 20px 0;
}

#lumisoft-maxi #gallerypage .cardWrapper .cardMedia .mediaList .lg-react-element {
    column-count: 6;
    column-gap: 10px;
}

#lumisoft-maxi #gallerypage .cardWrapper .cardMedia .mediaList .lg-react-element img {
    max-width: 100%;
    display: block;
    padding: 5px 0px;
    border-radius: 20px;
    transition: transform 0.2s;
}

#lumisoft-maxi #gallerypage .cardWrapper .cardMedia .mediaList .lg-react-element img:hover {
    // filter: opacity(.9);
    transform: scale(1.01);
}

#lumisoft-maxi #gallerypage .cardWrapper .cardMedia .mediaList .lumiGallery .imgGallery {
    // column-count: 4;
    // column-gap: 10px;
    // -moz-column-count: 4;
    // -moz-column-gap: 10px;
    // -webkit-column-count: 4;
    // -webkit-column-gap: 10px;
    --gap: 12px;
    --columns: 4;
    margin: 0 auto;
    display: column;
    columns: var(--columns);
    gap: var(--gap);
}

#lumisoft-maxi #gallerypage .cardWrapper .cardMedia .mediaList .lumiGallery .imgGallery > * {
    break-inside: avoid;
    // margin-bottom: var(--gap);
}

@supports (grid-template-rows: masonry) {
    #lumisoft-maxi #gallerypage .cardWrapper .cardMedia .mediaList .lumiGallery .imgGallery {
      display: grid;
      grid-template-columns: repeat(var(--columns), 1fr);
      grid-template-rows: masonry;
      grid-auto-flow: dense;
      /* align-tracks: stretch; */
    }
  
    #lumisoft-maxi #gallerypage .cardWrapper .cardMedia .mediaList .lumiGallery .imgGallery > * {
      margin-bottom: 0em;
    }
  }

#lumisoft-maxi #gallerypage .cardWrapper .cardMedia .mediaList .lumiGallery .imgGallery img {
    max-width: 100%;
    display: block;
    padding: 5px 0px;
    border-radius: 10px;
    transition: transform 0.2s;
}

#lumisoft-maxi #gallerypage .cardWrapper .cardMedia .mediaList .lumiGallery .imgGallery img:hover {
    filter: opacity(.95);
    transform: scale(1.01);
    cursor: pointer;
}

// #lumisoft-maxi #gallerypage .cardWrapper .cardMedia .mediaList .lumiGallery .imgGallery .singleGallery:nth-child(4n+1) {
//     clear: left;
// }

// #lumisoft-maxi #gallerypage .cardWrapper .cardMedia .mediaList .lumiGallery .imgGallery .singleGallery {
//     float: left;
//     // margin: 5px;
// }

#client .mainImageScreen .imgCardWrapper .imgCardInnerWrapper {
    padding: 10px;
    border: 1px solid $colorLightGray;
}

#client .mainImageScreen.light .imgCardWrapper.selected .imgCardInnerWrapper {
    border: 1px solid $colorBlueLogo;
    background-color: $colorBlueLogo;
    color: $colorWhite;
}

#client .mainImageScreen.dark .imgCardWrapper.selected .imgCardInnerWrapper {
    border: 1px solid $colorGreen;
    background-color: $colorGreen;
    color: $colorWhite;
}

#client .mainImageScreen .imgCardWrapper .imgCardInnerWrapper .imgText {
    padding-top: 10px;
    padding-left: 3px;
    font-size: 16px;
}


// OPEN GALLERY POP UP
#lumisoft-maxi #gallerypage .cardWrapper .cardMedia .mediaList .lumiGallery .galleryScreen {
    position: fixed;
    top: 50%;
    left: 50%;
    right: 0;
    width: 100%; // kalau ini ga 100%, mobile phone ga full width
    height: 101%;
    z-index: 2200;
    display: flex;
    justify-content: center;
    align-self: center;
    pointer-events: all;
    visibility: hidden;
    opacity: 0;
    transition: transform 0.5s ease, opacity 0.3s ease;
    transform: translate(-50%, -50%);
    will-change: transform;
}

#lumisoft-maxi #gallerypage .cardWrapper .cardMedia .mediaList .lumiGallery .galleryScreen.open {
    visibility: visible;
    opacity: 1;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

#lumisoft-maxi #gallerypage .cardWrapper .cardMedia .mediaList .lumiGallery .galleryScreen .btnClose {
    position: fixed;
    top: 40px;
    right: 40px;
    z-index: 2200;
    color: $colorVeryLightGray;
    font-size: 40px;
    cursor: pointer;
}

#lumisoft-maxi #gallerypage .cardWrapper .cardMedia .mediaList .lumiGallery .galleryScreen .btnClose:hover {
    color: $colorWhite;
}

#lumisoft-maxi #gallerypage .cardWrapper .cardMedia .mediaList .lumiGallery .galleryScreen .btnPrev {
    position: fixed;
    top: 50%;
    left: 40px;
    transform: translateY(-50%);
    z-index: 2200;
    color: $colorVeryLightGray;
    font-size: 40px;
    cursor: pointer;
}

#lumisoft-maxi #gallerypage .cardWrapper .cardMedia .mediaList .lumiGallery .galleryScreen .btnPrev:hover {
    color: $colorWhite;
}

#lumisoft-maxi #gallerypage .cardWrapper .cardMedia .mediaList .lumiGallery .galleryScreen .btnNext {
    position: fixed;
    top: 50%;
    right: 40px;
    transform: translateY(-50%);
    z-index: 2200;
    color: $colorVeryLightGray;
    font-size: 40px;
    cursor: pointer;
}

#lumisoft-maxi #gallerypage .cardWrapper .cardMedia .mediaList .lumiGallery .galleryScreen .btnNext:hover {
    color: $colorWhite;
}

#lumisoft-maxi #gallerypage .cardWrapper .cardMedia .mediaList .lumiGallery .galleryScreen .galleryInfo {
    position: fixed;
    bottom: 40px;
    z-index: 2200;
    color: $colorWhite;
}

#lumisoft-maxi #gallerypage .cardWrapper .cardMedia .mediaList .lumiGallery .galleryScreen .gallerybackdrop {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.9);
    z-index: 2100;
    cursor: pointer;
}

#lumisoft-maxi #gallerypage .cardWrapper .cardMedia .mediaList .lumiGallery .galleryScreen .fullscreenWrapper {
    max-width: 500px;
    max-height: 700px;
    display: flex;
    justify-content: center;
    align-self: center;
    z-index: 2200;
}

#lumisoft-maxi #gallerypage .cardWrapper .cardMedia .mediaList .lumiGallery .galleryScreen .fullscreenWrapper .fullscreenImage {
    display: flex;
    justify-content: center;
    align-self: center;
}

#lumisoft-maxi #gallerypage .cardWrapper .cardMedia .mediaList .lumiGallery .galleryScreen .fullscreenWrapper .fullscreenImage img {
    width: 100%;
    max-width: 100%;
    object-fit: cover;
    pointer-events: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

@media (max-width: 767.98px) {
    #lumisoft-maxi #gallerypage .cardWrapper .cardMedia .mediaList .lumiGallery .imgGallery {
        // column-count: 4;
        // column-gap: 10px;
        // -moz-column-count: 4;
        // -moz-column-gap: 10px;
        // -webkit-column-count: 4;
        // -webkit-column-gap: 10px;
        --gap: 10px;
        --columns: 1;
        columns: var(--columns);
        gap: var(--gap);
    }
    #lumisoft-maxi #gallerypage .cardWrapper .cardMedia .mediaList .lumiGallery .galleryScreen .btnClose {
        top: 10px;
        right: 10px;
    }

    #lumisoft-maxi #gallerypage .cardWrapper .cardMedia .mediaList .lumiGallery .galleryScreen .btnPrev {
        top: unset;
        bottom: 0;
        right: 50px;
    }

    #lumisoft-maxi #gallerypage .cardWrapper .cardMedia .mediaList .lumiGallery .galleryScreen .btnNext {
        top: unset;
        bottom: 0;
        right: 50px;
    }
}