@import '../colors.scss';

#client .clientpagelayout .clienthome {
    display: flex;
}

// DASHBOARD HEADER
#client .clientpagelayout .clientPageWrapper .insidecontentWrapper .mainBodyWrapper .cardWrapper .dashboardHeader {
    padding: 20px 30px;
}

#client .clientpagelayout .clientPageWrapper .dashboardProfile {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
}

#client .clientpagelayout .clientPageWrapper .dashboardProfile .imgWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
}

#client .clientpagelayout .clientPageWrapper .dashboardProfile .imgWrapper img {
    width: 100px;
    height: 100px;
    border-radius: 50px;
}

#client .clientpagelayout .clientPageWrapper .dashboardProfile.addbutton {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

#client .clientpagelayout .clientPageWrapper .dashboardProfile .greetWrapper {
    display: flex;
}

#client .clientpagelayout .clientPageWrapper .dashboardProfile .greetWrapper p {
    font-family: $montserrat;

}

#client .clientpagelayout .clientPageWrapper .dashboardProfile p.greetings {
    font-weight: 700;
    font-size: 20px;
}

#client .clientpagelayout .clientPageWrapper .dashboardProfile li.iconButton {
    width: 42px;
    height: 42px;
    border-radius: 8px;
    cursor: pointer;
}

#client .clientpagelayout.light .clientPageWrapper .dashboardProfile li.iconButton {
    color: $colorVeryDark;
    background-color: $colorWhiteBackground;
    border: 1px solid $colorVeryLightGray;
}

#client .clientpagelayout.dark .clientPageWrapper .dashboardProfile li.iconButton {
    color: $colorWhite;
    background-color: $colorVeryDarkGray;
    border: 1px solid $colorDarkGrayPlus;
}

#client .clientpagelayout .clientPageWrapper .dashboardProfile li span svg {
    vertical-align: middle;
    height: 16px;
    width: 16px;
    margin: 12px;
}

#client .clientpagelayout .clientPageWrapper .dashboardProfile li span svg {
    vertical-align: middle;
    height: 16px;
    width: 16px;
    margin: 12px;
}

// DASHBOARD NAVIGATION
#client .clientpagelayout .clientPageWrapper .insidecontentWrapper .mainBodyWrapper .dashboardNavigation {
    display: flex;
    justify-content: flex-end;
    padding-bottom: 10px;
}

#client .clientpagelayout .clientPageWrapper .insidecontentWrapper .mainBodyWrapper .dashboardNavigation ul {
    display: flex;
    flex-direction: row;
    align-items: center;
}

#client .clientpagelayout .clientPageWrapper .insidecontentWrapper .mainBodyWrapper .dashboardNavigation li {
    font-size: 25px;
    margin-right: 20px;
    cursor: pointer;
    border-radius: 10px;
    display: flex;
    flex-direction: row;
    transition: all 0.5s ease;
}

#client .clientpagelayout .clientPageWrapper .insidecontentWrapper .mainBodyWrapper .dashboardNavigation li:last-child {
    margin-right: 0;
}

#client .clientpagelayout .clientPageWrapper .insidecontentWrapper .mainBodyWrapper .dashboardNavigation li span {
    display: flex;
    align-items: center;
}

#client .clientpagelayout .clientPageWrapper .insidecontentWrapper .mainBodyWrapper .dashboardNavigation li span svg {
    vertical-align: middle;
    height: 18px;
    width: 18px;
    margin: 12px;
}

#client .clientpagelayout.light .clientPageWrapper .insidecontentWrapper .mainBodyWrapper .dashboardNavigation li {
    background-color: $colorWhite;
    border: 1px solid $colorVeryLightGray;
}

#client .clientpagelayout.dark .clientPageWrapper .insidecontentWrapper .mainBodyWrapper .dashboardNavigation li {
    background-color: $colorVeryDark;
    border: 1px solid $colorDarkGrayPlus;
}

#client .clientpagelayout.light .clientPageWrapper .insidecontentWrapper .mainBodyWrapper .dashboardNavigation li:hover {
    border: 1px solid $colorBlueLogo;
}

#client .clientpagelayout.dark .clientPageWrapper .insidecontentWrapper .mainBodyWrapper .dashboardNavigation li:hover {
    border: 1px solid $colorGreen;
}

#client .clientpagelayout.light .clientPageWrapper .insidecontentWrapper .mainBodyWrapper .dashboardNavigation li.active {
    color: $colorBlueLogo;
    border: 1px solid $colorBlueLogo;
}

#client .clientpagelayout.dark .clientPageWrapper .insidecontentWrapper .mainBodyWrapper .dashboardNavigation li.active {
    color: $colorGreen;
    border: 1px solid $colorGreen;
}

#client .clientpagelayout .clientPageWrapper .insidecontentWrapper .mainBodyWrapper .dashboardNavigation li span.navTitle {
    font-size: 15px;
    padding: 0 15px 0 0;
}

@media (max-width: 767.98px) {
    #client .clientpagelayout .clientPageWrapper .insidecontentWrapper .mainBodyWrapper .dashboardNavigation {
        justify-content: center;
    }
    
    #client .clientpagelayout .clientPageWrapper .dashboardProfile {
        align-items: center;
        justify-content: center;
    }

    #client .clientpagelayout .clientPageWrapper .dashboardProfile.mediapage,
    #client .clientpagelayout .clientPageWrapper .dashboardProfile.mediapage .greetWrapper {
        margin: 0;
    }

    #client .clientpagelayout .clientPageWrapper .dashboardProfile {
        flex-direction: column;
    }
}

@media (min-width: 767.99px) and (max-width: 835.98px) {
    #client .clientpagelayout .clientPageWrapper .dashboardProfile {
        align-items: center;
        justify-content: center;
    }
}

@media (min-width: 835.99px) and (max-width: 1023.98px) {
    #client .clientpagelayout .clientPageWrapper .dashboardProfile {
        align-items: center;
        justify-content: center;
    }
}