// TABLE clientLIST

#client .clientpagelayout #clientlist thead tr th:nth-child(1) {
    width: 35%;
}
#client .clientpagelayout #clientlist thead tr th:nth-child(2) {
    width: 15%;
}
#client .clientpagelayout #clientlist thead tr th:nth-child(3) {
    width: 15%;
}
#client .clientpagelayout #clientlist thead tr th:nth-child(4) {
    width: 15%;
}
#client .clientpagelayout #clientlist thead tr th:nth-child(5) {
    width: 20%;
}


// TABLE BILLINGLIST

#client .clientpagelayout #billinglist thead tr th:nth-child(1) {
    width: 10%;
}
#client .clientpagelayout #billinglist thead tr th:nth-child(2) {
    width: 15%;
}
#client .clientpagelayout #billinglist thead tr th:nth-child(3) {
    width: 15%;
}
#client .clientpagelayout #billinglist thead tr th:nth-child(4) {
    width: 15%;
}
#client .clientpagelayout #billinglist thead tr th:nth-child(5) {
    width: 15%;
}
#client .clientpagelayout #billinglist thead tr th:nth-child(6) {
    width: 15%;
}
#client .clientpagelayout #billinglist thead tr th:nth-child(7) {
    width: 15%;
}