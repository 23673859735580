@import '../colors.scss';
@import '../fonts.scss';

// FLOATING BUTTON //////////////////////////////
.floatingbutton {
    bottom: 30px;
}

.floatingbutton.theme {
    bottom: 100px;
}

.floatingbuttontotop {
    bottom: 90px;
}

.floatingbutton,
.floatingbuttontotop {
    position: fixed;
    right: 20px;
    cursor: pointer;
    z-index: 2000;
    margin: 0;
    padding: 0;
}

.floatingbutton .buttonicon,
.floatingbuttontotop .buttonicon {
    padding: 10px;
    font-size: 18px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.floatingbutton,
.floatingbuttontotop {
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.5);
}

.floatingbutton .buttonicon {
    background-color: $colorGreen;
    color: $colorWhite;
}

.dark .floatingbutton.theme .buttonicon {
    background-color: $colorWhite;
    color: $colorVeryDarkGray;
}

.light .floatingbutton.theme .buttonicon {
    background-color: $colorVeryDarkGray;
    color: $colorWhite;
}

@media (max-width: 767.98px) {

    .floatingbutton,
    .floatingbuttontotop {
        right: 10px;
    }

    .floatingbutton .buttonicon,
    .floatingbuttontotop .buttonicon {
        padding: 8px;
        opacity: 0.8;
    }

}