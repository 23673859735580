@use "sass:math";
@import './colors.scss';

$squarew: 15px;
$squareh: 15px;
$squaremargin: 5px;
$loaderw: $squarew * 3 + $squaremargin *2;
$loaderh: $squareh * 3 + $squaremargin *2;
$delayenter: 0.3s;
$topenter: -10px;

@mixin transition($value) {
  -webkit-transition: $value ;
   -moz-transition: $value ;
          transition: $value ;
}

@mixin delay( $delay ) {
  -webkit-animation-delay: $delay;
   -moz-animation-delay: $delay;
  animation-delay: $delay;
}
@-webkit-keyframes enter{
  0%   { 
    opacity: 0;
    top: $topenter;
  }
  5%   { 
    opacity: 1;
    top: 0px;
  }
  50.9% { 
    opacity: 1;
    top: 0px;
  }
  55.9% { 
    opacity: 0;
    top: (- $topenter);
  }
}
@keyframes enter{
  0%   { 
    opacity: 0;
    top: $topenter;
  }
  5%   { 
    opacity: 1;
    top: 0px;
  }
  50.9% { 
    opacity: 1;
    top: 0px;
  }
  55.9% { 
    opacity: 0;
    top: (- $topenter);
  }
}
@-moz-keyframes enter{
  0%   { 
    opacity: 0;
    top: $topenter;
  }
  5%   { 
    opacity: 1;
    top: 0px;
  }
  50.9% { 
    opacity: 1;
    top: 0px;
  }
  55.9% { 
    opacity: 0;
    top: (- $topenter);
  }
}

#lumisoft-maxi .loaderWrapper {
    position: absolute;
    width: 100%;
    height: 100%;
}

#lumisoft-maxi.light .loaderWrapper {
    background: $colorWhite;
}

#lumisoft-maxi.dark .loaderWrapper {
    background: $colorVeryDarkGray;
}

#lumisoft-maxi .loaderSquare {
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: math.div((-$loaderh), 2);
  margin-top: math.div((-$loaderh), 2);
}

#lumisoft-maxi.light .square {
    background: $colorBlueLogo;
}
#lumisoft-maxi.dark .square {
    background: $colorWhite;
}

#lumisoft-maxi.light .square:nth-child(3){
    background: $colorDarkGrayPlus !important;
  }

#lumisoft-maxi.dark .square:nth-child(3){
    background: $colorGreen !important;
  }

#lumisoft-maxi .square{
  width: $squarew;
  height: $squareh;
  float: left;
  top: $topenter;
  margin-right: $squaremargin;
  margin-top: $squaremargin;
  position:relative;
  opacity: 0;
  -webkit-animation: enter 6s infinite;
  animation: enter 6s infinite;
}
#lumisoft-maxi .enter{
  top: 0px;
  opacity: 1;
}
#lumisoft-maxi .square:nth-child(1){
  @include delay(6 * $delayenter);
}
#lumisoft-maxi .square:nth-child(2){
 @include delay(7 * $delayenter);
}
.square:nth-child(3){
  @include delay(8 * $delayenter);
}
#lumisoft-maxi .square:nth-child(4){
  @include delay(3 * $delayenter);
}
#lumisoft-maxi .square:nth-child(5){
  @include delay(4 * $delayenter);
}
#lumisoft-maxi .square:nth-child(6){
  @include delay(5 * $delayenter);
}
// #lumisoft-maxi .square:nth-child(7){
//   }
  #lumisoft-maxi .square:nth-child(8){
  @include delay(1 * $delayenter);
}
#lumisoft-maxi .square:nth-child(9){
  @include delay(2 * $delayenter);
}
#lumisoft-maxi .clear{
  clear: both;
 }
#lumisoft-maxi .last{
   margin-right:0;
}

