@import '../colors.scss';
@import '../fonts.scss';

// client DETAIlS
#client .clientpagelayout .clientPageWrapper .insidecontentWrapper .mainBodyWrapper .cardWrapper .cardBody.clientdetails .clientDetailsWrapper {
    display: flex;
    justify-content: center;
    margin-bottom: 30px;
}

#client .clientpagelayout .clientPageWrapper .insidecontentWrapper .mainBodyWrapper .cardWrapper .cardBody.clientdetails .profilePictureStyle {
    display: flex;
    cursor: pointer;
}

#client .clientpagelayout .clientPageWrapper .insidecontentWrapper .mainBodyWrapper .cardWrapper .cardBody.clientdetails .profilePictureStyle img {
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    height: 185px;
    width: 185px;
    border-radius: 100%;
}

#client .clientpagelayout .clientPageWrapper .insidecontentWrapper .mainBodyWrapper .cardWrapper .cardBody.clientdetails {
    display: flex;
    justify-content: center;
    width: 100%;
}

#client .clientpagelayout .clientPageWrapper .insidecontentWrapper .mainBodyWrapper .cardWrapper .cardBody .domainInfo {
    width: 100%;
    padding: 20px;
    background-color: $colorDarkGrayPlus;
    display: flex;
    justify-content: center;
}

#client .clientpagelayout .clientPageWrapper .insidecontentWrapper .mainBodyWrapper .cardWrapper .cardBody .infoWrapper {
    margin-bottom: 10px;
}

#client .clientpagelayout .clientPageWrapper .insidecontentWrapper .mainBodyWrapper .cardWrapper .cardBody.clientdetails .infoWrapper {
    display: flex;
    align-content: center;
    margin-bottom: 5px;
}

#client .clientpagelayout .clientPageWrapper .insidecontentWrapper .mainBodyWrapper .cardWrapper .cardBody.clientdetails .infoWrapper span {
    display: flex;
    margin-right: 5px;
}

#client .clientpagelayout .clientPageWrapper .insidecontentWrapper .mainBodyWrapper .cardWrapper .cardBody.clientdetails .infoWrapper span svg {
    display: flex;
    align-self: center;
}

#client .clientpagelayout.light .clientPageWrapper .insidecontentWrapper .mainBodyWrapper .cardWrapper .cardBody.clientdetails .infoWrapper span.iconColor {
    color: $colorBlueLogo;
}

#client .clientpagelayout.dark .clientPageWrapper .insidecontentWrapper .mainBodyWrapper .cardWrapper .cardBody.clientdetails .infoWrapper span.iconColor {
    color: $colorGreen;
}

#client .clientpagelayout.dark .clientPageWrapper .insidecontentWrapper .mainBodyWrapper .cardWrapper .cardBody.clientdetails .buttonWrapper {
    width: 100%;
}

#client .clientpagelayout.dark .clientPageWrapper .insidecontentWrapper .mainBodyWrapper .cardWrapper .cardBody.clientdetails .buttonWrapper .submitButton {
    background: $colorGreen;
    color: $colorWhite;
}

#client .clientpagelayout.light .clientPageWrapper .insidecontentWrapper .mainBodyWrapper .cardWrapper .cardBody.clientdetails .buttonWrapper .submitButton {
    background: $colorBlueLogo;
    color: $colorWhite;
}

#client .clientpagelayout .clientPageWrapper .insidecontentWrapper .mainBodyWrapper .cardWrapper .cardBody.clientdetails .buttonWrapper .submitButton {
    border: none;
    cursor: pointer;
    height: 55px;
    padding: 15.5px 50px 14.5px 50px;
    font-size: 17px;
    font-weight: 400;
    font-family: $montserrat;
    border-radius: 3px;
    width: 100%;
    margin-top: 40px;
}

// INPUT BUTTON
#client .clientpagelayout.dark .clientPageWrapper .insidecontentWrapper .mainBodyWrapper .cardWrapper .cardBody .cardOptions.form .inputbutton.disabled,
#client .clientpagelayout.dark .clientPageWrapper .insidecontentWrapper .mainBodyWrapper .cardWrapper .cardBody.clientdetails .inputbutton.disabled,
#client .clientpagelayout.dark .clientPageWrapper .insidecontentWrapper .mainBodyWrapper .cardWrapper .cardBody .clientdetails .inputbutton.disabled {
    color: $colorGray;
}

#client .clientpagelayout.light .clientPageWrapper .insidecontentWrapper .mainBodyWrapper .cardWrapper .cardBody .cardOptions.form .inputbutton.disabled,
#client .clientpagelayout.light .clientPageWrapper .insidecontentWrapper .mainBodyWrapper .cardWrapper .cardBody.clientdetails .inputbutton.disabled,
#client .clientpagelayout.light .clientPageWrapper .insidecontentWrapper .mainBodyWrapper .cardWrapper .cardBody .clientdetails .inputbutton.disabled {
    color: $colorGray !important;
}

#client .clientpagelayout.dark .clientPageWrapper .insidecontentWrapper .mainBodyWrapper .cardWrapper .cardBody .cardOptions.form .inputbutton,
#client .clientpagelayout.dark .clientPageWrapper .insidecontentWrapper .mainBodyWrapper .cardWrapper .cardBody.clientdetails .inputbutton,
#client .clientpagelayout.dark .clientPageWrapper .insidecontentWrapper .mainBodyWrapper .cardWrapper .cardBody .clientdetails .inputbutton {
    color: $colorWhite;
    background-color: $colorVeryDarkGray;
    border: 1px solid $colorDarkGrayPlus;
}

#client .clientpagelayout.light .clientPageWrapper .insidecontentWrapper .mainBodyWrapper .cardWrapper .cardBody .cardOptions.form .inputbutton,
#client .clientpagelayout.light .clientPageWrapper .insidecontentWrapper .mainBodyWrapper .cardWrapper .cardBody.clientdetails .inputbutton,
#client .clientpagelayout.light .clientPageWrapper .insidecontentWrapper .mainBodyWrapper .cardWrapper .cardBody .clientdetails .inputbutton {
    color: $colorVeryDark;
    background-color: $colorWhite;
    border: 1px solid $colorVeryLightGray;
}

#client .clientpagelayout .clientPageWrapper .insidecontentWrapper .mainBodyWrapper .cardWrapper .cardBody .cardOptions.form .inputbutton:hover {
    cursor: pointer;
}

#client .clientpagelayout.dark .clientPageWrapper .insidecontentWrapper .mainBodyWrapper .cardWrapper .cardBody .cardOptions.form .inputbutton:hover,
#client .clientpagelayout.dark .clientPageWrapper .insidecontentWrapper .mainBodyWrapper .cardWrapper .cardBody .cardOptions.form .inputbutton:focus,
#client .clientpagelayout.dark .clientPageWrapper .insidecontentWrapper .mainBodyWrapper .cardWrapper .cardBody.clientdetails .inputbutton:hover,
#client .clientpagelayout.dark .clientPageWrapper .insidecontentWrapper .mainBodyWrapper .cardWrapper .cardBody.clientdetails .inputbutton:focus,
#client .clientpagelayout.dark .clientPageWrapper .insidecontentWrapper .mainBodyWrapper .cardWrapper .cardBody .clientdetails .inputbutton:hover,
#client .clientpagelayout.dark .clientPageWrapper .insidecontentWrapper .mainBodyWrapper .cardWrapper .cardBody .clientdetails .inputbutton:focus {
    border-color: $colorDarkGray !important;
}

#client .clientpagelayout.light .clientPageWrapper .insidecontentWrapper .mainBodyWrapper .cardWrapper .cardBody .cardOptions.form .inputbutton:hover,
#client .clientpagelayout.light .clientPageWrapper .insidecontentWrapper .mainBodyWrapper .cardWrapper .cardBody .cardOptions.form .inputbutton:focus,
#client .clientpagelayout.light .clientPageWrapper .insidecontentWrapper .mainBodyWrapper .cardWrapper .cardBody.clientdetails .inputbutton:hover,
#client .clientpagelayout.light .clientPageWrapper .insidecontentWrapper .mainBodyWrapper .cardWrapper .cardBody.clientdetails .inputbutton:focus,
#client .clientpagelayout.light .clientPageWrapper .insidecontentWrapper .mainBodyWrapper .cardWrapper .cardBody .clientdetails .inputbutton:hover,
#client .clientpagelayout.light .clientPageWrapper .insidecontentWrapper .mainBodyWrapper .cardWrapper .cardBody .clientdetails .inputbutton:focus {
    border-color: $colorLightGray !important;
}

#client .clientpagelayout .clientPageWrapper .insidecontentWrapper .mainBodyWrapper .cardWrapper .cardBody .cardOptions.form .inputbutton,
#client .clientpagelayout .clientPageWrapper .insidecontentWrapper .mainBodyWrapper .cardWrapper .cardBody.clientdetails .inputbutton,
#client .clientpagelayout .clientPageWrapper .insidecontentWrapper .mainBodyWrapper .cardWrapper .cardBody .clientdetails .inputbutton {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    margin: 0;
    list-style: none;
    position: relative;
    display: flex;
    align-items: center;
    padding: 7px 13px 7px 30px;
    width: 100%;
    height: 44px;
    font-size: 15px;
    line-height: 1.5;
    background-image: none;
    border-radius: 8px;
    font-family: $roboto;
    // -webkit-transition: all .3s;
    // -o-transition: all .3s;
    // transition: all .3s;
    cursor: text;
}

#client .clientpagelayout .clientPageWrapper .insidecontentWrapper .mainBodyWrapper .cardWrapper .cardBody.clientdetails input.inputbutton::-webkit-input-placeholder,
#client .clientpagelayout .clientPageWrapper .insidecontentWrapper .mainBodyWrapper .cardWrapper .cardBody.clientdetails input.inputbutton:-ms-input-placeholder,
#client .clientpagelayout .clientPageWrapper .insidecontentWrapper .mainBodyWrapper .cardWrapper .cardBody.clientdetails input.inputbutton::-ms-input-placeholder,
#client .clientpagelayout .clientPageWrapper .insidecontentWrapper .mainBodyWrapper .cardWrapper .cardBody.clientdetails input.inputbutton:-moz-placeholder,
#client .clientpagelayout .clientPageWrapper .insidecontentWrapper .mainBodyWrapper .cardWrapper .cardBody.clientdetails input.inputbutton::-moz-placeholder,
#client .clientpagelayout .clientPageWrapper .insidecontentWrapper .mainBodyWrapper .cardWrapper .cardBody.clientdetails input.inputbutton::placeholder,
#client .clientpagelayout .clientPageWrapper .insidecontentWrapper .mainBodyWrapper .cardWrapper .cardBody .clientdetails input.inputbutton::-webkit-input-placeholder,
#client .clientpagelayout .clientPageWrapper .insidecontentWrapper .mainBodyWrapper .cardWrapper .cardBody .clientdetails input.inputbutton:-ms-input-placeholder,
#client .clientpagelayout .clientPageWrapper .insidecontentWrapper .mainBodyWrapper .cardWrapper .cardBody .clientdetails input.inputbutton::-ms-input-placeholder,
#client .clientpagelayout .clientPageWrapper .insidecontentWrapper .mainBodyWrapper .cardWrapper .cardBody .clientdetails input.inputbutton:-moz-placeholder,
#client .clientpagelayout .clientPageWrapper .insidecontentWrapper .mainBodyWrapper .cardWrapper .cardBody .clientdetails input.inputbutton::-moz-placeholder,
#client .clientpagelayout .clientPageWrapper .insidecontentWrapper .mainBodyWrapper .cardWrapper .cardBody .clientdetails input.inputbutton::placeholder,
#client .clientpagelayout .clientPageWrapper .insidecontentWrapper .mainBodyWrapper .cardWrapper .cardBody .cardOptions.form input.inputbutton::-webkit-input-placeholder,
#client .clientpagelayout .clientPageWrapper .insidecontentWrapper .mainBodyWrapper .cardWrapper .cardBody .cardOptions.form input.inputbutton:-ms-input-placeholder,
#client .clientpagelayout .clientPageWrapper .insidecontentWrapper .mainBodyWrapper .cardWrapper .cardBody .cardOptions.form input.inputbutton::-ms-input-placeholder,
#client .clientpagelayout .clientPageWrapper .insidecontentWrapper .mainBodyWrapper .cardWrapper .cardBody .cardOptions.form input.inputbutton:-moz-placeholder,
#client .clientpagelayout .clientPageWrapper .insidecontentWrapper .mainBodyWrapper .cardWrapper .cardBody .cardOptions.form input.inputbutton::-moz-placeholder,
#client .clientpagelayout .clientPageWrapper .insidecontentWrapper .mainBodyWrapper .cardWrapper .cardBody .cardOptions.form input.inputbutton::placeholder {
    font-family: $roboto;
}

#client .clientpagelayout .clientPageWrapper .insidecontentWrapper .mainBodyWrapper .cardWrapper .cardBody .cardOptions.form .selectIcon,
#client .clientpagelayout .clientPageWrapper .insidecontentWrapper .mainBodyWrapper .cardWrapper .cardBody.clientdetails .selectIcon,
#client .clientpagelayout .clientPageWrapper .insidecontentWrapper .mainBodyWrapper .cardWrapper .cardBody .clientdetails .selectIcon {
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    line-height: 0;
    color: $colorDarkGray;
    z-index: 3;
    margin-left: 35px;
    cursor: pointer;
}

#client .clientpagelayout.dark .clientPageWrapper .insidecontentWrapper .mainBodyWrapper .cardWrapper .cardBody .cardOptions.form .selectIcon:hover,
#client .clientpagelayout.dark .clientPageWrapper .insidecontentWrapper .mainBodyWrapper .cardWrapper .cardBody.clientdetails .selectIcon:hover {
    color: $colorGreen;
}

#client .clientpagelayout.light .clientPageWrapper .insidecontentWrapper .mainBodyWrapper .cardWrapper .cardBody .cardOptions.form .selectIcon:hover,
#client .clientpagelayout.light .clientPageWrapper .insidecontentWrapper .mainBodyWrapper .cardWrapper .cardBody.clientdetails .selectIcon:hover {
    color: $colorBlueLogo;
}

#client .clientpagelayout .clientPageWrapper .insidecontentWrapper .mainBodyWrapper .cardWrapper .cardBody .cardOptions.form .inputbuttonForSelect,
#client .clientpagelayout .clientPageWrapper .insidecontentWrapper .mainBodyWrapper .cardWrapper .cardBody.clientdetails .inputbuttonForSelect {
    padding: 7px 13px 7px 70px;
}

#client .clientpagelayout .clientPageWrapper .insidecontentWrapper .mainBodyWrapper .cardWrapper .cardHeader .cardOptions ul.dropdownmenu,
#client .clientpagelayout .clientPageWrapper .insidecontentWrapper .mainBodyWrapper .cardWrapper .cardBody .cardOptions ul.dropdownmenu {
    flex-direction: column !important;
}

#client .clientpagelayout .clientPageWrapper .insidecontentWrapper .mainBodyWrapper .cardWrapper .cardBody .cardOptions.form .dropdownmenu,
#client .clientpagelayout .clientPageWrapper .insidecontentWrapper .mainBodyWrapper .cardWrapper .cardBody.clientdetails .dropdownmenu,
#client .clientpagelayout .clientPageWrapper .insidecontentWrapper .mainBodyWrapper .cardWrapper .cardBody .clientdetails .dropdownmenu,
#client .clientpagelayout .clientPageWrapper .insidecontentWrapper .mainBodyWrapper .cardWrapper .cardHeader .dropdownmenu,
#client .clientpagelayout .clientPageWrapper .insidecontentWrapper .mainBodyWrapper .cardWrapper .cardBody .dropdownmenu {
    position: absolute;
    width: 100%;
    // height: 100%;
    // max-width: 290px;
    top: 98%;
    left: 0;
    z-index: 900;
    float: left;
    padding: 0;
    // margin: 0 15px;
    list-style: none;
    font-size: 13px;
    text-align: left;
    background-color: $colorWhite;
    border-radius: 0;
    // -webkit-box-shadow: 0 1px 1px 1px rgba(255,255,255,.2);
    // box-shadow: 0 1px 1px 1px rgba(255,255,255,.2);
    background-clip: padding-box;
    // min-height: 63px;
    max-height: 250px;
    overflow-y: scroll;
    scrollbar-width: thin;
}

#client .clientpagelayout .clientPageWrapper .insidecontentWrapper .mainBodyWrapper .cardWrapper .cardBody.clientdetails .dropdownmenu.notboolean,
#client .clientpagelayout .clientPageWrapper .insidecontentWrapper .mainBodyWrapper .cardWrapper .cardBody .clientdetails .dropdownmenu.notboolean,
#client .clientpagelayout .clientPageWrapper .insidecontentWrapper .mainBodyWrapper .cardWrapper .cardHeader .dropdownmenu.notboolean,
#client .clientpagelayout .clientPageWrapper .insidecontentWrapper .mainBodyWrapper .cardWrapper .cardBody .dropdownmenu.notboolean {
    min-height: 170px;
    max-height: 250px;
}

#client .clientpagelayout.dark .clientPageWrapper .insidecontentWrapper .mainBodyWrapper .cardWrapper .cardBody.clientdetails .dropdownmenu,
#client .clientpagelayout.dark .clientPageWrapper .insidecontentWrapper .mainBodyWrapper .cardWrapper .cardBody .clientdetails .dropdownmenu,
#client .clientpagelayout.dark .clientPageWrapper .insidecontentWrapper .mainBodyWrapper .cardWrapper .cardHeader .dropdownmenu,
#client .clientpagelayout.dark .clientPageWrapper .insidecontentWrapper .mainBodyWrapper .cardWrapper .cardBody .dropdownmenu {
    border: 1px solid $colorDarkGrayPlus;
    border-top: 0;
}

#client .clientpagelayout.light .clientPageWrapper .insidecontentWrapper .mainBodyWrapper .cardWrapper .cardBody.clientdetails .dropdownmenu,
#client .clientpagelayout.light .clientPageWrapper .insidecontentWrapper .mainBodyWrapper .cardWrapper .cardBody .clientdetails .dropdownmenu,
#client .clientpagelayout.light .clientPageWrapper .insidecontentWrapper .mainBodyWrapper .cardWrapper .cardHeader .dropdownmenu,
#client .clientpagelayout.light .clientPageWrapper .insidecontentWrapper .mainBodyWrapper .cardWrapper .cardBody .dropdownmenu {
    border: 1px solid $colorVeryLightGray;
    border-top: 0;
}

#client .clientpagelayout .clientPageWrapper .insidecontentWrapper .mainBodyWrapper .cardWrapper .cardBody.clientdetails .dropdownmenu>li>a,
#client .clientpagelayout .clientPageWrapper .insidecontentWrapper .mainBodyWrapper .cardWrapper .cardBody .clientdetails .dropdownmenu>li>a,
#client .clientpagelayout .clientPageWrapper .insidecontentWrapper .mainBodyWrapper .cardWrapper .cardHeader .dropdownmenu>li>a,
#client .clientpagelayout .clientPageWrapper .insidecontentWrapper .mainBodyWrapper .cardWrapper .cardHeader .dropdownmenu>li>span,
#client .clientpagelayout .clientPageWrapper .insidecontentWrapper .mainBodyWrapper .cardWrapper .cardBody .dropdownmenu>li>a,
#client .clientpagelayout .clientPageWrapper .insidecontentWrapper .mainBodyWrapper .cardWrapper .cardBody .dropdownmenu>li>span {
    display: block;
    padding: 5px 18px 5px 5px;
    clear: both;
    font-weight: normal;
    line-height: 1.49;
    white-space: nowrap;
}

#client .clientpagelayout .clientPageWrapper .insidecontentWrapper .mainBodyWrapper .cardWrapper .cardBody.clientdetails .dropdownmenu .divider,
#client .clientpagelayout .clientPageWrapper .insidecontentWrapper .mainBodyWrapper .cardWrapper .cardBody .clientdetails .dropdownmenu .divider,
#client .clientpagelayout .clientPageWrapper .insidecontentWrapper .mainBodyWrapper .cardWrapper .cardHeader .dropdownmenu .divider,
#client .clientpagelayout .clientPageWrapper .insidecontentWrapper .mainBodyWrapper .cardWrapper .cardBody .dropdownmenu .divider {
    height: 1px;
    margin: 9px 18px;
    overflow: hidden;
}

#client .clientpagelayout .clientPageWrapper .insidecontentWrapper .mainBodyWrapper .cardWrapper .cardBody.clientdetails .dropdownmenu .listgroupitem:first-child,
#client .clientpagelayout .clientPageWrapper .insidecontentWrapper .mainBodyWrapper .cardWrapper .cardBody .clientdetails .dropdownmenu .listgroupitem:first-child,
#client .clientpagelayout .clientPageWrapper .insidecontentWrapper .mainBodyWrapper .cardWrapper .cardHeader .dropdownmenu .listgroupitem:first-child,
#client .clientpagelayout .clientPageWrapper .insidecontentWrapper .mainBodyWrapper .cardWrapper .cardBody .dropdownmenu .listgroupitem:first-child {
    border-top-right-radius: 2px;
    border-top-left-radius: 2px;
    border-top: 0;
}

#client .clientpagelayout.dark .clientPageWrapper .insidecontentWrapper .mainBodyWrapper .cardWrapper .cardBody.clientdetails .dropdownmenu .listgroupitem,
#client .clientpagelayout.dark .clientPageWrapper .insidecontentWrapper .mainBodyWrapper .cardWrapper .cardBody .clientdetails .dropdownmenu .listgroupitem,
#client .clientpagelayout.dark .clientPageWrapper .insidecontentWrapper .mainBodyWrapper .cardWrapper .cardHeader .dropdownmenu .listgroupitem,
#client .clientpagelayout.dark .clientPageWrapper .insidecontentWrapper .mainBodyWrapper .cardWrapper .cardBody .dropdownmenu .listgroupitem {
    background-color: $colorVeryDarkGray;
    border-top: 1px solid $colorDarkGrayPlus;
    border-bottom: 1px solid $colorDarkGrayPlus;
    border-right: 1px solid $colorDarkGrayPlus;
    color: $colorWhite;
}

#client .clientpagelayout.light .clientPageWrapper .insidecontentWrapper .mainBodyWrapper .cardWrapper .cardBody.clientdetails .dropdownmenu .listgroupitem,
#client .clientpagelayout.light .clientPageWrapper .insidecontentWrapper .mainBodyWrapper .cardWrapper .cardBody .clientdetails .dropdownmenu .listgroupitem,
#client .clientpagelayout.light .clientPageWrapper .insidecontentWrapper .mainBodyWrapper .cardWrapper .cardHeader .dropdownmenu .listgroupitem,
#client .clientpagelayout.light .clientPageWrapper .insidecontentWrapper .mainBodyWrapper .cardWrapper .cardBody .dropdownmenu .listgroupitem {
    background-color: $colorWhite;
    border-top: 1px solid $colorVeryLightGray;
    border-bottom: 1px solid $colorVeryLightGray;
    border-right: 1px solid $colorVeryLightGray;
    color: $colorVeryDark;
}

#client .clientpagelayout .clientPageWrapper .insidecontentWrapper .mainBodyWrapper .cardWrapper .cardBody.clientdetails .dropdownmenu .listgroupitem,
#client .clientpagelayout .clientPageWrapper .insidecontentWrapper .mainBodyWrapper .cardWrapper .cardBody .clientdetails .dropdownmenu .listgroupitem,
#client .clientpagelayout .clientPageWrapper .insidecontentWrapper .mainBodyWrapper .cardWrapper .cardHeader .dropdownmenu .listgroupitem,
#client .clientpagelayout .clientPageWrapper .insidecontentWrapper .mainBodyWrapper .cardWrapper .cardBody .dropdownmenu .listgroupitem {
    position: relative;
    display: block;
    padding: 7px 15px;
    margin-bottom: -1px;
    border-left: 0;
    cursor: pointer;
}

#client .clientpagelayout.dark .clientPageWrapper .insidecontentWrapper .mainBodyWrapper .cardWrapper .cardBody.clientdetails .dropdownmenu .listgroupitem:hover,
#client .clientpagelayout.dark .clientPageWrapper .insidecontentWrapper .mainBodyWrapper .cardWrapper .cardBody .clientdetails .dropdownmenu .listgroupitem:hover,
#client .clientpagelayout.dark .clientPageWrapper .insidecontentWrapper .mainBodyWrapper .cardWrapper .cardHeader .dropdownmenu .listgroupitem:hover,
#client .clientpagelayout.dark .clientPageWrapper .insidecontentWrapper .mainBodyWrapper .cardWrapper .cardBody .dropdownmenu .listgroupitem:hover {
    background-color: $colorGreen;
}

#client .clientpagelayout.light .clientPageWrapper .insidecontentWrapper .mainBodyWrapper .cardWrapper .cardBody.clientdetails .dropdownmenu .listgroupitem:hover,
#client .clientpagelayout.light .clientPageWrapper .insidecontentWrapper .mainBodyWrapper .cardWrapper .cardBody .clientdetails .dropdownmenu .listgroupitem:hover,
#client .clientpagelayout.light .clientPageWrapper .insidecontentWrapper .mainBodyWrapper .cardWrapper .cardHeader .dropdownmenu .listgroupitem:hover,
#client .clientpagelayout.light .clientPageWrapper .insidecontentWrapper .mainBodyWrapper .cardWrapper .cardBody .dropdownmenu .listgroupitem:hover {
    background-color: $colorBlueLogo;
    color: $colorWhite;
}

// DATE PICKER
#client .clientpagelayout.dark .rdt .rdtPicker {
    background-color: $colorVeryDarkGray;
    border: 1px solid $colorDarkGrayPlus;
}

#client .clientpagelayout.dark .rdt .rdtPicker .rdtYears .rdtDisabled,
#client .clientpagelayout.dark .rdt .rdtPicker .rdtMonths .rdtDisabled,
#client .clientpagelayout.dark .rdt .rdtPicker .rdtDays .rdtDisabled {
    color: $colorDarkGrayPlus
}

#client .clientpagelayout.dark .rdt .rdtPicker .rdtYears,
#client .clientpagelayout.dark .rdt .rdtPicker .rdtMonths,
#client .clientpagelayout.dark .rdt .rdtPicker .rdtDays {
    color: $colorWhite
}

#client .clientpagelayout.dark .rdt .rdtPicker .rdtDays .rdtActive,
#client .clientpagelayout.dark .rdt .rdtPicker .rdtDays .rdtDay:hover {
    background-color: $colorGreen;
    color: $colorWhite;
}

#client .clientpagelayout.dark .rdt .rdtPicker .rdtDays .rdtNew {
    color: $colorDarkGray
}

#client .clientpagelayout.dark .rdt .rdtPicker .rdtDays .rdtOld {
    color: $colorDarkGray
}

#client .clientpagelayout.dark .rdt .rdtPicker .rdtDays .rdtToday,
#client .clientpagelayout.dark .rdt .rdtPicker .rdtYears .rdtSwitch,
#client .clientpagelayout.dark .rdt .rdtPicker .rdtYears .rdtPrev,
#client .clientpagelayout.dark .rdt .rdtPicker .rdtYears .rdtNext,
#client .clientpagelayout.dark .rdt .rdtPicker .rdtMonths .rdtSwitch,
#client .clientpagelayout.dark .rdt .rdtPicker .rdtMonths .rdtPrev,
#client .clientpagelayout.dark .rdt .rdtPicker .rdtMonths .rdtNext,
#client .clientpagelayout.dark .rdt .rdtPicker .rdtDays thead tr th {
    color: $colorGreen
}

#client .clientpagelayout.light .rdt .rdtPicker {
    background-color: $colorWhite;
    border: 1px solid $colorVeryLightGray;
}

#client .clientpagelayout.light .rdt .rdtPicker .rdtYears .rdtDisabled,
#client .clientpagelayout.light .rdt .rdtPicker .rdtMonths .rdtDisabled,
#client .clientpagelayout.light .rdt .rdtPicker .rdtDays .rdtDisabled {
    color: $colorVeryLightGray
}

#client .clientpagelayout.light .rdt .rdtPicker .rdtYears,
#client .clientpagelayout.light .rdt .rdtPicker .rdtMonths,
#client .clientpagelayout.light .rdt .rdtPicker .rdtDays {
    color: $colorVeryDark
}

#client .clientpagelayout.light .rdt .rdtPicker .rdtDays .rdtActive,
#client .clientpagelayout.light .rdt .rdtPicker .rdtDays .rdtDay:hover {
    background-color: $colorBlueLogo;
    color: $colorWhite;
}

#client .clientpagelayout.light .rdt .rdtPicker .rdtDays .rdtNew {
    color: $colorGray
}

#client .clientpagelayout.light .rdt .rdtPicker .rdtDays .rdtOld {
    color: $colorGray
}

#client .clientpagelayout.light .rdt .rdtPicker .rdtDays .rdtToday,
#client .clientpagelayout.light .rdt .rdtPicker .rdtYears .rdtSwitch,
#client .clientpagelayout.light .rdt .rdtPicker .rdtYears .rdtPrev,
#client .clientpagelayout.light .rdt .rdtPicker .rdtYears .rdtNext,
#client .clientpagelayout.light .rdt .rdtPicker .rdtMonths .rdtSwitch,
#client .clientpagelayout.light .rdt .rdtPicker .rdtMonths .rdtPrev,
#client .clientpagelayout.light .rdt .rdtPicker .rdtMonths .rdtNext,
#client .clientpagelayout.light .rdt .rdtPicker .rdtDays thead tr th {
    color: $colorBlueLogo
}

#client .clientpagelayout .rdt .rdtPicker .rdtDays thead tr th.rdtSwitch,
#client .clientpagelayout .rdt .rdtPicker .rdtMonths thead tr th.rdtSwitch,
#client .clientpagelayout .rdt .rdtPicker .rdtYears thead tr th.rdtSwitch {
    padding-top: 5px;
}

#client .clientpagelayout .rdt .rdtPicker td,
#client .clientpagelayout .rdt .rdtPicker th {
    vertical-align: middle;
}

#client .clientpagelayout .rdt .rdtPicker .rdtMonths tbody tr td.rdtMonth,
#client .clientpagelayout .rdt .rdtPicker .rdtYears tbody tr td.rdtYear {
    justify-content: center;
    align-items: center;
    display: inline-flex;
}

@media (max-width: 767.98px) {
    #client .clientpagelayout .clientPageWrapper .insidecontentWrapper .mainBodyWrapper .cardWrapper .cardBody.clientdetails {
        margin-left: 0;
    }
}