@import './colors.scss';
@import './fonts.scss';

/***********************************************
                PAGINATION
***********************************************/
#lumisoft-maxi .pagination {
    display: -ms-flexbox;
    display: flex;
    padding-left: 0;
    list-style: none;
    border-radius: .25rem;
    // position: absolute;
    // left: 0;
    // right: 0;
    // bottom: 0;
    margin-bottom: 20px;
    justify-content: center;
}

#lumisoft-maxi .pagination .pageItem {
    height: 38px;
    width: 38px;
    display: flex;
    cursor: pointer;
    border-radius: 8px;
    justify-content: center;
    align-items: center;
}

#lumisoft-maxi .pagination .pageItem.active .pageLink,
#lumisoft-maxi .pagination .pageItem.disabled .pageLink {
    vertical-align: middle;
    height: 18px;
    width: 18px;
    margin: 12px;
    display: inline-block;
    display: flex;
    justify-content: center;
    align-items: center;
}

#lumisoft-maxi .pagination .pageItem:not(:last-child) {
    margin-right: 10px;
}

// DARK
#lumisoft-maxi.dark .pagination .pageItem.disabled {
    color: $colorDarkGrayPlus;
    background-color: $colorVeryDark;
    border-color: $colorDarkGrayPlus;
}

#lumisoft-maxi.dark .pagination .pageItem {
    color: $colorWhite;
    border: 1px solid $colorDarkGrayPlus;
}

#lumisoft-maxi.dark .pagination .pageItem:hover {
    border: 1px solid $colorGreen;
}

#lumisoft-maxi.dark .pagination .pageItem.active {
    color: $colorWhite;
    border-color: $colorGreen;
}

// LIGHT
#lumisoft-maxi.light .pagination .pageItem.disabled {
    color: $colorLightGray !important;
    background-color: $colorWhite;
    border-color: $colorLightGray;
}

#lumisoft-maxi.light .pagination .pageItem {
    color: $colorVeryDark;
    border: 1px solid $colorLightGray;
}

#lumisoft-maxi.light .pagination .pageItem:hover {
    border: 1px solid $colorBlueLogo;
}

#lumisoft-maxi.light .pagination .pageItem.active {
    color: $colorVeryDark;
    border-color: $colorBlueLogo;
}