@import '../colors.scss';
@import '../fonts.scss';

#client .clientbackdrop {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.5);
    z-index: 940;
    cursor: pointer;
}

#client .clientpagelayout.light,
#client .clientpagelayout.light .clienthome {
    background-color: $colorWhiteBackground;
    color: $colorVeryDarkGray;
}

#client .clientpagelayout.dark,
#client .clientpagelayout.dark .clienthome {
    background-color: $colorVeryDarkGray;
    color: $colorWhite;
}

#client .clientpagelayout {
    display: flex;
    flex-direction: row;
    scrollbar-width: thin;
}

#client .clientpagelayout .clientSideWrapper {
    width: 245px;
    // box-shadow: 0 1px 2px hsla(0,0%,100%,.2);
    z-index: 45;
    transform: translateZ(0);
    white-space: nowrap;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    transition: .3s ease;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    will-change: transform;
}

#client .clientpagelayout.light .clientSideWrapper .navContainer,
#client .clientpagelayout.light .clientSideWrapper .subNavContainer {
    // box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.1);
    border: 1px solid $colorVeryLightGray;
    background: $colorWhite;
}

#client .clientpagelayout.dark .clientSideWrapper .navContainer,
#client .clientpagelayout.dark .clientSideWrapper .subNavContainer {
    // box-shadow: 2px 2px 2px rgba(255, 255, 255, .03);
    border: 1px solid $colorDarkGrayPlus;
    background: $colorVeryDark;
}

#client .clientpagelayout .clientSideWrapper .navContainer {
    border-radius: 10px;
    border: initial;
    margin: 30px 10px 30px 30px;
    padding: 10px 0;
    // padding: 15px 20px;
    // width: 100%;
}

#client .clientpagelayout .clientSideWrapper .subNavContainer {
    border-radius: 10px;
    border: initial;
    margin: 5px 10px 30px 30px;
    padding: 10px 0;
}

#client .clientpagelayout .clientSideWrapper .navContainer li,
#client .clientpagelayout .clientSideWrapper .subNavContainer li {
    padding: 10px 20px;
    font-family: $montserrat;
    font-weight: 300;
    display: flex;
    align-items: center;
    cursor: pointer;
    transition: all 0.5s ease;
}

#client .clientpagelayout.light .clientSideWrapper .navContainer li:hover,
#client .clientpagelayout.light .clientSideWrapper .subNavContainer li:hover {
    color: $colorBlueLogo;
}

#client .clientpagelayout.dark .clientSideWrapper .navContainer li:hover,
#client .clientpagelayout.dark .clientSideWrapper .subNavContainer li:hover {
    color: $colorGreen;
}

#client .clientpagelayout.light .clientSideWrapper .navContainer li.active,
#client .clientpagelayout.light .clientSideWrapper .subNavContainer li.active {
    color: $colorBlueLogo;
}

#client .clientpagelayout.dark .clientSideWrapper .navContainer li.active,
#client .clientpagelayout.dark .clientSideWrapper .subNavContainer li.active {
    color: $colorGreen;
}

#client .clientpagelayout .clientSideWrapper .navContainer li.active,
#client .clientpagelayout .clientSideWrapper .subNavContainer li.active {
    font-weight: 500;
}

#client .clientpagelayout .clientSideWrapper .navContainer li span,
#client .clientpagelayout .clientSideWrapper .subNavContainer li span {
    display: flex;
    align-items: center;
}

#client .clientpagelayout .clientSideWrapper .navContainer li span:first-child,
#client .clientpagelayout .clientSideWrapper .subNavContainer li span:first-child {
    margin-right: 10px;
}

// MAIN PAGE STYLE

#client .clientpagelayout .clientPageWrapper {
    box-sizing: border-box;
    display: flex;
    position: relative;
    overflow: hidden;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    width: 100%;
}

// #client .clientpagelayout .clientPageWrapper .mainpageWrapper {
//     padding: 30px;
// }

#client .clientpagelayout .clientPageWrapper .insidenavWrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
#client .clientpagelayout .clientPageWrapper .insidenavWrapper .insidenavTitle h4 {
    font-size: 20px;
    display: flex;
}
#client .clientpagelayout .clientPageWrapper .insidenavWrapper ul {
    display: flex;
    flex-direction: row;
    align-items: center;
}

#client .clientpagelayout .clientPageWrapper .insidenavWrapper li {
    font-size: 25px;
    margin-right: 20px;
    cursor: pointer;
    border-radius: 10px;
    display: flex;
    flex-direction: row;
    transition: all 0.5s ease;
}
#client .clientpagelayout .clientPageWrapper .insidenavWrapper li span {
    display: flex;
    align-items: center;
}
#client .clientpagelayout .clientPageWrapper .insidenavWrapper li span svg {
    vertical-align: middle;
    height: 18px;
    width: 18px;
    margin: 12px;
}
#client .clientpagelayout.light .clientPageWrapper .insidenavWrapper li {
    background-color: $colorWhite;
    border: 1px solid $colorVeryLightGray;
}
#client .clientpagelayout.dark .clientPageWrapper .insidenavWrapper li {
    background-color: $colorVeryDark;
    border: 1px solid $colorDarkGrayPlus;
    // box-shadow: 2px 2px 2px rgba(255, 255, 255, 0.03);
}
#client .clientpagelayout.light .clientPageWrapper .insidenavWrapper li:hover {
    border: 1px solid $colorBlueLogo;
}
#client .clientpagelayout.dark .clientPageWrapper .insidenavWrapper li:hover {
    border: 1px solid $colorGreen;
}
#client .clientpagelayout.light .clientPageWrapper .insidenavWrapper li.active {
    color: $colorBlueLogo;
    border: 1px solid $colorBlueLogo;
}
#client .clientpagelayout.dark .clientPageWrapper .insidenavWrapper li.active {
    color: $colorGreen;
    border: 1px solid $colorGreen;
}

#client .clientpagelayout .clientPageWrapper .insidenavWrapper li span.navTitle {
    font-size: 15px;
    padding: 0 10px 0 0;
}

// #client .clientpagelayout .clientPageWrapper .insidecontentWrapper {
    // padding-top: 20px;
    // padding-right: 20px;
// }

#client .clientpagelayout .clientPageWrapper .insidecontentWrapper.dashboard {
    padding-top: 0 !important;
}

@media (max-width: 767.98px) {
    #client .clientpagelayout .clientPageWrapper .insidecontentWrapper {
        padding-right: 0;
    }
    #client .clientpagelayout {
        display: flex;
        flex-direction: column;
    }
    #client .clientpagelayout .clientSideWrapper .navContainer,
    #client .clientpagelayout .clientSideWrapper .subNavContainer {
        display: none;
    }
    #client .clientpagelayout .clientPageWrapper .mainpageWrapper {
        padding: 10px;
    }
    #client .clientpagelayout .clientPageWrapper .insidenavWrapper {
        flex-direction: column;
    }
    #client .clientpagelayout .clientPageWrapper .insidenavWrapper .insidenavTitle {
        padding: 20px 0;
    }
    #client .clientpagelayout .clientPageWrapper .insidenavWrapper li:last-child {
        margin: 0;
    }
    #client .clientpagelayout .clientPageWrapper .insidenavWrapper li span.navTitle {
        display: none;
    }
}

// #client .clientpagelayout .clientSideWrapper .navContainer {
//     position: relative;
//     overflow: hidden;
//     -webkit-box-flex: 1;
//     flex: 1 1;
//     -ms-touch-action: auto;
//     touch-action: auto;
//     overflow-y: auto !important;
//     -ms-overflow-style: none;
//     scrollbar-width: thin !important;
// }