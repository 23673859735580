.lumi-md-1 {
    width: 8.3333333%;
}
.lumi-md-2 {
    width: 16.666666667%;
}
.lumi-md-3 {
    width: 25%;
}
.lumi-md-4 {
    width: 33.3333333%;
}
.lumi-md-5 {
    width: 41.6666667%;
}
.lumi-md-6 {
    width: 50%;
}
.lumi-md-7 {
    width: 58.3333333%;
}
.lumi-md-8 {
    width: 66.6666666%;
}
.lumi-md-9 {
    width: 75%;
}
.lumi-md-10 {
    width: 83.3333333%;
}
.lumi-md-12 {
    width: 100%;
}

.lumi-md-1, .lumi-md-2, .lumi-md-3, .lumi-md-4, .lumi-md-5, .lumi-md-6, .lumi-md-7, .lumi-md-8, .lumi-md-9, .lumi-md-10, .lumi-md-11, .lumi-md-12 {
    float: left;
}
.lumi-xs-1, .lumi-sm-1, .lumi-md-1, .lumi-lg-1, .lumi-xlg-1,
.lumi-xs-2, .lumi-sm-2, .lumi-md-2, .lumi-lg-2, .lumi-xlg-2,
.lumi-xs-3, .lumi-sm-3, .lumi-md-3, .lumi-lg-3, .lumi-xlg-3,
.lumi-xs-4, .lumi-sm-4, .lumi-md-4, .lumi-lg-4, .lumi-xlg-4,
.lumi-xs-5, .lumi-sm-5, .lumi-md-5, .lumi-lg-5, .lumi-xlg-5,
.lumi-xs-6, .lumi-sm-6, .lumi-md-6, .lumi-lg-6, .lumi-xlg-6,
.lumi-xs-7, .lumi-sm-7, .lumi-md-7, .lumi-lg-7, .lumi-xlg-7,
.lumi-xs-8, .lumi-sm-8, .lumi-md-8, .lumi-lg-8, .lumi-xlg-8,
.lumi-xs-9, .lumi-sm-9, .lumi-md-9, .lumi-lg-9, .lumi-xlg-9,
.lumi-xs-10, .lumi-sm-10, .lumi-md-10, .lumi-lg-10, .lumi-xlg-10, 
.lumi-xs-11, .lumi-sm-11, .lumi-md-11, .lumi-lg-11, .lumi-xlg-11,
.lumi-xs-12, .lumi-sm-12, .lumi-md-12, .lumi-lg-12, .lumi-xlg-12
{
    position: relative;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
}

.container {
    width:1140px;
    margin: 0 auto;
}
.containerfluid{
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}

.row {
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
}

.alignitemscenter {
    align-items: center !important;
}

@media (max-width: 767.98px) {
    .containerfluid {
        padding-left: 15px;
        padding-right: 15px;
    }
    .hiddenXs {
        display: none !important;
    }
    .container {
        width: 340px !important;
    }
    .lumi-xs-1 {
        width: 8.3333333%;
    }
    .lumi-xs-2 {
        width: 16.666666667%;
    }
    .lumi-xs-3 {
        width: 25%;
    }
    .lumi-xs-4 {
        width: 33.3333333%;
    }
    .lumi-xs-5 {
        width: 41.6666667%;
    }
    .lumi-xs-6 {
        width: 50%;
    }
    .lumi-xs-7 {
        width: 58.3333333%;
    }
    .lumi-xs-8 {
        width: 66.6666666%;
    }
    .lumi-xs-9 {
        width: 75%;
    }
    .lumi-xs-10 {
        width: 83.3333333%;
    }
    .lumi-xs-11 {
        width: 91.7%;
    }
    .lumi-xs-12 {
        width: 100%;
    }
}

@media (min-width: 767.99px) and (max-width: 835.98px) {
    .container {
        max-width: 750px !important;
    }
    .lumi-sm-1 {
        width: 8.3333333%;
    }
    .lumi-sm-2 {
        width: 16.666666667%;
    }
    .lumi-sm-3 {
        width: 25%;
    }
    .lumi-sm-4 {
        width: 33.3333333%;
    }
    .lumi-sm-5 {
        width: 41.6666667%;
    }
    .lumi-sm-6 {
        width: 50%;
    }
    .lumi-sm-7 {
        width: 58.3333333%;
    }
    .lumi-sm-8 {
        width: 66.6666666%;
    }
    .lumi-sm-9 {
        width: 75%;
    }
    .lumi-sm-10 {
        width: 83.3333333%;
    }
    .lumi-sm-11 {
        width: 91.7%;
    }
    .lumi-sm-12 {
        width: 100%;
    }
}

@media (min-width: 835.99px) and (max-width: 1023.98px){
    .container {
        max-width: 1000px !important;
    }
    .lumi-lg-1 {
        width: 8.3333333%;
    }
    .lumi-lg-2 {
        width: 16.666666667%;
    }
    .lumi-lg-3 {
        width: 25%;
    }
    .lumi-lg-4 {
        width: 33.3333333%;
    }
    .lumi-lg-5 {
        width: 41.6666667%;
    }
    .lumi-lg-6 {
        width: 50%;
    }
    .lumi-lg-7 {
        width: 58.3333333%;
    }
    .lumi-lg-8 {
        width: 66.6666666%;
    }
    .lumi-lg-9 {
        width: 75%;
    }
    .lumi-lg-10 {
        width: 83.3333333%;
    }
    .lumi-lg-11 {
        width: 91.7%;
    }
    .lumi-lg-12 {
        width: 100%;
    }
}

@media (min-width: 1024px) and (max-width: 1279.98px){
    .container {
        max-width: 960px !important;
    }
    .lumi-xlg-1 {
        width: 8.3333333%;
    }
    .lumi-xlg-2 {
        width: 16.666666667%;
    }
    .lumi-xlg-3 {
        width: 25%;
    }
    .lumi-xlg-4 {
        width: 33.3333333%;
    }
    .lumi-xlg-5 {
        width: 41.6666667%;
    }
    .lumi-xlg-6 {
        width: 50%;
    }
    .lumi-xlg-7 {
        width: 58.3333333%;
    }
    .lumi-xlg-8 {
        width: 66.6666666%;
    }
    .lumi-xlg-9 {
        width: 75%;
    }
    .lumi-xlg-10 {
        width: 83.3333333%;
    }
    .lumi-xlg-11 {
        width: 91.7%;
    }
    .lumi-xlg-12 {
        width: 100%;
    }
}