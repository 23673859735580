@import '../colors.scss';

#client .clientpagelayout #loadingSubmit .lds-ring,
#client .clientpagelayout #fullSubmit .lds-ring {
    width: 42px;
    height: 38px;
}
#client .clientpagelayout #loadingSubmit .lds-ring div,
#client .clientpagelayout #fullSubmit .lds-ring div {
    width: 26px;
    height: 26px;
}

#client .mainImageScreen .lds-ring
#client .clientpagelayout .lds-ring,
#clientlogin .lds-ring {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
}
#client .mainImageScreen.dark .lds-ring div,
#client .clientpagelayout.dark .lds-ring div,
#clientlogin.dark .lds-ring div {
    border: 8px solid $colorGreen;
    border-color: $colorGreen transparent transparent transparent;
}
#client .mainImageScreen.light .lds-ring div,
#client .clientpagelayout.light .lds-ring div,
#clientlogin.light .lds-ring div {
    border: 8px solid $colorBlueLogo;
    border-color: $colorBlueLogo transparent transparent transparent;
}
#client .mainImageScreen .lds-ring div,
#client .clientpagelayout .lds-ring div,
#clientlogin .lds-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 64px;
    height: 64px;
    margin: 8px;
    
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    
}
#client .mainImageScreen .lds-ring div:nth-child(1),
#client .clientpagelayout .lds-ring div:nth-child(1),
#clientlogin .lds-ring div:nth-child(1) {
    animation-delay: -0.45s;
}
#client .mainImageScreen .lds-ring div:nth-child(2),
#client .clientpagelayout .lds-ring div:nth-child(2),
#clientlogin .lds-ring div:nth-child(2) {
    animation-delay: -0.3s;
}
#client .mainImageScreen .lds-ring div:nth-child(3),
#client .clientpagelayout .lds-ring div:nth-child(3),
#clientlogin .lds-ring div:nth-child(3) {
    animation-delay: -0.15s;
}

@keyframes lds-ring {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}