@import '../fonts.scss';
@import '../colors.scss';

#clientlogin .loginImg {
    background-size: cover !important;
    top: 0;
    bottom: 0;
    min-height: 100vh !important;
    text-align: left;
    z-index: 10;
    opacity: 1;
    position: relative !important;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 30px 30px;
}

#clientlogin .loginImg::after {
    position: absolute;
    right: -1px;
    top: 0;
    width: 276px;
    height: 100%;
    content: "";
    z-index: -1;
}

// SIGNIN PAGE LEFT INFORMATION
#clientlogin .information {
    opacity: 0;
    padding: 20px;
    max-width: 450px;
    min-height: 275px;
    transform: translateY(20%);
    transition: transform 0.5s ease, opacity 0.3s ease;
}

#clientlogin.light .information {
    background-color: rgba(255, 255, 255, 0.5);
    color: $colorVeryDarkGray;
}

#clientlogin.dark .information {
    background-color: rgba(0, 0, 0, 0.5);
    color: $colorWhite;
}

#clientlogin .information.fadeIn {
    opacity: 1;
    transform: translateY(0);
}

#clientlogin .information h1 {
    margin-bottom: 20px;
    font-size: 35px;
    font-weight: 600;
    text-transform: uppercase;
}

#clientlogin .title>* {
    overflow: hidden;
    white-space: nowrap;
    animation: typingAnim 3s steps(50);
}

#clientlogin .information p {
    margin-bottom: 25px;
    line-height: 25px;
    font-size: 15px;
    font-weight: 300;
    font-family: $montserrat;
}

#clientlogin .information p .clientButton {
    cursor: pointer;
}

#clientlogin .socialIcon,
#clientlogin .logoutIcon {
    display: flex;
}

#clientlogin .logoutIcon {
    align-items: center;
    flex-direction: column;
}

#clientlogin .logoutIcon .logoutContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    width: 110px;
}

#clientlogin .socialIcon .socialButton {
    text-decoration: none !important;
    color: $colorWhite;
    width: 35px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    margin: 5px 8px 0 0;
    font-size: 20px;
    overflow: hidden;
    position: relative;
    cursor: pointer;
}

#clientlogin.light .logoutIcon .logoutButton {
    color: $colorVeryDarkGray;
}

#clientlogin.dark .logoutIcon .logoutButton {
    color: $colorWhite;
}

#clientlogin .logoutIcon .logoutButton {
    text-decoration: none !important;
    width: 35px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    margin-right: 8px;
    font-size: 20px;
    overflow: hidden;
    position: relative;
}

#clientlogin .socialIcon .socialButton.facebook {
    font-size: 20px;
    background: #3b5998;
}

#clientlogin .socialIcon .socialButton.instagram {
    font-size: 21px;
    background: #d6249f;
    background: radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%, #d6249f 60%, #285AEB 90%);
}

#clientlogin .socialIcon .socialButton:hover {
    transition: transform 0.4s linear 0s, border-top-left-radius 0.1s linear 0s, border-top-right-radius 0.1s linear 0.1s, border-bottom-right-radius 0.1s linear 0.2s, border-bottom-left-radius 0.1s linear 0.3s;
}

#clientlogin .logoutIcon .logoutButton:hover {
    transition: transform 0.4s linear 0s, border-top-left-radius 0.1s linear 0s, border-top-right-radius 0.1s linear 0.1s, border-bottom-right-radius 0.1s linear 0.2s, border-bottom-left-radius 0.1s linear 0.3s;
}

// SIGNIN PAGE CROP COLOR
#clientlogin.light .loginImg::after {
    background: url('./images/signin_crop.png') top left repeat;
}

#clientlogin.dark .loginImg::after {
    background: url('./images/signin_crop_dark.png') top left repeat;
}

// SIGNIN PAGE RIGHT INFORMATION
#clientlogin.light .loginBg {
    background-color: $colorSignInWhite;
}

#clientlogin.dark .loginBg {
    background-color: $colorVeryDarkGray;
}

#clientlogin.light .loginInnerForm,
#clientlogin.light .loginInnerForm h3 {
    color: $colorVeryDarkGray;
}

#clientlogin.dark .loginInnerForm,
#clientlogin.dark .loginInnerForm h3 {
    color: $colorWhite;
}

#clientlogin .formSection {
    min-height: 100vh;
    position: relative;
    text-align: center;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 30px;
}

#clientlogin .loginInnerForm {
    max-width: 350px;
    width: 100%;
    text-align: center;
}

#clientlogin .loginInnerForm .details {
    margin-bottom: 10px;
}

#clientlogin.dark .loginInnerForm .submitButton {
    background: $colorGreen;
    color: $colorWhite;
}

#clientlogin.light .loginInnerForm .submitButton {
    background: $colorBlueLogo;
    color: $colorWhite;
}

#clientlogin .loginInnerForm .submitButton {
    border: none;
    cursor: pointer;
    height: 55px;
    padding: 15.5px 50px 14.5px 50px;
    font-size: 17px;
    font-weight: 400;
    font-family: $montserrat;
    border-radius: 3px;
    width: 100%;
}

#clientlogin .loginInnerForm h3 {
    margin: 0 0 30px;
    font-size: 25px;
    font-weight: 400;
    font-family: $montserrat;
}

#clientlogin .loginInnerForm small {
    margin-top: 40px;
    font-size: 14px;
    display: flex;
    text-align: left !important;
    font-family: $montserrat;
}

#clientlogin .loginInnerForm .formSuccess,
#clientlogin .loginInnerForm .formError {
    position: absolute;
    font-size: 15px;
    font-family: $montserrat;
    margin: 8px 3px 3px 2px;
}

#clientlogin.dark .loginInnerForm .formSuccess {
    color: $colorGreen;
    font-weight: 300;
}

#clientlogin.light .loginInnerForm .formSuccess {
    color: $colorGreen;
    font-weight: 500;
}

#clientlogin.dark .loginInnerForm .formError {
    color: $colorRed;
    font-weight: 300;
}

#clientlogin.light .loginInnerForm .formError {
    color: $colorRed;
    font-weight: 500;
}

#clientlogin .formGroup {
    display: block;
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 40px !important;
    position: relative;
}

#clientlogin .formGroup .formWrapper {
    font-size: 15px;
    line-height: 15px;
    color: rgba(0, 0, 0, .65);
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    list-style: none;
    position: relative;
    display: inline-block;
    width: 100%;
}

#clientlogin.dark .formGroup .formWrapper .errorLabel {
    color: $colorRed;
    font-weight: 300;
}

#clientlogin.light .formGroup .formWrapper .errorLabel {
    color: $colorRed;
    font-weight: 500;
}

#clientlogin .formGroup .formWrapper .errorLabel {
    position: absolute;
    font-size: 15px;
    font-family: $montserrat;
    margin: 8px 3px 3px 2px;
}

#clientlogin .formGroup .formWrapper .customicon {
    position: absolute;
    left: auto;
    top: 50%;
    right: 12px;
    text-indent: 32px;
    cursor: pointer;
    transform: translateY(-50%);
}

#clientlogin .formGroup .formWrapper .customiconpin {
    display: flex;
    height: 40px;
    width: 100%;
    justify-content: center;
    cursor: pointer;
}

#clientlogin.dark .formGroup .formWrapper .customicon,
#clientlogin.dark .formGroup .formWrapper .customiconpin {
    color: $colorWhite;
}

#clientlogin.light .formGroup .formWrapper .customicon,
#clientlogin.light .formGroup .formWrapper .customiconpin {
    color: $colorVeryDarkGray;
}

// ICON STYLE AND POSITION
#clientlogin .formGroup .iconPosition {
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    line-height: 0;
    color: $colorDarkGray;
    z-index: 3;
    margin-left: 10px;
    // cursor: pointer;
}

// INPUT STYLE
// #clientlogin input[type="text"], 
// #clientlogin input[type="password"], 
// #clientlogin input[type="email"], 
// #clientlogin textarea {
//     appearance: none !important;
// }
#clientlogin input[type="checkbox"],
#clientlogin input[type="radio"] {
    box-sizing: border-box;
    cursor: pointer;
    line-height: normal;
}

#clientlogin input[type="checkbox"],
#clientlogin input[type="radio"] {
    margin: 4px 0 0;
    padding: 0;
}

#clientlogin textarea,
#clientlogin input[type="text"],
#clientlogin input[type="password"],
#clientlogin input[type="datetime"],
#clientlogin input[type="daterange"],
#clientlogin input[type="datetime-local"],
#clientlogin input[type="date"],
#clientlogin input[type="month"],
#clientlogin input[type="time"],
#clientlogin input[type="week"],
#clientlogin input[type="number"],
#clientlogin input[type="email"],
#clientlogin input[type="url"],
#clientlogin input[type="search"],
#clientlogin input[type="tel"],
#clientlogin input[type="color"] {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    margin: 0;
    list-style: none;
    position: relative;
    display: inline-block;
    padding: 7px 13px 7px 30px;
    width: 100%;
    height: 55px;
    font-size: 15px;
    line-height: 1.5;
    background-image: none;
    border-radius: 4px;
    -webkit-transition: all .3s;
    -o-transition: all .3s;
    transition: all .3s;
}

#clientlogin.dark textarea,
#clientlogin.dark input[type="text"],
#clientlogin.dark input[type="password"],
#clientlogin.dark input[type="datetime"],
#clientlogin.dark input[type="daterange"],
#clientlogin.dark input[type="datetime-local"],
#clientlogin.dark input[type="date"],
#clientlogin.dark input[type="month"],
#clientlogin.dark input[type="time"],
#clientlogin.dark input[type="week"],
#clientlogin.dark input[type="number"],
#clientlogin.dark input[type="email"],
#clientlogin.dark input[type="url"],
#clientlogin.dark input[type="search"],
#clientlogin.dark input[type="tel"],
#clientlogin.dark input[type="color"] {
    color: $colorGray;
    background-color: $colorVeryDarkGray;
    border: 1px solid $colorGray;
}

#clientlogin.light textarea,
#clientlogin.light input[type="text"],
#clientlogin.light input[type="password"],
#clientlogin.light input[type="datetime"],
#clientlogin.light input[type="daterange"],
#clientlogin.light input[type="datetime-local"],
#clientlogin.light input[type="date"],
#clientlogin.light input[type="month"],
#clientlogin.light input[type="time"],
#clientlogin.light input[type="week"],
#clientlogin.light input[type="number"],
#clientlogin.light input[type="email"],
#clientlogin.light input[type="url"],
#clientlogin.light input[type="search"],
#clientlogin.light input[type="tel"],
#clientlogin.light input[type="color"] {
    color: $colorDarkGrayPlus;
    background-color: $colorWhite;
    border: 1px solid $colorGray;
}

#clientlogin.dark textarea:hover,
#clientlogin.dark input[type="text"]:hover,
#clientlogin.dark input[type="password"]:hover,
#clientlogin.dark input[type="datetime"]:hover,
#clientlogin.dark input[type="daterange"]:hover,
#clientlogin.dark input[type="datetime-local"]:hover,
#clientlogin.dark input[type="date"]:hover,
#clientlogin.dark input[type="month"]:hover,
#clientlogin.dark input[type="time"]:hover,
#clientlogin.dark input[type="week"]:hover,
#clientlogin.dark input[type="number"]:hover,
#clientlogin.dark input[type="email"]:hover,
#clientlogin.dark input[type="url"]:hover,
#clientlogin.dark input[type="search"]:hover,
#clientlogin.dark input[type="tel"]:hover,
#clientlogin.dark input[type="color"]:hover {
    border-color: $colorSignInWhite !important;
}

#clientlogin.light textarea:hover,
#clientlogin.light input[type="text"]:hover,
#clientlogin.light input[type="password"]:hover,
#clientlogin.light input[type="datetime"]:hover,
#clientlogin.light input[type="daterange"]:hover,
#clientlogin.light input[type="datetime-local"]:hover,
#clientlogin.light input[type="date"]:hover,
#clientlogin.light input[type="month"]:hover,
#clientlogin.light input[type="time"]:hover,
#clientlogin.light input[type="week"]:hover,
#clientlogin.light input[type="number"]:hover,
#clientlogin.light input[type="email"]:hover,
#clientlogin.light input[type="url"]:hover,
#clientlogin.light input[type="search"]:hover,
#clientlogin.light input[type="tel"]:hover,
#clientlogin.light input[type="color"]:hover {
    border-color: $colorVeryDarkGray !important;
}

#clientlogin.dark textarea:focus,
#clientlogin.dark input[type="text"]:focus,
#clientlogin.dark input[type="password"]:focus,
#clientlogin.dark input[type="datetime"]:focus,
#clientlogin.dark input[type="daterange"]:focus,
#clientlogin.dark input[type="datetime-local"]:focus,
#clientlogin.dark input[type="date"]:focus,
#clientlogin.dark input[type="month"]:focus,
#clientlogin.dark input[type="time"]:focus,
#clientlogin.dark input[type="week"]:focus,
#clientlogin.dark input[type="number"]:focus,
#clientlogin.dark input[type="email"]:focus,
#clientlogin.dark input[type="url"]:focus,
#clientlogin.dark input[type="search"]:focus,
#clientlogin.dark input[type="tel"]:focus,
#clientlogin.dark input[type="color"]:focus {
    border-color: $colorSignInWhite !important;
    background-color: $colorVeryDarkGray !important;
    outline: none;
}

#clientlogin.light textarea:focus,
#clientlogin.light input[type="text"]:focus,
#clientlogin.light input[type="password"]:focus,
#clientlogin.light input[type="datetime"]:focus,
#clientlogin.light input[type="daterange"]:focus,
#clientlogin.light input[type="datetime-local"]:focus,
#clientlogin.light input[type="date"]:focus,
#clientlogin.light input[type="month"]:focus,
#clientlogin.light input[type="time"]:focus,
#clientlogin.light input[type="week"]:focus,
#clientlogin.light input[type="number"]:focus,
#clientlogin.light input[type="email"]:focus,
#clientlogin.light input[type="url"]:focus,
#clientlogin.light input[type="search"]:focus,
#clientlogin.light input[type="tel"]:focus,
#clientlogin.light input[type="color"]:focus {
    border-color: $colorVeryDarkGray !important;
    background-color: $colorWhite !important;
    outline: none;
}

#clientlogin.dark textarea:disabled,
#clientlogin.dark input[type="text"]:disabled,
#clientlogin.dark input[type="password"]:disabled,
#clientlogin.dark input[type="datetime"]:disabled,
#clientlogin.dark input[type="daterange"]:disabled,
#clientlogin.dark input[type="datetime-local"]:disabled,
#clientlogin.dark input[type="date"]:disabled,
#clientlogin.dark input[type="month"]:disabled,
#clientlogin.dark input[type="time"]:disabled,
#clientlogin.dark input[type="week"]:disabled,
#clientlogin.dark input[type="number"]:disabled,
#clientlogin.dark input[type="email"]:disabled,
#clientlogin.dark input[type="url"]:disabled,
#clientlogin.dark input[type="search"]:disabled,
#clientlogin.dark input[type="tel"]:disabled,
#clientlogin.dark input[type="color"]:disabled {
    pointer-events: none;
    color: rgba(255, 255, 255, 0.2) !important;
}

#clientlogin.light textarea:disabled,
#clientlogin.light input[type="text"]:disabled,
#clientlogin.light input[type="password"]:disabled,
#clientlogin.light input[type="datetime"]:disabled,
#clientlogin.light input[type="daterange"]:disabled,
#clientlogin.light input[type="datetime-local"]:disabled,
#clientlogin.light input[type="date"]:disabled,
#clientlogin.light input[type="month"]:disabled,
#clientlogin.light input[type="time"]:disabled,
#clientlogin.light input[type="week"]:disabled,
#clientlogin.light input[type="number"]:disabled,
#clientlogin.light input[type="email"]:disabled,
#clientlogin.light input[type="url"]:disabled,
#clientlogin.light input[type="search"]:disabled,
#clientlogin.light input[type="tel"]:disabled,
#clientlogin.light input[type="color"]:disabled {
    pointer-events: none;
    color: rgba(255, 255, 255, 0.2) !important;
}

.disabled {
    pointer-events: none;
    // color: rgba(255, 255, 255, 0.2) !important;
}

@media (max-width: 767.98px) {
    #clientlogin .mobileHide {
        display:none !important
    }
}

@media (min-width: 767.99px) and (max-width: 835.98px) {
    #clientlogin .information h1 {
        font-size: 25px;
    }
    #clientlogin .information p {
        font-size: 13px;
    }
}

// @media (min-width: 835.99px) and (max-width: 1023.98px){
//     #clientlogin .information h1 {
//         font-size: 25px;
//     }
// }