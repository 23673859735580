@import '../colors.scss';
@import '../fonts.scss';

#client .clientpagelayout .clientPageWrapper .insidecontentWrapper .mainBodyWrapper .floatingScreen {
    box-shadow: 1px 0px 7px rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 50%;
    left: 50%;
    right: 0;
    width: 100%; // kalau ini ga 100%, mobile phone ga full width
    max-width: 1000px;
    z-index: 2100;
    display: table;
    pointer-events: all;
    visibility: hidden;
    opacity: 0;
    transition: transform 0.5s ease, opacity 0.3s ease;
    transform: translate(-50%, -50%);
    will-change: transform;
}

#client .clientpagelayout .clientPageWrapper .insidecontentWrapper .mainBodyWrapper .floatingScreen.open {
    visibility: visible;
    opacity: 1;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

#client .clientpagelayout .clientPageWrapper .insidecontentWrapper .mainBodyWrapper .floatingScreen.light {
    background-color: $colorWhite;
}

#client .clientpagelayout .clientPageWrapper .insidecontentWrapper .mainBodyWrapper .floatingScreen.dark {
    background-color: $colorVeryDark;
}

#client .clientpagelayout .clientPageWrapper .insidecontentWrapper .mainBodyWrapper .floatingScreen .mediacss {
    padding: 0;
}

#client .clientpagelayout .clientPageWrapper .insidecontentWrapper .mainBodyWrapper .floatingScreen .mediacss .cardWrapper {
    margin-bottom: 0;
    border-radius: 0;
}

// #client .clientpagelayout .clientPageWrapper .insidecontentWrapper .mainBodyWrapper .floatingScreen .mediacss .cardWrapper.body {
//     height: 100%;
// }

#client .clientpagelayout .clientPageWrapper .insidecontentWrapper .mainBodyWrapper .floatingScreen .mediacss .cardWrapper .imgWrapper {
    padding: 15px;
}

#client .clientpagelayout .clientPageWrapper .insidecontentWrapper .mainBodyWrapper .floatingScreen .mediacss .cardWrapper .imgWrapper .mediaTitleWrapper {
    padding: 10px;
}

#client .clientpagelayout .clientPageWrapper .insidecontentWrapper .mainBodyWrapper .floatingScreen .mediacss .cardWrapper .imgWrapper .imgCard img {
    min-height: auto;
    max-height: auto;
}


@media (max-width: 767.98px) {
    #client .clientpagelayout .clientPageWrapper .insidecontentWrapper .mainBodyWrapper .floatingScreen .mediacss .cardWrapper .dashboardHeader .dashboardProfile {
        margin-bottom: 0;
    }
}