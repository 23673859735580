#lumisoft-maxi .slidercontainer {
    width: 85%;
    margin: 0 auto;
}

#lumisoft-maxi .carouselimage {
    background-size: cover !important;
    // background-color: rgba(0, 0, 0, 0);
    // background-position-x: 0%;
    // background-position-y: 0%;
    // background-repeat: repeat;
    // background-attachment: scroll;
    // background-image: linear-gradient(to right, rgba(0, 0, 0, 0.35), transparent 100%);
    // background-size: auto;
    // background-origin: padding-box;
    // background-clip: border-box;
    // background-image: linear-gradient(to right,rgba(0,0,0,.35),transparent 100%);
}

#lumisoft-maxi .carouselimage img {
    width: 100%;
}

#lumisoft-maxi #homepage #frontendslider a.button {
    display: inline-block;
    text-decoration: none;
    background: 0 0;
    border: 1px solid #4f565d;
    transition: background-color .3s, color .3s;
    font-family: Roboto, sans-serif;
    cursor: pointer;
    border-color: #fff;
    color: #fff;
    border-width: 2.6px;
    font-size: 14px;
    font-weight: 500;
    padding: 10px 18px;
    line-height: 1.3rem;
    margin: 0 15px 15px 25px;
}

#lumisoft-maxi #homepage #frontendslider a.buttondirections {
    display: inline-block;
    text-decoration: none;
    background: 0 0;
    border: 1px solid #4f565d;
    transition: background-color .3s, color .3s;
    font-family: Roboto, sans-serif;
    cursor: pointer;
    border-color: #fff;
    color: #fff;
    border-width: 2.6px;
    font-size: 14px;
    font-weight: 500;
    padding: 10px 18px;
    line-height: 1.3rem;
    margin: 0 15px 15px 10px;
}

#lumisoft-maxi #homepage #frontendslider a.button:hover,
#lumisoft-maxi #homepage #frontendslider a.buttondirections:hover {
    background-color: $colorWhite;
    color: $colorDarkGrayPlus;
}

#lumisoft-maxi #homepage #frontendslider .hometitle {
    background: linear-gradient(to right, rgba(0, 0, 0, .5), transparent 100%);
    position: absolute;
    // top: 50%;
    // left: 40%;
    width: 800px;
    bottom: 150px;
    // -webkit-transform: translate(-50%, -50%);
    // transform: translate(-50%, -50%);
}

#lumisoft-maxi #homepage #frontendslider .homeevent .homeeventWrapper {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
}

#lumisoft-maxi #homepage #frontendslider .homeevent .homeeventWrapper p {
    color: #fff;
}

#lumisoft-maxi #homepage #frontendslider .hometitlemobile {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    padding: 0px 10px;
    height: 160px;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

#lumisoft-maxi #homepage #frontendslider .hometitle .big_white {
    padding-top: 10px;
    color: #fff;
    font-size: 45px;
    margin: 10px 15px 5px 25px;
    text-transform: uppercase;
    line-height: 40px;
    font-weight: 700;
}

#lumisoft-maxi #homepage #frontendslider .hometitle .slidersmalltitle {
    color: #fff;
    margin: 0 25px;
    font-size: 22px;
    line-height: 32px;
    font-weight: 300;
    padding-bottom: 20px;
}

#lumisoft-maxi #homepage #frontendslider .hometitle .eventdetails {
    color: #fff;
    margin: 0 25px;
    font-size: 16px;
    font-weight: 700;
    font-weight: 500;
    padding-bottom: 20px;
}

#lumisoft-maxi #homepage #frontendslider .hometitlemobile .big_whitemobile {
    color: #fff;
    font-size: 25px;
    margin: 10px 15px;
    text-transform: uppercase;
    line-height: 30px;
    font-weight: 700;
}

#lumisoft-maxi #homepage #frontendslider .hometitlemobile .slidersmalltitle {
    color: #fff;
    margin: 0 15px;
    font-size: 18px;
    line-height: 22px;
    font-weight: 300;
}

// #lumisoft-maxi #homepage #frontendslider::after {
//     content: "";
//   width: 100%;
//   height: 782px;
//   position: absolute;
// //   left: 0;
// //   bottom: 0;
//   background-repeat: repeat-x;
//   background-position: top center;
//   background-image: url(./images/divider_2.png);
//   z-index: 1;
//   background-color: #FFBEC9;
// }

// .goldcolor {
//     background-color: #FFBEC9;
// }

@media (max-width: 767.98px) {
    #lumisoft-maxi .slidercontainer {
        width: 100%;
    }
}

@media (min-width: 835.99px) and (max-width: 1023.98px) {
    #lumisoft-maxi .slidercontainer {
        height: 500px !important;
        overflow: hidden
    }
}