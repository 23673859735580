$colorWhite2: #fafafa;
$colorWhite3: #dfdfdf;
$colorWhite4: #ebebeb;
$colorWhite5: #aaaaaa;
$colorWhite6: #bbbbbb;
$colorBlack: #000000;
$colorLightDark: #2a2a2a;
$colorBlue: #0190fe;
$colorBlue2: #0e74d7;
$colorPurplePink: #521751;
$colorPurple: #3d266a;
$colorDarkPink: #800080;

$colorOrange: #ff4c3b;
$colorOrange2: #73cd61;
$colorLight: #cccccc;
// $colorRed: #e60000;

$colorOrangeNew: #FF4500;
$colorDarkGreen: #359144;

// Dark Combination
$colorVeryDark: #1a1a1a;
$colorVeryDarkGray: #232323;
$colorDark: #333333;
$colorDarkGrayPlus: #343434;
$colorDarkGray: #5a5a5a;
$colorGray: #878787;
$colorGray2: #b3b3b3;

$colorGreen: #41b053;
$colorBlueLogo: #213e96;
$colorRed: #FF0000;

// Light Combination
$colorWhite: #ffffff;
$colorWhiteBackground: #f5f7fb;
$colorSignInWhite: #eef1f6;
$colorClientNavDashboard: #edf1fc;
$colorClientNavDashboard2: #E6E6FA;
$colorLightGray: #A0A0A0;
$colorVeryLightGray: #D3D3D3;

// Additional color
$colorLink: #4f565d;