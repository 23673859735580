@import '../resets.scss';
@import '../fonts.scss';
@import '../grids.scss';
@import '../margin.scss';
@import '../padding.scss';

@import './loading_ring.scss';
@import './loading_track.scss';
@import './signin.scss';
@import './header.scss';
@import './sidemobile.scss';
@import './pagelayout.scss';
@import './content.scss';
@import './contentdetails.scss';
@import './forms.scss';
@import './tags.scss';
@import './table.width.scss';
@import './emailverify.scss';
@import './home.scss';
@import './gallery.scss';
@import './media.scss';
@import './mediagallery.scss';
@import './mediafloating.scss';
@import './pagination.scss';
@import './floatingbutton.scss';


/***********************************************
                    GENERAL
***********************************************/
*,
::after,
::before {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

.clearfix::before,
.clearfix::after,
.dl-horizontal dd::before,
.dl-horizontal dd::after,
.container::before,
.container::after,
.container-fluid::before,
.container-fluid::after,
.row::before,
.row::after,
.form-horizontal .form-group::before,
.form-horizontal .form-group::after,
.btn-toolbar::before,
.btn-toolbar::after,
.btn-group-vertical>.btn-group::before,
.btn-group-vertical>.btn-group::after,
.nav::before,
.nav::after,
.navbar::before,
.navbar::after,
.navbar-header::before,
.navbar-header::after,
.navbar-collapse::before,
.navbar-collapse::after,
.pager::before,
.pager::after,
.panel-body::before,
.panel-body::after,
.modal-footer::before,
.modal-footer::after,
#topbar::before,
#topbar::after {
    content: " ";
    display: table;
}

.clearfix::after,
.dl-horizontal dd::after,
.container::after,
.container-fluid::after,
.row::after,
.form-horizontal .form-group::after,
.btn-toolbar::after,
.btn-group-vertical>.btn-group::after,
.nav::after,
.navbar::after,
.navbar-header::after,
.navbar-collapse::after,
.pager::after,
.panel-body::after,
.modal-footer::after,
#topbar::after {
    clear: both;
}

img {
    vertical-align: middle;
    border: 0;
    border-style: none;
}

html {
    scrollbar-width: none;
}

body #root #client,
body #root #clientlogin {
    font-family: $roboto;
}

body #root #client a,
body #root #clientlogin a {
    text-decoration: none;
    background-color: transparent;
}

.h1,
h1 {
    font-size: 2.5rem;
}

.h3,
h3 {
    font-size: 23px;
}

.h4,
h4 {
    font-size: 1.5rem;
}

.h5,
h5 {
    font-size: 1.2rem;
}

.h6,
h6 {
    font-size: 14px;
    font-weight: 400;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
    margin-bottom: .5rem;
    font-family: inherit;
    font-weight: 500;
    line-height: 1.2;
    color: inherit;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin-top: 0;
}

b,
strong {
    font-weight: bolder;
}

p {
    display: block;
    margin-top: 0;
    margin-bottom: 1rem;
}

// TRANSITION PAGE ANIMATION
.clienttransition {
    display: unset;
}

.clienttransition.fadeIn {
    animation: 0.5s fadeIn forwards;
}

.clienttransition.fadeOut {
    animation: 0.5s fadeOut forwards;
}

@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translate(-5px, 0);
        // transform: translate(-20px, 0); // originally
    }

    to {
        opacity: 1;
        transform: translate(0px, 0px);
    }
}

@keyframes fadeOut {
    from {
        opacity: 1;
        transform: translate(0px, 0px);
    }

    to {
        // transform: translate(-20px, 0); // originally
        transform: translate(-5px, 0);
        opacity: 0;
    }
}