@import './colors.scss';
@import './fonts.scss';

#lumisoft-maxi #footer {
    background-color: $colorDark;
    margin-top: 100px;
}
#lumisoft-maxi #footer .footerwrapper {
    margin-top: -100px;
    width: 100%;
}
#lumisoft-maxi #footer .footerwrapper .footercard {
    padding: 50px;
    border: medium none;
    // box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.4);
    border-radius: 2px;
    min-height: 425px;
    margin-bottom: 20px;
}

#lumisoft-maxi.dark #footer .footerwrapper .footercard {
    background-color: $colorDark;
    color: $colorWhite;
}

#lumisoft-maxi.light #footer .footerwrapper .footercard {
    background-color: $colorWhite;
    color: $colorDark;
}

///////////// ADDRESS
#lumisoft-maxi #footer .footerwrapper .footercard h3 {
    font-family: $roboto;
    margin-bottom: 30px;
    color: #333333;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 22px;
}
#lumisoft-maxi.dark #footer .footerwrapper .footercard h3 {
    color: $colorWhite;
}

#lumisoft-maxi.light #footer .footerwrapper .footercard h3 {
    color: $colorDark;
}
#lumisoft-maxi #footer .footerwrapper .footercard address {
    font-style: normal;
    line-height: inherit;
    margin-bottom: 30px;
}
#lumisoft-maxi #footer .footerwrapper .footercard abbr[title], #lumisoft-maxi #footer .footerwrapper .footercard abbr[data-original-title] {
    text-decoration: underline;
    -webkit-text-decoration: underline dotted;
    text-decoration: underline dotted;
    cursor: help;
    border-bottom: 0;
    -webkit-text-decoration-skip-ink: none;
    text-decoration-skip-ink: none;
}

///////////// SOCIAL
#lumisoft-maxi #footer .footerwrapper .footercard .footersocial ul {
    padding-top: 5px;
}
#lumisoft-maxi #footer .footerwrapper .footercard .footersocial ul li {
    display: inline-block;
    text-align: center;
    margin-right: 20px;
}
#lumisoft-maxi #footer .footerwrapper .footercard .footersocial ul li a {
    // width: 35px;
    // height: 35px;
    line-height: 35px;
    font-size: 35px;
    border-radius: 3px;
    // background: #aaaaaa;
    // display: inline-block;
    display: flex;
    -webkit-transition: all 0.2s linear;
    -o-transition:all 0.2s linear;
    transition: all 0.2s linear;
}

#lumisoft-maxi.dark #footer .footerwrapper .footercard .footersocial ul li a {
    color: $colorWhite
}
#lumisoft-maxi.dark #footer .footerwrapper .footercard .footersocial ul li a svg:hover {
    color: $colorGreen;
}
#lumisoft-maxi.light #footer .footerwrapper .footercard .footersocial ul li a {
    color: $colorDark;
}

#lumisoft-maxi.light #footer .footerwrapper .footercard .footersocial ul li a svg:hover {
    color: $colorBlueLogo;
}
///////////// FORM
#lumisoft-maxi #footer .footerwrapper #contactform {
    display: flex;
}
#lumisoft-maxi #footer .footerwrapper #contactform .formcenter,
#lumisoft-maxi #footer .footerwrapper #contactform .formleft,
#lumisoft-maxi #footer .footerwrapper #contactform .formright {
    padding-top: 0;
    padding-bottom: 0;
}
#lumisoft-maxi #footer .footerwrapper #contactform .formcenter {
    padding-left: 0;
    padding-right: 0;
}
#lumisoft-maxi #footer .footerwrapper #contactform .formleft {
    padding-left: 0;
    padding-right: 10px;
}
#lumisoft-maxi #footer .footerwrapper #contactform .formright {
    padding-left: 10px;
    padding-right: 0;
}
#lumisoft-maxi #footer .footerwrapper #contactform .formGroup {
    display: block;
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 30px;
    position: relative;
}
#lumisoft-maxi #footer .footerwrapper #contactform .formWrapper {
    font-size: 15px;
    line-height: 15px;
    color: rgba(0,0,0,.65);
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    list-style: none;
    position: relative;
    display: inline-block;
    width: 100%;
}
#lumisoft-maxi #footer .footerwrapper #contactform .formWrapper .iconPosition {
    position: absolute;
    top: 50%;
    color: #878787;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    line-height: 0;
    z-index: 3;
    margin-left: 10px;
    cursor: pointer;
}
#lumisoft-maxi #footer .footerwrapper #contactform .formWrapper .iconPosition svg {
    display: inline-block;
    font-style: normal;
    vertical-align: -.125em;
    text-align: center;
    text-transform: none;
    line-height: 1;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
#lumisoft-maxi #footer .footerwrapper #contactform .footerbtn {
    border: 1px solid #50d1c0;
    color: #50d1c0;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 2px;
    min-width: 150px;
    padding: 13px 30px;
    text-decoration: none;
    background: transparent;
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
    -webkit-transition: 0.3s;
    transition: 0.3s;
    display: inline-block;
    margin-bottom: 0;
    line-height: 1.42857143;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    -ms-touch-action: manipulation;
    touch-action: manipulation;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    text-transform: uppercase;
}
#lumisoft-maxi #footer .footerwrapper #contactform .footerbtn:hover
{
    background: $colorBlueLogo;
    border: 1px solid $colorBlueLogo;
    box-shadow: 2px 3px 5px 0 rgba(0, 0, 0, 0.2);
    color: $colorWhite;
    -webkit-transform: translateY(-1px);
    transform: translateY(-1px);
}

///////////// COPYRIGHT
#lumisoft-maxi #footer .homecopyright {
    display: flex;
    justify-content: center;
}
#lumisoft-maxi #footer .homecopyright p.footercopy {
    margin-bottom: 0;
    font-size: 16px;
    color: #fff;
    padding: 20px 0;
    margin-bottom: 20px;
}
#lumisoft-maxi #footer .homecopyright p.footercopy a {
    color: #999999;
}

#lumisoft-maxi #footer .homecopyright p.footercopy a:hover {
    color: $colorWhite;
}
//============================================== FORM
#lumisoft-blank #blogpagedetails .formWrapper .form-control,
#lumisoft-login #loginform .formWrapper .form-control,
#lumisoft-maxi #contactform .formWrapper .form-control {
    padding-left: 34px;
}
#lumisoft-blank input[type=text], 
#lumisoft-blank input[type=password], 
#lumisoft-blank input[type=email],
#lumisoft-blank textarea,
#lumisoft-login input[type=text], 
#lumisoft-login input[type=password], 
#lumisoft-login input[type=email],
#lumisoft-login textarea,
#lumisoft-maxi input[type=text], 
#lumisoft-maxi input[type=password], 
#lumisoft-maxi input[type=email], 
#lumisoft-maxi textarea {
    appearance: none !important;
}
#lumisoft-blank input[type="checkbox"],
#lumisoft-blank input[type="radio"],
#lumisoft-login input[type="checkbox"],
#lumisoft-login input[type="radio"],
#lumisoft-maxi input[type="checkbox"], 
#lumisoft-maxi input[type="radio"] {
    box-sizing: border-box;
    cursor: pointer;
    line-height: normal;
}
#lumisoft-blank input[type=checkbox],
#lumisoft-blank input[type=radio],
#lumisoft-login input[type=checkbox],
#lumisoft-login input[type=radio],
#lumisoft-maxi input[type=checkbox], 
#lumisoft-maxi input[type=radio] {
    margin: 4px 0 0;
    padding: 0;
}
#lumisoft-blank textarea,
#lumisoft-login textarea,
#lumisoft-maxi textarea {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    list-style: none;
    position: relative;
    display: inline-block;
    padding: 7px 13px;
    width: 100%;
    font-size: 15px;
    line-height: 1.5;
    color: $colorDark;
    background-color: #eeeeee;
    background-image: none;
    border: 1px solid rgba(0,0,0,0.3);
    border-radius: 4px;
    -webkit-transition: all .3s;
    -o-transition: all .3s;
    transition: all .3s;
}
#lumisoft-blank input[type="text"], 
#lumisoft-blank input[type="password"], 
#lumisoft-blank input[type="datetime"], 
#lumisoft-blank input[type="daterange"], 
#lumisoft-blank input[type="datetime-local"], 
#lumisoft-blank input[type="date"], 
#lumisoft-blank input[type="month"], 
#lumisoft-blank input[type="time"], 
#lumisoft-blank input[type="week"], 
#lumisoft-blank input[type="number"], 
#lumisoft-blank input[type="email"], 
#lumisoft-blank input[type="url"], 
#lumisoft-blank input[type="search"], 
#lumisoft-blank input[type="tel"], 
#lumisoft-blank input[type="color"],
#lumisoft-login input[type="text"], 
#lumisoft-login input[type="password"], 
#lumisoft-login input[type="datetime"], 
#lumisoft-login input[type="daterange"], 
#lumisoft-login input[type="datetime-local"], 
#lumisoft-login input[type="date"], 
#lumisoft-login input[type="month"], 
#lumisoft-login input[type="time"], 
#lumisoft-login input[type="week"], 
#lumisoft-login input[type="number"], 
#lumisoft-login input[type="email"], 
#lumisoft-login input[type="url"], 
#lumisoft-login input[type="search"], 
#lumisoft-login input[type="tel"], 
#lumisoft-login input[type="color"],
#lumisoft-maxi input[type="text"], 
#lumisoft-maxi input[type="password"], 
#lumisoft-maxi input[type="datetime"], 
#lumisoft-maxi input[type="daterange"], 
#lumisoft-maxi input[type="datetime-local"], 
#lumisoft-maxi input[type="date"], 
#lumisoft-maxi input[type="month"], 
#lumisoft-maxi input[type="time"], 
#lumisoft-maxi input[type="week"], 
#lumisoft-maxi input[type="number"], 
#lumisoft-maxi input[type="email"], 
#lumisoft-maxi input[type="url"], 
#lumisoft-maxi input[type="search"], 
#lumisoft-maxi input[type="tel"], 
#lumisoft-maxi input[type="color"] {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    list-style: none;
    position: relative;
    display: inline-block;
    padding: 7px 13px;
    width: 100%;
    height: 32px;
    font-size: 15px;
    line-height: 1.5;
    color: $colorDark;
    background-color: #eeeeee;
    background-image: none;
    border: 1px solid rgba(0,0,0,0.3);
    border-radius: 4px;
    -webkit-transition: all .3s;
    -o-transition: all .3s;
    transition: all .3s;
}
#lumisoft-blank textarea:hover, 
#lumisoft-blank input[type="text"]:hover, 
#lumisoft-blank input[type="password"]:hover, 
#lumisoft-blank input[type="datetime"]:hover, 
#lumisoft-blank input[type="daterange"]:hover, 
#lumisoft-blank input[type="datetime-local"]:hover, 
#lumisoft-blank input[type="date"]:hover, 
#lumisoft-blank input[type="month"]:hover, 
#lumisoft-blank input[type="time"]:hover, 
#lumisoft-blank input[type="week"]:hover, 
#lumisoft-blank input[type="number"]:hover, 
#lumisoft-blank input[type="email"]:hover, 
#lumisoft-blank input[type="url"]:hover, 
#lumisoft-blank input[type="search"]:hover, 
#lumisoft-blank input[type="tel"]:hover, 
#lumisoft-blank input[type="color"]:hover,
#lumisoft-login textarea:hover, 
#lumisoft-login input[type="text"]:hover, 
#lumisoft-login input[type="password"]:hover, 
#lumisoft-login input[type="datetime"]:hover, 
#lumisoft-login input[type="daterange"]:hover, 
#lumisoft-login input[type="datetime-local"]:hover, 
#lumisoft-login input[type="date"]:hover, 
#lumisoft-login input[type="month"]:hover, 
#lumisoft-login input[type="time"]:hover, 
#lumisoft-login input[type="week"]:hover, 
#lumisoft-login input[type="number"]:hover, 
#lumisoft-login input[type="email"]:hover, 
#lumisoft-login input[type="url"]:hover, 
#lumisoft-login input[type="search"]:hover, 
#lumisoft-login input[type="tel"]:hover, 
#lumisoft-login input[type="color"]:hover,
#lumisoft-maxi textarea:hover, 
#lumisoft-maxi input[type="text"]:hover, 
#lumisoft-maxi input[type="password"]:hover, 
#lumisoft-maxi input[type="datetime"]:hover, 
#lumisoft-maxi input[type="daterange"]:hover, 
#lumisoft-maxi input[type="datetime-local"]:hover, 
#lumisoft-maxi input[type="date"]:hover, 
#lumisoft-maxi input[type="month"]:hover, 
#lumisoft-maxi input[type="time"]:hover, 
#lumisoft-maxi input[type="week"]:hover, 
#lumisoft-maxi input[type="number"]:hover, 
#lumisoft-maxi input[type="email"]:hover, 
#lumisoft-maxi input[type="url"]:hover, 
#lumisoft-maxi input[type="search"]:hover, 
#lumisoft-maxi input[type="tel"]:hover, 
#lumisoft-maxi input[type="color"]:hover {
    border: 1px solid rgba(0,0,0,1);
    box-shadow: 2px 3px 5px 0 rgba(0, 0, 0, 0.1);
    background-color: $colorWhite !important;
}
#lumisoft-blank textarea:focus, 
#lumisoft-blank input[type="text"]:focus, 
#lumisoft-blank input[type="password"]:focus, 
#lumisoft-blank input[type="datetime"]:focus, 
#lumisoft-blank input[type="daterange"]:focus, 
#lumisoft-blank input[type="datetime-local"]:focus, 
#lumisoft-blank input[type="date"]:focus, 
#lumisoft-blank input[type="month"]:focus, 
#lumisoft-blank input[type="time"]:focus, 
#lumisoft-blank input[type="week"]:focus, 
#lumisoft-blank input[type="number"]:focus, 
#lumisoft-blank input[type="email"]:focus, 
#lumisoft-blank input[type="url"]:focus, 
#lumisoft-blank input[type="search"]:focus, 
#lumisoft-blank input[type="tel"]:focus, 
#lumisoft-blank input[type="color"]:focus,
#lumisoft-login textarea:focus, 
#lumisoft-login input[type="text"]:focus, 
#lumisoft-login input[type="password"]:focus, 
#lumisoft-login input[type="datetime"]:focus, 
#lumisoft-login input[type="daterange"]:focus, 
#lumisoft-login input[type="datetime-local"]:focus, 
#lumisoft-login input[type="date"]:focus, 
#lumisoft-login input[type="month"]:focus, 
#lumisoft-login input[type="time"]:focus, 
#lumisoft-login input[type="week"]:focus, 
#lumisoft-login input[type="number"]:focus, 
#lumisoft-login input[type="email"]:focus, 
#lumisoft-login input[type="url"]:focus, 
#lumisoft-login input[type="search"]:focus, 
#lumisoft-login input[type="tel"]:focus, 
#lumisoft-login input[type="color"]:focus,
#lumisoft-maxi textarea:focus, 
#lumisoft-maxi input[type="text"]:focus, 
#lumisoft-maxi input[type="password"]:focus, 
#lumisoft-maxi input[type="datetime"]:focus, 
#lumisoft-maxi input[type="daterange"]:focus, 
#lumisoft-maxi input[type="datetime-local"]:focus, 
#lumisoft-maxi input[type="date"]:focus, 
#lumisoft-maxi input[type="month"]:focus, 
#lumisoft-maxi input[type="time"]:focus, 
#lumisoft-maxi input[type="week"]:focus, 
#lumisoft-maxi input[type="number"]:focus, 
#lumisoft-maxi input[type="email"]:focus, 
#lumisoft-maxi input[type="url"]:focus, 
#lumisoft-maxi input[type="search"]:focus, 
#lumisoft-maxi input[type="tel"]:focus, 
#lumisoft-maxi input[type="color"]:focus {
    box-shadow: 2px 3px 5px 0 rgba(0, 0, 0, 0.1) !important;
    border-color: $colorDarkGrayPlus !important;
    background-color: $colorWhite !important;
}

@media (max-width: 767.98px) {
    #lumisoft-maxi #footer .footerwrapper .footercard {
        padding: 40px 30px;
    }
}